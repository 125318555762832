import { ColumnType } from 'antd/lib/table';
import { CreditEvent } from '@prisma/client';

import { ArrowSortIcon } from '../../../../../components/ArrowSortIcon/ArrowSortIcon';
import {
  DATE_WITH_FULL_MONTH,
  formatDate,
} from '../../../../../core/models/contstants/format-date';
import Status from '../../../../../components/StatusComponent';
import { BCU_EVENTS } from '../../../../../../constants/bcu';

export const bcuLogsColumns: ColumnType<any>[] = [
  {
    title: 'BCU Event Date and time',
    sorter: true,
    defaultSortOrder: 'ascend',
    sortIcon: ({ sortOrder }) => <ArrowSortIcon sortOrder={sortOrder} />,
    dataIndex: 'approveDate',
    render: (_, item) => formatDate(item.createdAt, DATE_WITH_FULL_MONTH),
  },
  {
    title: 'BCU Event Type',
    dataIndex: 'triggeredBy',
    render: (_, item) => item.triggeredBy,
  },
  {
    title: 'Account Name',
    dataIndex: 'accountName',
    render: (_, item) => item.accountName,
  },
  {
    title: 'BCU Event',
    dataIndex: 'creditEvent',
    render: (_, item) => BCU_EVENTS[item.creditEvent as CreditEvent],
  },
  {
    title: 'BCU ID',
    dataIndex: 'BCUID',
    render: (_, item) => item?.BCUID,
  },
  {
    title: 'Pre Event Status',
    dataIndex: 'preEventStatus',
    render: (_, item) => (
      <Status statusOfComponent={item?.preEventStatus || ''} />
    ),
  },

  {
    title: 'Post Event Status',
    dataIndex: 'postEventStatus',
    render: (_, item) => (
      <Status statusOfComponent={item?.postEventStatus || ''} />
    ),
  },
  {
    title: 'Amount of BCUs',
    dataIndex: 'amount',
    render: (_, item) => item.amount,
  },
  {
    title: 'BCU Holder',
    dataIndex: 'bcuHolder',
    render: (_, item) => item.bcuHolder,
  },
];
