import { actions } from './fetchProvider/fetch';
import { URL_USER_TRANSACTIONS } from './urls/urls';
import {
  setTransactions,
  setUserTransactionsStatus,
} from '../store/reducers/user/transactionsReducer';
import { AppThunkDispatch } from '../store/store';

export const fetchUserTransactions =
  ({ page, limit, ...query }: any) =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setUserTransactionsStatus('loading'));

    return actions
      .get(URL_USER_TRANSACTIONS, {
        queries: {
          skip: (page - 1) * limit,
          limit,
          ...query,
        },
      })
      .then((resp: any) => {
        dispatch(setTransactions(resp.data.data));
        return resp.data.data;
      })
      .finally(() => {
        dispatch(setUserTransactionsStatus('idle'));
      });
  };
