import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface AdminDashboardState {
  status: 'idle',
  selectedRowContact: null | any,
  visiblePopUp: boolean
}
const initialState: AdminDashboardState = {
  status: 'idle',
  selectedRowContact: null as any,
  visiblePopUp: false,
};

const rowSlice = createSlice({
  name: 'row',
  initialState,
  reducers: {
    setSelectedRowContact: (state, action) => {
      state.selectedRowContact = action.payload;
    },
    setVisiblePopUp: (state, action) => {
      state.visiblePopUp = action.payload;
    },
    updateSelectedRowField: (state, action: PayloadAction<{field: string, value: string}>) => {
      if (state.selectedRowContact) {
        state.selectedRowContact[action.payload.field] = action.payload.value;
      }
    },
  },
});

export const {
  setSelectedRowContact,
  setVisiblePopUp,
  updateSelectedRowField
} = rowSlice.actions;

export const selectVisiblePopUp  = (state: RootState) => state.row.visiblePopUp;
export const selectedRowContact = (state: RootState) => state.row.selectedRowContact;

export default rowSlice.reducer;
