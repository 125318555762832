import { Column } from '../../Table/Table';
import { formatDate } from '../../../core/models/contstants/format-date';
import { limitOfNumbers } from '../../../../helpers/limitOfNumbers';

const formatVolume = (volume: number) =>
  `${volume ? limitOfNumbers(volume) : 0} BCU`;

export const COLUMNS: Column<any>[] = [
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: item => item.BCUID,
  },
  {
    title: 'Product',
    keyItem: 'product',
    render: item => item.productType,
  },
  {
    title: 'Feedstock',
    keyItem: 'feedstock',
    render: item => item.rawMaterial,
  },
  {
    title: 'Scheme',
    keyItem: 'scheme',
    render: item => item.certificationScheme,
  },
  {
    title: 'Date of registration',
    keyItem: 'registrationApproveDate',
    render: item => formatDate(item.registrationApproveDate),
  },
  {
    title: (
      <span>
        CO<sub>2</sub>eq reduction (t)
      </span>
    ),
    keyItem: 'tonnesOfCO2eq',
    render: item => (item.tonnesOfCO2eq || 0).toFixed(3),
  },
  {
    title: 'Volume',
    keyItem: 'volume',
    render: item => formatVolume(item.volume),
  },
];
