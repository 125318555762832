import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectedRow } from '../../../../../store/reducers/admin/adminReducer';
import { fetchCompanyInfo, fetchEditCompany } from '../../../../../API/admin/accounts';
import { actions } from '../../../../../API/fetchProvider/fetch';
import { URL_COMPANY_DOWNLOAD_SIGNED_LETTER } from '../../../../../API/urls/urls';
import fileDownload from 'js-file-download';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { useNotification } from '../../../../../hooks/useNotification';
import CompanyInfoData from '../../../../components/CompanyInfoData/CompanyInfoData';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { CompanyType } from '@prisma/client';

export const AccountCompanyInfo: React.FC = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectedRow);
  const user = useAppSelector(selectUser);

  const isEditable = user?.company?.type === CompanyType.Admin;

  const formattedAccountData = {
    ...account,
    approveDate: formatDate(account.approveDate, DATE_WITH_TIME_FORMAT),
  };

  const handleTransformAndDispatchData = async (data: any) => {
    try {
      await dispatch(fetchEditCompany(data));

      toast.success({ message: 'Success', description: 'Account has been updated' });

      if (account) {
        await dispatch(fetchCompanyInfo(account.id));
      }

      return true;
    } catch (error: any) {
      const message = error?.response?.data?.message || [];

      toast.error({ message: 'Error', description: (<>
          Account update failed{message.length > 0 ? ':' : ''}
          {message.length > 0 && (<ul style={{marginTop:10, marginBottom: 0, padding: 0}}>
            {message.map((m: string, idx: number) => <li key={idx}>{m}</li>)}
          </ul>)}
        </>) });

      return false;
    }
  };

  const onDownloadLetter = async () => {
    const result:
      | {
          data: Blob;
        }
      | undefined = (await actions
      .get(URL_COMPANY_DOWNLOAD_SIGNED_LETTER.replace(':id', account.id), {
        responseType: 'blob',
      } as any)
      .catch(({ response }) => {
        toast.error({ message: 'Error',  description: response.statusText });
      })) as any;

    if (result?.data) {
      fileDownload(result?.data, `signed_letter_${account.id}.pdf`);
      toast.success({ message: 'Success', description: 'The file has been downloaded successfully' });
    }
  };

  return (
    <div style={{marginTop: 24}}>
      <CompanyInfoData
          editable={isEditable}
          data={formattedAccountData}
          onSave={(data) => handleTransformAndDispatchData(data)}
          onDownloadSignedLetter={onDownloadLetter}
      />
    </div>
  );
};
