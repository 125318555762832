import React, { MouseEventHandler, useRef, useState } from 'react';
import { actions } from '../../../API/fetchProvider/fetch';
import { URL_DOWNLOAD_RETIREMENT_STATEMENT_FILE } from '../../../API/urls/urls';
import fileDownload from 'js-file-download';
import { useNotification } from '../../../hooks/useNotification';

export const DownloadLink: React.FC<{ id: string, name: string }> = React.memo<{ id: string, name: string }>(({ id, name }) => {
    const toast = useNotification();
    const anchor = useRef<HTMLAnchorElement>(null);

    const [isLoading, setIsLoading] = useState(false);
    const onClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
        setIsLoading(true);

        if (!anchor.current) {
            return;
        }

        const result: { data: Blob } | undefined = await actions.get(URL_DOWNLOAD_RETIREMENT_STATEMENT_FILE.replace(':id', id), { responseType: 'blob' } as any).catch(({ response }) => {
            toast.error({ message: 'Error', description: response.statusText });
        }) as any;

        if (result?.data) {
            fileDownload(result?.data, name);
            toast.success({ message: 'Success', description: 'The file has been downloaded successfully' });
        }

        setIsLoading(false);
    };

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
            <a ref={anchor} hidden></a>
            <button
                className="pdf-link"
                onClick={onClick}
                disabled={isLoading}>
                <span className="material-icons-outlined">picture_as_pdf</span>
            </button>
        </>
    );
});
