import React, { FC, useEffect, useState } from 'react';
import './CompanyInfoData.scss';
import {
  COMPANY_TYPE_NAMES,
  CompanyStatusOptions,
  CompanyType,
  RegistrationIdentifications,
} from '../../../structure/models/company/company';
import { EditButtons } from '../EditButtons/EditButtons';
import DataTable, {
  DataKeyType,
  ProcessedDataKeyType,
} from '../DataTable/DataTable';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../core/models/contstants/format-date';
import { DatePicker, Input, Select, Tag } from 'antd';
import { CODES_OPTIONS } from '../Form/models/codes.constant';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { diff } from 'deep-object-diff';
import { Attachment, UploadAttachment } from '../Attachment/Attachment';
import { RcFile } from 'antd/lib/upload';
import {
  addAttachment,
  downloadAttachment,
  removeAttachment,
} from '../../../API/attachments';
import { RelationTypes } from '../../../API/admin/notes';
import fileDownload from 'js-file-download';
import { useAppDispatch } from '../../../store/hooks';
import { CompanyStatus } from '@prisma/client';
import { COUNTRIES } from '../../../constants/countries.constant';

const RangePicker = DatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
).RangePicker;

export const getCompanyInfoKeys = (
  data: any,
  hideId = false,
  handleUploadFile = (kind: string) => (file: RcFile) => Promise.resolve(false),
  handleOpenFile = (file: UploadAttachment) => {},
  handleRemoveFile = (file: UploadAttachment) => Promise.resolve(false),
  editable?: boolean,
): { fields: DataKeyType[]; label?: string }[] => {
  const isSupplier: boolean = data?.type === CompanyType.Supplier;

  const registrationIdentificationOptions = [
    {
      value: RegistrationIdentifications.RegistrationNumber,
      label: 'Registration number',
    },
    {
      value: RegistrationIdentifications.IdentificationNumber,
      label: 'Identification number',
    },
    {
      value: RegistrationIdentifications.VatIdentificationNumber,
      label: 'VAT identification number',
    },
    {
      value: RegistrationIdentifications.TaxRegistrationNumber,
      label: 'Tax registration number',
    },
    {
      value: RegistrationIdentifications.TradeRegisterNumber,
      label: 'Trade register number',
    },
  ];

  return [
    {
      fields: [
        ...(hideId
          ? []
          : [
              {
                label: 'Account ID',
                key: 'id',
                value: (v: any) => v.id,
              },
            ]),
        {
          label: 'Company name',
          key: 'name',
          type: 'text',
          editable: true,
          value: v => v.name,
        },
        {
          label: 'Registry account type',
          key: 'type',
          value: v => v.type,
          render: v => COMPANY_TYPE_NAMES[v.type],
        },
        ...(isSupplier
          ? [
              {
                label: 'RSB PO number',
                key: 'RSBPONumber',
                type: 'text',
                editable: true,
                value: v => v.RSBPONumber,
              } as DataKeyType,
              {
                label: 'Certificate validity date',
                editable: true,
                value: v => v.certificateValidityStartDate,
                render: v =>
                  `${formatDate(
                    v.certificateValidityStartDate!,
                  )} - ${formatDate(v.certificateValidityEndDate!)}`,
                renderEdit: (v, onChange) => {
                  return (
                    <RangePicker
                      className="c-data-table__input"
                      allowEmpty={[false, false]}
                      format="dd.MM.yyyy"
                      value={[
                        new Date(
                          v.certificateValidityStartDate
                            ? v.certificateValidityStartDate
                            : new Date().toISOString(),
                        ),
                        new Date(
                          v.certificateValidityEndDate
                            ? v.certificateValidityEndDate
                            : new Date().toISOString(),
                        ),
                      ]}
                      onChange={(dates, strings) => {
                        const [start, end] = dates as Date[];

                        onChange({
                          ...v,
                          certificateValidityStartDate: start,
                          certificateValidityEndDate: end,
                        });
                      }}
                    />
                  );
                },
              } as DataKeyType,
            ]
          : []),
        {
          label: 'Company registration identification',
          key: 'identificationType',
          type: 'select',
          editable: true,
          options: () => registrationIdentificationOptions,
          render: v =>
            registrationIdentificationOptions.find(
              o => o.value === v.identificationType,
            )?.label || '',
          value: v => v.identificationType,
        },
        {
          label: 'Company legal identification number',
          key: 'identification',
          type: 'text',
          editable: true,
          value: v => v.identification,
        },
        {
          label: 'Address',
          editable: true,
          value: v => v.address,
          render: v =>
            `${v.address} ${v.addressNumber} ${v.city}, ${v.country} ${v.zip}`,
          renderEdit: (v, onChange) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 5,
                  padding: '5px 0',
                  width: '100%',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <label
                    style={{ flex: 'none', minWidth: 100 }}
                    htmlFor="companyInfoDataAddress">
                    Street 1:
                  </label>
                  <Input
                    id="companyInfoDataAddress"
                    className="c-data-table__input"
                    value={v.address}
                    onChange={e => onChange({ ...v, address: e.target.value })}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <label
                    style={{ flex: 'none', minWidth: 100 }}
                    htmlFor="companyInfoDataAddress2">
                    Street 2:
                  </label>
                  <Input
                    id="companyInfoDataAddress2"
                    className="c-data-table__input"
                    value={v.addressNumber}
                    onChange={e =>
                      onChange({ ...v, addressNumber: e.target.value })
                    }
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <label
                    style={{ flex: 'none', minWidth: 100 }}
                    htmlFor="companyInfoDataCity">
                    City:
                  </label>
                  <Input
                    id="companyInfoDataCity"
                    className="c-data-table__input"
                    value={v.city}
                    onChange={e => onChange({ ...v, city: e.target.value })}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <label
                    style={{ flex: 'none', minWidth: 100 }}
                    htmlFor="companyInfoDataCountry">
                    Country:
                  </label>
                  <Select
                    id="companyInfoDataCountry"
                    className="c-data-table__input"
                    showSearch={true}
                    options={COUNTRIES as any}
                    value={v.country}
                    onChange={(e: any) => {
                      onChange({ ...v, country: e });
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}>
                  <label
                    style={{ flex: 'none', minWidth: 100 }}
                    htmlFor="companyInfoDataZip">
                    Post code:
                  </label>
                  <Input
                    id="companyInfoDataZip"
                    className="c-data-table__input"
                    value={v.zip}
                    onChange={e => onChange({ ...v, zip: e.target.value })}
                  />
                </div>
              </div>
            );
          },
        },
        {
          label: 'Website',
          key: 'website',
          type: 'text',
          editable: true,
          value: v => v.website,
        },
        {
          label: 'Phone',
          key: 'phone',
          editable: true,
          value: v => v.phone,
          render: v => (v.phone ? `${v.phone.code}${v.phone.number}` : ''),
          renderEdit: (v, onChange) => {
            return (
              <div style={{ display: 'flex', gap: 10, width: '100%' }}>
                <div style={{ flex: 1 }}>
                  <Select
                    className="c-data-table__input"
                    options={CODES_OPTIONS}
                    value={v.phone?.code}
                    onChange={e =>
                      onChange({
                        number: v.phone?.number,
                        code: e,
                      })
                    }
                  />
                </div>
                <div style={{ flex: 2 }}>
                  <Input
                    className="c-data-table__input c-data-table__input--no-controls"
                    type="number"
                    onChange={e =>
                      onChange({
                        code: v.phone?.code,
                        number: e.target.value,
                      })
                    }
                    value={v.phone?.number}
                  />
                </div>
              </div>
            );
          },
        },
        {
          label: 'Registration date',
          key: 'createdAt',
          value: v => v.createdAt,
          render: v =>
            v.createdAt ? formatDate(v.createdAt, DATE_WITH_TIME_FORMAT) : '-',
        },
        {
          label: 'Signed company letter',
          key: 'signedLetters',
          value: () => null,
          render: v => {
            const files = (v?.signedLetters || []).map((f: any) => ({
              ...f.attachment,
              uid: f.attachment?.id?.toString(),
            }));
            return (
              <Attachment
                accept="application/pdf"
                readonly={!editable}
                files={files}
                handleUpload={handleUploadFile('signedLetters')}
                handleOpen={handleOpenFile}
                handleRemove={handleRemoveFile}
              />
            );
          },
        },
        {
          label: 'Status',
          key: 'status',
          type: 'select',
          editable: true,
          options: () =>
            CompanyStatusOptions.filter(
              companyStatusOptions => companyStatusOptions.value,
            ),
          value: v => v.status,
          render: v => {
            let color = 'default';

            switch (v.status) {
              case CompanyStatus.Active:
                color = 'green';
                break;
              case CompanyStatus.Pending:
                color = 'yellow';
                break;
              case CompanyStatus.Suspended:
                color = 'red';
                break;
              default:
                color = 'default';
                break;
            }

            return (
              <Tag bordered={false} color={color}>
                {v.status}
              </Tag>
            );
          },
        },
      ],
    },
  ];
};

const CompanyInfoData: FC<{
  data: any;
  editable?: boolean;
  hideId?: boolean;
  onSave?: (data: any) => Promise<boolean>;
  onDownloadSignedLetter?: () => void;
}> = ({ data, editable, hideId = false, onSave, onDownloadSignedLetter }) => {
  const dispatch = useAppDispatch();
  const [groups, setGroups] = useState<
    Array<{
      label?: string;
      fields: DataKeyType[];
      processedFields: ProcessedDataKeyType[];
    }>
  >([]);
  const [isEdit, setIsEdit] = useState(false);
  const [innerData, setInnerData] = useState(
    JSON.parse(JSON.stringify(data || {})),
  );
  const [preSaveData, setPreSaveData] = useState(
    JSON.parse(JSON.stringify(data || {})),
  );

  const handleFieldChange = (field: ProcessedDataKeyType, value: any) => {
    if (typeof field.key !== 'undefined') {
      setInnerData((prevData: any) => ({
        ...prevData,
        [field.key as string]: value,
      }));
    } else {
      setInnerData((prevData: any) => ({
        ...prevData,
        ...value,
      }));
    }
  };

  const handleUploadFile = (kind: string) => async (file: RcFile) => {
    if (!editable) {
      return false;
    }

    const formData = new FormData();

    formData.set('file', file as any);

    return dispatch(
      addAttachment(formData, RelationTypes.ACCOUNT, kind, innerData.id),
    ).then(
      (response: any) => {
        const attachment = response?.data?.data;
        const attachmentEntity = {
          entityId: innerData.id,
          attachmentId: attachment?.id,
          attachment,
        };

        // add attachment to list
        handleFieldChange({ key: 'signedLetters' } as ProcessedDataKeyType, [
          ...innerData.signedLetters,
          attachmentEntity,
        ]);
        setPreSaveData((prevData: any) => ({
          ...prevData,
          signedLetters: [...prevData.signedLetters, attachmentEntity],
        }));

        return attachment;
      },
      () => false,
    );
  };

  const handleOpenFile = async (file: UploadAttachment) => {
    return dispatch(downloadAttachment(file.id) as any).then(
      (response: any) => {
        fileDownload(response.data, file.name);
      },
    );
  };

  const handleRemoveFile = async (file: UploadAttachment) => {
    if (!editable) {
      return false;
    }

    return dispatch(removeAttachment(file.id)).then(
      () => {
        // remove attachment from list
        handleFieldChange(
          { key: 'signedLetters' } as ProcessedDataKeyType,
          innerData.signedLetters.filter(
            (f: any) => f.attachmentId !== file.id,
          ),
        );
        setPreSaveData((prevData: any) => ({
          ...prevData,
          signedLetters: prevData.signedLetters.filter(
            (f: any) => f.attachmentId !== file.id,
          ),
        }));

        return true;
      },
      () => false,
    );
  };

  useEffect(() => {
    const d = JSON.parse(JSON.stringify(data));

    setInnerData(d);
    setPreSaveData(d);
  }, [data]);

  useEffect(() => {
    setGroups(
      getCompanyInfoKeys(
        innerData,
        hideId,
        handleUploadFile,
        handleOpenFile,
        handleRemoveFile,
        editable,
      ).map(keys => {
        const processedFields = (keys.fields || []).map(field => ({
          ...field,
          options: field.options && field.options(innerData),
          value: field.value(innerData),
          ...(field.render ? { render: field.render(innerData) } : {}),
        }));

        return {
          ...keys,
          processedFields: processedFields.map(processedField => ({
            ...processedField,
            ...(processedField.renderEdit
              ? {
                  renderEdit: processedField.renderEdit(innerData, v =>
                    handleFieldChange(processedField, v),
                  ),
                }
              : {}),
          })),
        };
      }),
    );
  }, [innerData, onDownloadSignedLetter, editable]);

  const handleEditCancel = () => {
    setInnerData(JSON.parse(JSON.stringify(preSaveData)));
    setIsEdit(false);
  };

  const handleEditSave = async () => {
    if (!onSave) {
      return true;
    }

    const differences = diff(preSaveData, innerData);

    // No changes made
    if (Object.keys(differences).length === 0) {
      setIsEdit(false);
      return true;
    }

    // Only save differences between the original and inner data
    return onSave({
      ...differences,
      phone: innerData.phone || data.phone,
      id: data.id,
    }).then(isSaveSuccessful => {
      if (isSaveSuccessful) {
        setIsEdit(false);
        setPreSaveData({
          ...innerData,
          ...differences,
        });
      }
      return isSaveSuccessful;
    });
  };

  return (
    <div className="c-company-info-data">
      <div className="c-company-info-data__wrapper">
        <DataTable
          data={innerData}
          edit={isEdit}
          columns={groups}
          onChange={handleFieldChange}
        />
        {editable && (
          <EditButtons
            isEdit={isEdit}
            onEdit={() => setIsEdit(true)}
            onSave={handleEditSave}
            onCancel={handleEditCancel}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyInfoData;
