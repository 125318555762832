import {FC} from "react";
import "./FormTitleStyles.scss"
import removeBatch from '../../../../../assets/closeRemovePdf.svg'

interface IFormTitleProps {
    title: string
    handleRemoveBatch?: () => void;
    index?: number;
}

const FormTitle: FC<IFormTitleProps> = ({title, handleRemoveBatch, index}) => {
    const shouldShowRemoveBatch = title.includes("Batch") && index !== undefined && index > 0;
    return (
        <div className="form-title--reg">
            <span className="form-title--text">{title}</span>
            
            {shouldShowRemoveBatch && handleRemoveBatch &&(
                <img 
                    className="remove_batch"
                    src={removeBatch} 
                    alt="Remove Batch" 
                    onClick={() => handleRemoveBatch()}
                />
            )}
        </div>
    );
};
export default FormTitle;
