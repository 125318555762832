import React, { FC } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyType } from '../../structure/models/company/company';
import { SupplierPage } from './Pages/SupplierPage';
import { CorporatePage } from './Pages/CorporatePage';
import { AirlinePage } from './Pages/AirlinePage';
import { ForwarderPage } from './Pages/ForwarderPage';
import { useGetCreditsQuery } from '../../API';
import { IntermediaryPage } from './Pages/IntermediaryPage';

export interface CreditOption {
  value: string;
  label: string;
  credit: any;
}

export const Transfer: FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const { data: credits } = useGetCreditsQuery();

  const creditsOptions: CreditOption[] = (credits || []).map((credit: any) => ({
    credit,
    value: credit.id,
    label: credit.BCUID,
  }));

  switch (user?.company.type) {
    case CompanyType.Supplier:
      return <SupplierPage creditsOptions={creditsOptions} />;
    case CompanyType.Intermediary:
      return <IntermediaryPage creditsOptions={creditsOptions} />;
    case CompanyType.Airline:
      return <AirlinePage creditsOptions={creditsOptions} />;
    case CompanyType.Corporate:
      return <CorporatePage creditsOptions={creditsOptions} />;
    case CompanyType.Forwarder:
      return <ForwarderPage creditsOptions={creditsOptions} />;
    default:
      return null;
  }
});
