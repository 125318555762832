import { phoneRegexp } from "../../../../constants";
import { BasedUserFields } from "../../../../structure/models/user/user";
import { CODES } from "../../../components/Form/models/codes.constant";

export const basedUserFieldsValidator = (
  account: BasedUserFields,
): { [key in keyof BasedUserFields]: (value: any) => { error: boolean; text: string } } => {
  return {
    name: (value: string) => ({
      error: !value,
      text: 'Name is required',
    }),
    surname: (value: string) => ({
      error: !value,
      text: 'Surname is required',
    }),
    position: (value: string) => ({
      error: !value,
      text: 'Position is required',
    }),
    phone_code: (value: string) => ({
      error: !CODES.includes(value),
      text: 'Code must have correct format',
    }),
    phone_number: (value: string) => ({
      error: !phoneRegexp.test(value),
      text: 'Phone must have correct format',
    }),
    agreement: (value: string) => ({
      error: !value,
      text: 'Agreement is required',
    }),
    password: (value: string) => ({
      error: !value,
      text: 'Password is required',
    }),
    confirmPassword: (value: string) => {
      const password = account['password'];
      return {
        error: !value || password !== value,
        text: 'Passwords do not match',
      };
    },
  };
};
