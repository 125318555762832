import { actions } from './fetchProvider/fetch';
import { URL_ATTACHMENTS_ADD, URL_ATTACHMENTS_DOWNLOAD, URL_ATTACHMENTS_REMOVE } from './urls/urls';

export const addAttachment = (data: any, type: string, kind: string, id: number) => () => {
    return actions.post(URL_ATTACHMENTS_ADD.replace(':type', type).replace(':id', id.toString()).replace(':kind', kind), {
        payloads: data,
    });
};

export const downloadAttachment = (attachmentId: number) => () => {
    return actions.get(URL_ATTACHMENTS_DOWNLOAD.replace(':id', attachmentId.toString()), { responseType: 'blob' } as any);
};

export const removeAttachment = (attachmentId: number) => () => {
    return actions.delete(URL_ATTACHMENTS_REMOVE.replace(':id', attachmentId.toString()));
};


