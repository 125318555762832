type Config = {
  API_URL: string;
  SSE_URL: string;
  APP_VERSION: string;
  APP_STAGE: 'development' | 'test' | 'production',
};

export const config: Config = {
  API_URL: process.env.REACT_APP_API_URL!,
  SSE_URL: process.env.REACT_APP_SSE_URL!,
  APP_VERSION: process.env.REACT_APP_VERSION!,
  APP_STAGE: (process.env.NODE_ENV! || 'production') as any,
};

export function isDev(): boolean {
  return config.APP_STAGE === 'development';
}

export function isTest(): boolean {
  return config.APP_STAGE === 'test';
}

export function isProd(): boolean {
  return config.APP_STAGE === 'production';
}