import React, { useEffect, useMemo, useState } from 'react';
import {
  selectIncomingCredits,
} from '../../../../store/reducers/user/creditsReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  fetchIncomingCreditApprove,
  fetchIncomingCreditReject,
  fetchIncomingCredits,
} from '../../../../API/credits';
import { ConfirmationPrompt } from '../../../components/ConfirmationPrompt';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import NextButton from '../../../components/Buttons/NextButton/NextButton';
import { TRANSFER_ROUTES } from '../../../../structure/routes/routes';
import { TransactionStatus } from '@prisma/client';
import { NavigationTabs } from '../../../components/NavigationTabs/NavigationTabs';
import { useNotification } from '../../../../hooks/useNotification';

enum ACTIONS {
    APPROVE = 'Approve',
    DENY = 'Deny',
}

const CONFIRMATION_TEXT: Record<ACTIONS, string> = {
    [ACTIONS.APPROVE]: 'Are you sure you want to approve this transaction?',
    [ACTIONS.DENY]: 'Are you sure you want to reject this transaction?',
};

export const IncomingTransactionPage: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const data = useAppSelector(selectIncomingCredits);

  const tabs = [
    { path: TRANSFER_ROUTES.INCOMING_TRANSACTION.replace(':id', id || ''), title: 'BCU Info' },
    { path: TRANSFER_ROUTES.INCOMING_TRANSACTION_SCOPE_1.replace(':id', id || ''), title: 'Scope 1 Info' },
    { path: TRANSFER_ROUTES.INCOMING_TRANSACTION_SCOPE_3.replace(':id', id || ''), title: 'Scope 3 Info' },
  ];

  const item = useMemo(() => Array.isArray(data) && id ? data.find((i) => i.id.toString() === id) : null, [data, id]);

  const [context, setContext] = useState<ACTIONS | null>(null);

  const onConfirmationClose = (status: boolean) => {
    if (status) {
      if (context === ACTIONS.APPROVE)
        dispatch(fetchIncomingCreditApprove(id || '')).then(() => {
            toast.success({ message: 'Success', description: 'Transaction has been approved' });
        });
      if (context === ACTIONS.DENY)
        dispatch(fetchIncomingCreditReject(id || '')).then(() => {
            toast.success({ message: 'Success', description: 'Transaction has been denied' });
        });
    }
    setContext(null);
    dispatch(fetchIncomingCredits());
  };

  const onApproveClick = () => {
    setContext(ACTIONS.APPROVE);
  };
  const onRejectClick = () => {
    setContext(ACTIONS.DENY);
  };

  useEffect(() => {
    dispatch(fetchIncomingCredits());
  }, []);

  return (
    <>
      <div className="c-transfer">
        <div className="c-transfer__container">
          <form className="c-transfer__form">
            <div className="c-transfer__row">
              <div className="c-transfer__col">
                <div className="c-transfer__form__header">
                  Incoming Transaction
                </div>

                <NavigationTabs tabs={tabs} />

                <Outlet />
            </div>
            </div>

            <div className="align-right mt-5 d-flex justify-content-end">
              <NextButton
                handleClick={() => navigate(TRANSFER_ROUTES.INCOMING_TRANSACTIONS)}
                text="Back"
                style={{
                  margin: '0 15px 0 0',
                  width: '100px',
                  color: '#5C6D76',
                  backgroundColor: '#455A641A',
                }}
              />
              <NextButton
                handleClick={onRejectClick}
                text="Deny"
                disabled={item?.status !== TransactionStatus.Pending}
                style={{
                  margin: '0 15px 0 0',
                  width: '100px',
                  color: '#FF3D00',
                  opacity: item?.status !== TransactionStatus.Pending ? '0.5' : '1',
                  backgroundColor: '#FF3D001A',
                }}
              />
              <NextButton
                handleClick={onApproveClick}
                disabled={
                  item?.status !== TransactionStatus.Pending
                }
                text="Approve"
                style={{ margin: '0', width: '100px' }}
              />
            </div>
          </form>
        </div>
      </div>

      <ConfirmationPrompt onClosed={onConfirmationClose} show={!!context}>
        <p>{CONFIRMATION_TEXT[context!]}</p>
      </ConfirmationPrompt>
    </>
  );
});
