import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyType } from '../../structure/models/company/company';

import './RetirementStyle.scss';
import { CorporatePage } from './Pages/CorporatePage';
import { useGetCreditsQuery } from '../../API';
import { AirlinePage } from './Pages/AirlinePage';
import { ForwarderPage } from './Pages/ForwarderPage';

export const Retirement: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const { data: credits } = useGetCreditsQuery();

  const creditsOptions = (credits || []).map((credit: any) => ({
    credit,
    value: credit.id,
    label: credit.BCUID,
  }));

  switch (user?.company.type) {
    case CompanyType.Airline:
      return <AirlinePage creditsOptions={creditsOptions} />;
    case CompanyType.Corporate:
      return <CorporatePage creditsOptions={creditsOptions} />;
    case CompanyType.Forwarder:
    default:
      return <ForwarderPage creditsOptions={creditsOptions} />;
  }
});
