import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { BaseButton } from '../../../Buttons/BaseButton/BaseButton';
import { fetchLogOut } from '../../../../../API/login';
import {
  CHANGE_PASSWORD,
  CONTACT_LIST,
  DASHBOARD_ROUTES,
  PROFILE_ROUTES,
  STATEMENTS,
} from '../../../../../structure/routes/routes';
import { GenerateTokenModal } from './GenerateTokenModal';
import { Role, UserStatus } from '../../../../../structure/models/user/user';
import { Avatar } from '../../../Avatar/Avatar';

import './UserMenuStyle.scss';

export const UserMenu: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openTokenModal, setTokenModal] = useState<boolean>(false);

  const isUserSuspended = useMemo(
    () => user?.status === UserStatus.Suspended,
    [user],
  );

  const name = `${user?.firstName} ${user?.lastName}`;
  const initials = [(user?.firstName || '')[0], (user?.lastName || '')[0]]
    .filter(l => !!l)
    .map(l => l.toUpperCase());

  const onClick = () => {
    dispatch(fetchLogOut());
    navigate(STATEMENTS);
  };

  const switchTokenModalVisible = () => setTokenModal(!openTokenModal);

  const goToPage = (url: string) => () => navigate(url);

  const onMoveToContactList = () => {
    navigate(
      user!.role === Role.Auditor
        ? CONTACT_LIST
        : DASHBOARD_ROUTES.CONTACT_LIST,
    );
  };

  const createAccess = (roles: Role[]) => !roles.includes(user!.role);

  const isUserAvailable = createAccess([
    Role.Admin,
    Role.SuperAdmin,
    Role.Auditor,
  ]);
  const isUserAndAuditorAvailable = createAccess([Role.Admin, Role.SuperAdmin]);

  return (
    <div className="user_menu">
      <div id="userName" className="name">
        <Avatar className="name__avatar" size="large">
          {initials}
        </Avatar>
        <div className="name__info">
          <span className="name__company-name">{user?.company.name}</span>
          <span>{name}</span>
        </div>
        <span className="material-icons-outlined">arrow_drop_down</span>
      </div>
      <div className="menu">
        {isUserAvailable && (
          <>
            <div className="menu_content">
              <BaseButton onClick={switchTokenModalVisible} active={true}>
                <span className="material-icons-outlined">
                  add_circle_outline
                </span>
                Generate token
              </BaseButton>
            </div>
            <div className="menu_content">
              <BaseButton
                onClick={goToPage(PROFILE_ROUTES.COMPANY_PROFILE)}
                active={true}>
                <span className="material-icons-outlined">account_circle</span>
                Profile settings
              </BaseButton>
            </div>
          </>
        )}
        {isUserAndAuditorAvailable && (
          <div className="menu_content">
            <BaseButton onClick={onMoveToContactList} active={true}>
              <span className="material-icons-outlined">
                perm_contact_calendar
              </span>
              Contact list
            </BaseButton>
          </div>
        )}
        {isUserSuspended || (
          <div className="menu_content">
            <BaseButton
              id="changePassword"
              onClick={goToPage(CHANGE_PASSWORD)}
              active={true}>
              <span className="material-icons-outlined">vpn_key</span>
              Change password
            </BaseButton>
          </div>
        )}
        <div className="menu_content">
          <BaseButton id="logout" onClick={onClick} active={true}>
            <span className="material-icons-outlined">logout</span>
            Log Out
          </BaseButton>
        </div>
      </div>
      <GenerateTokenModal
        show={openTokenModal}
        onHide={switchTokenModalVisible}
      />
    </div>
  );
});
