import { FormikErrors } from 'formik';
import { MyBatchFormValue } from './types';

export const validateBatchForm = (
  values: MyBatchFormValue,
  setErrors: (errors: FormikErrors<MyBatchFormValue>) => void,
) => {
  const errors: Partial<MyBatchFormValue> = { batchInformation: [] };

  values.batchInformation.forEach((batch, index) => {
    const batchErrors: Partial<typeof batch> = {};

    const isAnotherBatchFiledIdError =
      values.batchInformation
        .map(batch => batch.fieldID)
        .filter(batchId => batchId === batch.fieldID).length > 1;

    if (isAnotherBatchFiledIdError) {
      batchErrors.fieldID = 'This batch ID cannot be the same';
    }

    if (!batch.fieldTonnes) {
      batchErrors.fieldTonnes = 'Field is required';
    }

    if (!batch.fieldMJ) {
      batchErrors.fieldMJ = 'Field is required';
    }

    if (!batch.dateProd) {
      batchErrors.dateProd = 'Field is required';
    } else if (!/^\d{4}$/.test(batch.dateProd)) {
      batchErrors.dateProd = 'Field should contain exactly 4 digits';
    } else if (parseInt(batch.dateProd) < 1900) {
      batchErrors.dateProd = 'Number should not be before 1900';
    } else if (parseInt(batch.dateProd) > new Date().getFullYear()) {
      batchErrors.dateProd = `Number should not exceed ${new Date().getFullYear()}`;
    }

    if (!batch.dateBlend) {
      batchErrors.dateBlend = 'Field is required';
    }

    if (!batch.fieldCO2) {
      batchErrors.fieldCO2 = 'Field is required';
    }

    if (!batch.fieldBatchCountryOf) {
      batchErrors.fieldBatchCountryOf = 'Field is required';
    }

    // @see RBCRB-370 - Set fieldSaf as optional
    // if (!batch.fieldSaf) {
    //   batchErrors.fieldSaf = 'Field is required';
    // }

    if (Object.keys(batchErrors).length > 0) {
      (errors.batchInformation as Array<Partial<typeof batch>>)[index] =
        batchErrors;
    }
  });

  setErrors(errors);

  return errors;
};
