import React, { memo, ReactNode } from 'react';
import { Modal } from 'antd';
import NextButton from '../Buttons/NextButton/NextButton';
import './Confirmation.scss';
import classNames from 'classnames';

interface ConfirmationPromptI {
  title?: string;
  trueText?: string;
  centralized?: boolean;
  falseText?: string;
  isFalseButton?: boolean;
  onClosed: (state: boolean) => void;
  children?: ReactNode;
  show: boolean;
}

export const ConfirmationPrompt = memo(
  ({
    title,
    show,
    children,
    onClosed,
    trueText = 'OK',
    isFalseButton = true,
    centralized = true,
    falseText = 'Cancel',
  }: ConfirmationPromptI) => {
    return (
      <Modal
        open={show}
        keyboard={false}
        centered
        closeIcon={false}
        className={classNames({ centralized: centralized })}
        title={title && (
            <div className="confirmation__title">
                <h4>{title}</h4>
            </div>
        )}
        footer={
            <div className="align-right d-flex justify-content-end">
                {isFalseButton && (
                    <NextButton
                        handleClick={() => onClosed(false)}
                        text={falseText}
                        style={{
                            margin: '0 15px 0 0',
                            width: '100px',
                            color: '#5C6D76',
                            backgroundColor: '#455A641A',
                        }}
                    />
                )}
                <NextButton
                    handleClick={() => onClosed(true)}
                    text={trueText}
                    style={{ margin: '0', width: '100px' }}
                />
            </div>
        }>
          {children}
      </Modal>
    );
  },
);
