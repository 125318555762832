import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { RetirementStatement } from '../../../structure/models/retirement-statements/retirement-statements';

interface RetirementStatementsPayload {
  loading: boolean;
  items: RetirementStatement[];
  page: number;
  limit: number;
  total: number;
}
interface RetirementStatementsState {
  status: 'idle' | 'loading' | 'failed';
  publicList: RetirementStatementsPayload;
  privateList: RetirementStatementsPayload;
}

export const initPublicList = {
  loading: true,
  page: 1,
  limit: 50,
  total: 0,
  items: [],
};

const initialState: RetirementStatementsState = {
  status: 'idle',
  publicList: initPublicList,
  privateList: initPublicList,
};

export const retirementStatementsSlice = createSlice({
  name: 'retirement-statements',
  initialState,
  reducers: {
    setRetirementStatementsPublicList: (state, action: PayloadAction<any>) => {
      state.publicList = action.payload;
    },
    setRetirementStatementsPrivateList: (state, action: PayloadAction<any>) => {
      state.privateList = action.payload;
    },
    setRetirementStatementsStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
  },
});

export const {
  setRetirementStatementsStatus,
  setRetirementStatementsPublicList,
  setRetirementStatementsPrivateList,
} = retirementStatementsSlice.actions;

export const selectRetirementStatementsPublicList = (state: RootState) =>
  state.retirementStatements.publicList;
export const selectRetirementStatementsStatus = (state: RootState) =>
  state.retirementStatements.status;
export const selectRetirementStatementsPrivateList = (state: RootState) =>
    state.retirementStatements.privateList;

export default retirementStatementsSlice.reducer;
