import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { fetchEditUser } from '../../../../../API/admin/users';
import { useNotification } from '../../../../../hooks/useNotification';
import { useAsyncValue } from 'react-router-dom';
import InfoData from '../../../../components/InfoData/InfoData';
import { DataKeyType } from '../../../../components/DataTable/DataTable';
import { Input, Select, Tag } from 'antd';
import { CODES_OPTIONS } from '../../../../components/Form/models/codes.constant';
import { formatDate } from '../../../../core/models/contstants/format-date';
import { UserStatus, UserStatusOptions } from '../../../../../structure/models/user/user';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { CompanyType } from '@prisma/client';

export const getUserKeys = (data: any): { fields: DataKeyType[]; label?: string }[] => {
    return [
        {
            fields: [
                {
                    label: 'SU ID',
                    key: 'SUID',
                    value: v => v.SUID,
                },
                {
                    label: 'First name',
                    key: 'firstName',
                    type: 'text',
                    editable: true,
                    value: v => v.firstName,
                },
                {
                    label: 'Last name',
                    key: 'lastName',
                    type: 'text',
                    editable: true,
                    value: v => v.lastName,
                },
                {
                    label: 'Email',
                    key: 'email',
                    value: v => v.email,
                },
                {
                    label: 'Position',
                    key: 'position',
                    type: 'text',
                    editable: true,
                    value: v => v.position,
                },
                {
                    label: 'Company',
                    key: 'company',
                    value: v => v.company?.name,
                },
                {
                    label: 'Cell phone',
                    key: 'cellPhone',
                    editable: true,
                    value: v => v.cellPhone?.code,
                    render: v => v.cellPhone ? `${v.cellPhone?.code || ''}${v.cellPhone?.number || ''}` : '',
                    renderEdit: (v, onChange) => {
                        return (
                            <div style={{ display: 'flex', gap: 10, width: '100%' }}>
                                <div style={{ flex: 1 }}>
                                    <Select className="c-data-table__input" options={CODES_OPTIONS}
                                            value={v.cellPhone?.code} onChange={(e) => onChange({
                                        number: v.cellPhone?.number,
                                        code: e,
                                    })} />
                                </div>
                                <div style={{ flex: 2 }}>
                                    <Input className="c-data-table__input c-data-table__input--no-controls" type="number" onChange={(e) => onChange({
                                        code: v.cellPhone?.code,
                                        number: e.target.value,
                                    })} value={v.cellPhone?.number} />
                                </div>
                            </div>
                        );
                    },
                },
                {
                    label: 'Registration date',
                    key: 'createdAt',
                    type: 'date',
                    editable: true,
                    value: v => v.createdAt,
                    render: v => v.createdAt ? formatDate(v.createdAt, undefined, true) : 'N/A',
                },
                {
                    label: 'Status',
                    key: 'status',
                    type: 'select',
                    editable: true,
                    options: () => UserStatusOptions.filter((option) => option.value),
                    value: v => v.status,
                    render: v => {
                        let color = 'default';

                        switch (v.status) {
                            case UserStatus.Active:
                                color = 'green';
                                break;
                            case UserStatus.Pending:
                                color = 'yellow';
                                break;
                            case UserStatus.Suspended:
                                color = 'red';
                                break;
                            default:
                                color = 'default';
                                break;
                        }

                        return <Tag bordered={false} color={color}>{v.status}</Tag>;
                    }
                },
            ],
        },
    ];
};

export const UserDetailsInfo: React.FC = () => {
    const toast = useNotification();
    const dispatch = useAppDispatch();
    const data: any = useAsyncValue();
    const keys = getUserKeys(data);
    const user = useAppSelector(selectUser);

    const isEditable = user?.company?.type === CompanyType.Admin;

    const handleTransformAndDispatchData = async (formData: any) => {
        try {
            await dispatch(fetchEditUser({
                id: data?.id,
                ...formData,
            }));
            toast.success({ message: 'Success', description: 'Users have been updated' });

            return true;
        } catch (error) {
            toast.error({ message: 'Error', description: 'Users have not been updated' });
        }

        return false;
    };

    return (
        <div style={{marginTop: 24}}>
            <InfoData data={data} keys={keys} editable={isEditable} onSave={handleTransformAndDispatchData}/>
        </div>
    );
};
