import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { AccountUser, selectUser } from '../../../store/reducers/user/userReducer';

export type GuardProps = {
  canActivate: (user?: AccountUser) => boolean;
  redirect: string;
};

export const guard =
  <TProps extends Record<string, any>>(
    Component: React.FC<TProps>,
  ): React.FC<GuardProps & TProps> =>
  ({ canActivate, redirect, ...props }) => {
    const user = useAppSelector(selectUser);

    return canActivate(user) ? (
      <Component {...(props as unknown as TProps)} />
    ) : (
      <Navigate to={redirect} />
    );
  };
