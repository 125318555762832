import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface AdminDashboardState {
  status: 'idle',
  selectedRow: null | any,
  visiblePopUp: boolean
}
const initialState: AdminDashboardState = {
  status: 'idle',
  selectedRow: null as any,
  visiblePopUp: false,
};

const adminDashboardSlice = createSlice({
  name: 'admin-dashboard',
  initialState,
  reducers: {
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    setVisiblePopUp: (state, action) => {
      state.visiblePopUp = action.payload;
    },
    updateSelectedRowField: (state, action: PayloadAction<{field: string, value: string}>) => {
      if (state.selectedRow) {
        state.selectedRow[action.payload.field] = action.payload.value;
      }
    },
  },
});

export const {
  setSelectedRow,
  setVisiblePopUp,
  updateSelectedRowField
} = adminDashboardSlice.actions;

export const selectVisiblePopUp  = (state: RootState) => state.adminDashboard.visiblePopUp;
export const selectedRow = (state: RootState) => state.adminDashboard.selectedRow;

export default adminDashboardSlice.reducer;
