import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectedRow } from '../../../../../store/reducers/admin/adminReducer';
import {
  fetchAdminCredits,
} from '../../../../../API/admin/accounts';
import {
  Table,
} from '../../../../components/Table/Table';
import {
  selectCredits,
} from '../../../../../store/reducers/user/creditsReducer';
import { ACCOUNT_BALANCE_COLUMNS } from '../models/columns.constant';

export const AccountBalance: React.FC = () => {
  const dispatch = useAppDispatch();

  const credits = useAppSelector(selectCredits);
  const { id } = useAppSelector(selectedRow);

  useEffect(() => {
    dispatch(fetchAdminCredits(id));
  }, [id]);

  return (
    <div className="account-detail__table">
      <Table
        showHeader
        columns={ACCOUNT_BALANCE_COLUMNS}
        data={credits}
        rowKey={item => item.id}
      />
    </div>
  );
};
