export type FieldsValidator<T> = { [key in keyof T]: (value: any) => { error: boolean; text: string } };

export const getFieldsErrors = (validator: FieldsValidator<any>, form: { [key: string]: any }) => {
    const errorTexts = {} as { [key: string]: string };
    const keys = Object.keys(validator).filter(item => {
        const key = item as keyof typeof form;
        const currentItem = validator[key](form[key]);
        errorTexts[key] = currentItem.error ? currentItem.text : '';

        return currentItem.error;
    });

    return {
        keys,
        errorTexts
    };
};
