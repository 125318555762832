import { actions } from './fetchProvider/fetch';
import { AppThunk, AppThunkDispatch } from '../store/store';
import {
  AccountUser,
  setCompanyData,
  setUserData,
} from '../store/reducers/user/userReducer';
import {
  URL_COMPANY,
  URL_DOWNLOAD_USER_TRANSACTIONS,
  URL_GET_USER,
  URL_UPDATE_ACCOUNT
} from './urls/urls';
import { AxiosResponse } from 'axios';
import { ServerResponse } from './types';
import { PhoneValue } from '../app/components/Form/Phone';
import fileDownload from 'js-file-download';

export const fetchUserData = (): AppThunk<Promise<void>> => dispatch => {
  return actions
    .get<AxiosResponse<ServerResponse<AccountUser>>>(URL_GET_USER)
    .then((userData: AxiosResponse<ServerResponse<AccountUser>>) => {
      dispatch(setUserData(userData.data.data));
    });
};

export const fetchCompanyData = () => (dispatch: AppThunkDispatch) => {
  return actions
    .get(URL_COMPANY)
    .then((resp: any) => dispatch(setCompanyData(resp.data.data)));
};

export const editUserData = (data: { position: string, cellPhone : PhoneValue }) => (dispatch: AppThunkDispatch) => {
  return actions.post(URL_UPDATE_ACCOUNT, { payloads: data })
      .then((resp: any) => fetchUserData());
};

export const downloadUserTransactions = (query: any): Promise<any> =>
  actions
    .get<AxiosResponse>(URL_DOWNLOAD_USER_TRANSACTIONS, {
        responseType: 'arraybuffer',
        queries: {
            ...query,
        },
    })
    .then(response => {
      fileDownload(response.data, 'transactions.xlsx');
    })
    .catch(e => {
      throw e;
    });