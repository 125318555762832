import {
  DATE_WITH_TIME_FORMAT_SHORT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { Column } from '../../../../components/Table/Table';
import {
  ACTION_LOGS_OPTIONS,
  SystemLogTypes,
} from '../../../../../constants/action-logs';
import { Prisma, ActionLogType } from '@prisma/client';
import { isInTypes } from '../../../../../helpers/types';

const isSystemLog = (action: ActionLogType) => isInTypes(action, SystemLogTypes);

export const COLUMNS: Column<Prisma.ActionLogGetPayload<{ include: { user: true }}>>[] = [
  {
    title: 'Date & time',
    keyItem: 'createdAt',
    sort: true,
    render: item => formatDate(item.createdAt, DATE_WITH_TIME_FORMAT_SHORT),
  },
  {
    title: 'Event type',
    keyItem: '',
    render: item => isSystemLog(item.action) ? 'System' : 'User',
  },
  {
    title: 'Action',
    keyItem: 'action',
    render: item =>
      ACTION_LOGS_OPTIONS.find(o => o.value === item.action)?.label || '',
  },
  {
    title: 'SU ID',
    keyItem: 'SUID',
    render: item => {
      if (isSystemLog(item.action)) return 'System';

      return item.user?.SUID || '';
    },
  },
  {
    title: 'Account ID',
    keyItem: 'accountId',
    render: item => {
      if (isSystemLog(item.action)) return 'System';

      return item.user?.companyId || '';
    },
  },
  {
    title: 'User name',
    keyItem: 'contactName',
    render: item => {
      if (isSystemLog(item.action)) return 'System';

      if (!item.user) return '';

      return `${item.user?.firstName || ''} ${item.user?.lastName || ''}`;
    },
  },
  {
    title: 'Details',
    keyItem: 'details',
    render: item => <span className='c-action-log__details'>{item.details}</span>,
  },
];
