import { Option } from '../../../../components/Form/Select/Select';
import { BCUStatuses } from '../../../../../structure/models/bcu/bcu';

export const STATUSES: Option[] = [
  { value: null, label: 'All' },
  { value: BCUStatuses.Active, label: BCUStatuses.Active },
  { value: BCUStatuses.Deposited, label: BCUStatuses.Deposited },

  { value: BCUStatuses.Transit, label: BCUStatuses.Transit },
  { value: BCUStatuses.Merged, label: BCUStatuses.Merged },
  { value: BCUStatuses.Retired, label: BCUStatuses.Retired },
  { value: BCUStatuses.Transcribed, label: BCUStatuses.Transcribed },
  { value: BCUStatuses.Failed, label: BCUStatuses.Failed },
  { value: BCUStatuses.Split, label: BCUStatuses.Split },
  //
  { value: BCUStatuses.Suspended, label: BCUStatuses.Suspended },
  { value: BCUStatuses.Inactive, label: BCUStatuses.Inactive },
  { value: BCUStatuses.Expired, label: BCUStatuses.Expired },
  { value: BCUStatuses.Withdrawn, label: BCUStatuses.Withdrawn },
];
