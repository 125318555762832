import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import DataTable, {
  DataKeyType,
} from '../../../../components/DataTable/DataTable';

const getKeys = (data: any): { fields: DataKeyType[]; label?: string }[] => {
  return [
    {
      fields: [
        {
          label: 'BCU ID',
          value: v => v?.BCUID,
        },
        {
          label: 'Amount BCUs (t)',
          value: v => v?.volume,
        },
        {
          label: (
            <span>
              Amount CO<sub>2</sub>eq reduction (t)
            </span>
          ),
          value: v => v?.tonnesOfCO2eq,
        },
        {
          label: 'Retiring company',
          value: v => v?.company?.name,
        },
      ],
    },
  ];
};

const useGroups = (credit: any) => {
  const groups = useMemo(
    () =>
      getKeys(credit).map(keys => {
        const processedFields = (keys.fields || []).map(field => ({
          ...field,
          options: field.options && field.options(credit),
          value: field.value(credit),
          ...(field.render ? { render: field.render(credit) } : {}),
        }));

        return {
          ...keys,
          processedFields,
        };
      }),
    [credit],
  );

  return groups;
};

export const AdminRetirementInfo: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);

  const transaction = details?.creditTransaction;
  const credit = transaction?.newCredit || transaction?.credit;

  const data = { ...credit, company: details?.company };

  const groups = useGroups(data);

  return (
    <div style={{ margin: '20px 0 30px' }}>
      <DataTable data={data} edit={false} columns={groups} />
    </div>
  );
};
