import { ADMIN_BCU_DETAILS_ROUTES } from '../../../../../structure/routes/routes';

export const TABS = [
  {
    title: 'BCU Values',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS,
  },
  {
    title: 'BCU Info',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_INFO,
  },
  {
    title: 'Scope 1 Info',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_SCOPE1,
  },
  {
    title: 'Scope 3 Info',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_SCOPE3,
  },
  {
    title: 'BCU Events',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY,
  },
  {
    title: 'Note',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_NOTE,
  },
];
