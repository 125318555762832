import React from 'react';
import { TabElement } from './components/TabElement/TabElement';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import { pageAccesses } from '../../core/models/contstants/page-accesses';

import './NavigationTabsStyle.scss';
import { useParams } from 'react-router-dom';
import { TabDef } from '../Header/models/tabs.constant';

export interface NavigationTabsProps {
  tabs: Array<TabDef>;
  replace?: boolean;
  onClick?: () => void;
}

export const NavigationTabs: React.FC<NavigationTabsProps> = React.memo(
  ({ tabs: allTabs, replace = false, onClick }) => {
    const params = useParams();
    const user = useAppSelector(selectUser);
    const tabs = allTabs.filter(tab => {
      const path = Object.entries(params).reduce(
        (acc, [key, value]) => acc.replace(value!, `:${key}`),
        tab.path,
      );

      return pageAccesses[path]?.canActivate(user);
    });

    const onTabClick = () => {
      window.scrollTo(0, 0);
      if (onClick) onClick();
    };

    return (
      <div className="navigation">
        <div className="navigation_container">
          {user && user.status !== 'Active'
            ? tabs.map(
                (tab, idx) =>
                  (tab.title === 'Public Retirements' ||
                    tab.title === 'Dashboard' ||
                    tab.path.includes('/profile')) && (
                    <div key={idx} onClick={onTabClick}>
                      <TabElement
                        link={tab.path}
                        key={tab.path}
                        replace={replace}
                        childRoutes={tab.childRoutes}>
                        <>
                          {tab.title}
                          {tab.Badge ? <tab.Badge /> : null}
                        </>
                      </TabElement>
                      {tab.separator && idx !== tabs.length - 1 && (
                        <span className="c-nav-separator"></span>
                      )}
                    </div>
                  ),
              )
            : tabs.map((tab, idx) => (
                <div key={idx} onClick={onTabClick}>
                  <TabElement
                    link={tab.path}
                    key={tab.path}
                    replace={replace}
                    childRoutes={tab.childRoutes}>
                    <>
                      {tab.title}
                      {tab.Badge ? <tab.Badge /> : null}
                    </>
                  </TabElement>
                  {tab.separator && idx !== tabs.length - 1 && (
                    <span className="c-nav-separator"></span>
                  )}
                </div>
              ))}
        </div>
      </div>
    );
  },
);
