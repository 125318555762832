import { FC } from "react";
import "./blendStyles.scss";
import SummaryField from "../../SummaryField";
import { useAppSelector } from "../../../../../../store/hooks";



interface Props {
    title: string;
  }
  
  const BlendSum: FC<Props> = ({ title }) => {
    const formValues = useAppSelector((state) => state.form.fields);
    return (
      <div className="form-sum1"> 
        <h2 className="form-title">{title}</h2>
        <SummaryField
          nameProp="Name" 
          selected={formValues.blendFieldName}
          border
        />
        <SummaryField
          nameProp="Street 1" 
          selected={formValues.blendFieldStreet1}
          border
        />
        <SummaryField
          nameProp="Street 2" 
          selected={formValues.blendFieldStreet2}
          border
        />
        <SummaryField
          nameProp="Post Code" 
          selected={formValues.blendFieldPost}
          border
        />
        <SummaryField
          nameProp="City" 
          selected={formValues.blendFieldCity}
          border
        />
        <SummaryField
          nameProp="Country" 
          selected={formValues.blendFieldCountry}
        />
      </div>
    );
  };

export default BlendSum;