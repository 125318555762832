import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { fetchRegistration } from '../../../API/login';
import { Input } from '../../components/Form/Input';
import { BaseButtonSubmit } from '../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { CheckBox } from '../../components/Form/CheckBox/CheckBox';

import { STATEMENTS } from '../../../structure/routes/routes';
import { Phone, PhoneValue } from '../../components/Form/Phone';
import { basedUserFieldsValidator } from './helpers/validator';
import { getFieldsErrors } from '../../../helpers/getFieldsErrors';

import './InviteRegistrationStyle.scss';
import { flattenObject } from '../Registration';
import { useNotification } from '../../../hooks/useNotification';
import { Password } from '../../components/Form/Password';

const required = true;

interface InviteRegistrationProps {
    token: string;
}

export const InviteRegistration: React.FC<InviteRegistrationProps> = React.memo(
    ({token}) => {
        const toast = useNotification();
        const dispatch = useAppDispatch();
        const navigate = useNavigate();

        const [, setErrors] = useState<string[]>([]);
        const [errorTexts, setErrorTexts] = useState<{ [key: string]: string }>({});
        const [isSendingAttempt, setSendingAttempt] = useState(false);
        const [form, setForm] = useState({
            name: '',
            surname: '',
            phone_code: '',
            phone_number: '',
            position: '',
            password: '',
            confirmPassword: '',
            agreement: false,
        });

        const accountValidator = basedUserFieldsValidator(form);

        const onDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setForm({...form, [e.target.name]: e.target.value});
        };

        const onPhoneChange = ({code, number}: PhoneValue) => {
            setForm({
                ...form,
                phone_code: code ? code : form.phone_code,
                phone_number: number ? number : form.phone_number,
            });
        };

        const onAgreementChange = () => {
            setForm({...form, agreement: !form.agreement});
        };

        const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();

            setSendingAttempt(true);

            const {keys} = getFieldsErrors(accountValidator, form);

            if (!keys.length) {
                const {name, surname, position, password, phone_code, phone_number} = form;
                const data = {
                    name,
                    surname,
                    position,
                    userPhone: {
                        code: phone_code,
                        number: phone_number,
                    },
                    password,
                    token,
                };

                const formData = new FormData();
                const flattenData = flattenObject(data);

                Object.keys(flattenData).forEach((key) => {
                    formData.set(key, (flattenData as any)[key]);
                });

                setErrorTexts({});

                dispatch(fetchRegistration(formData)).then(() => {
                    toast.success(
                        { message: 'Success', description: 'You will be able to log in after the system admin has approved your account' },
                    );
                    navigate(STATEMENTS);
                }).catch(e => {
                    const message = e?.response?.data?.message;
                    toast.error({ message: 'Error', description: <span>{Array.isArray(message) ? <ul style={{margin: 0, padding: 0}}>{message.map((v) => <li>{v}</li>)}</ul> : message || 'Unknown error'}</span> });      
                });
            }
        };

        useEffect(() => {
            if (isSendingAttempt) {
                const {keys, errorTexts} = getFieldsErrors(accountValidator, form);

                setErrors(keys);
                setErrorTexts(errorTexts);
            }
        }, [form, isSendingAttempt]);

        return (
            <form className="invite-registration" autoComplete="off" onSubmit={onSubmit}>
                <input autoComplete="false" name="hidden" style={{ display: 'none' }} type="text" />

                <div className="form_block">
                    <Row>
                        <Col md={6}>
                            <Input
                                label="Name"
                                placeholder="Name"
                                name="name"
                                type="text"
                                required={required}
                                error={errorTexts['name']}
                                onChange={onDataChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label="Surname"
                                placeholder="Surname"
                                name="surname"
                                type="text"
                                required={required}
                                error={errorTexts['surname']}
                                onChange={onDataChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Phone
                                name="phone"
                                required={required}
                                error={errorTexts['phone_code'] || errorTexts['phone_number']}
                                onChange={onPhoneChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label="Position"
                                placeholder="Position"
                                name="position"
                                type="text"
                                required={required}
                                error={errorTexts['position']}
                                onChange={onDataChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Password
                                autoComplete="off"
                                label="Password"
                                placeholder="Password"
                                name="password"
                                required={required}
                                error={errorTexts['password']}
                                onChange={onDataChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Password
                                autoComplete="off"
                                label="Confirm password"
                                placeholder="Confirm password"
                                name="confirmPassword"
                                required={required}
                                error={errorTexts['confirmPassword']}
                                onChange={onDataChange}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="form_block">
                    <CheckBox
                        name="agreement"
                        error={errorTexts['agreement']}
                        onClick={onAgreementChange}>
            <span>
              I’m aware of, fully understand and commit to follow the&nbsp;
                <a
                    className="c-terms-link"
                    rel="noreferrer nofollow noopener"
                    target="_blank"
                    href="https://rsb.org/wp-content/uploads/2023/10/RSB-BC-Registry-Terms-and-Conditions.pdf">
                T&Cs
              </a>
            </span>
                    </CheckBox>
                </div>

                <BaseButtonSubmit active>Submit</BaseButtonSubmit>
            </form>
        );
    },
);
