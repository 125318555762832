import React, { useEffect } from 'react';
import { ChangePasswordForm } from './ChangePasswordForm';
import { fetchChangePassword } from '../../API/login';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/reducers/user/userReducer';
import { STATEMENTS } from '../../structure/routes/routes';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../hooks/useNotification';

export const ChangePassword: React.FC = () => {
  const toast = useNotification();
  const accessToken = useAppSelector(selectToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      toast.error({ message: 'Error', description: 'Token is required' });
      navigate(STATEMENTS);
    }
  }, [accessToken]);

  return <ChangePasswordForm fetchData={fetchChangePassword} />;
};
