import React from 'react';
import { Input, InputProps } from './Input';

interface Props extends Omit<InputProps, 'type' | 'someAfter'> {}

export const DatePicker: React.FC<Props> = ({ onBlur, onFocus, ...props }) => {
  return (
    <Input
      isDateField
      type="date"
      // someAfter={
      //   <span className="material-icons-outlined">calendar_month</span>
      // }
      {...props}
    />
  );
};
