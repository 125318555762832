import React from 'react';
import { TABS } from './models/tabs.constant';
import { PageWithTabsLayout } from '../layouts/PageWithTabsLayout';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';

export const Profiles: React.FC = () => {
  const user = useAppSelector(selectUser);

  if (!user) return null;

  return <PageWithTabsLayout tabs={TABS} />;
};
