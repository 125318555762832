import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { ScopeInfo } from '../../../../components/UserBalance/Tabs/ScopeInfo';
import { selectAdminCredit } from '../../../../../store/reducers/admin/bcuListReducer';
import { fetchAdminBCUCredit } from '../../../../../API/admin/bcu-list';
import { setSelectedRow } from '../../../../../store/reducers/admin/adminReducer';
import { useParams } from 'react-router-dom';

export const Scope3: React.FC = () => {
    const dispatch = useAppDispatch();
    const currentBCU = useAppSelector(selectAdminCredit);
    const { creditId } = useParams();

  useEffect(() => {
    dispatch(fetchAdminBCUCredit(creditId!));
  }, [dispatch, creditId]);

  useEffect(() => {
    dispatch(setSelectedRow(currentBCU));
  }, [dispatch, currentBCU]);

  return (
    <>{currentBCU && <ScopeInfo scope="scope3" credit={currentBCU} />}</>
  );
};
