import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  fetchBaselines,
  fetchCredits,
} from '../../../../API/credits';
import { selectUser } from '../../../../store/reducers/user/userReducer';

import './BCURegistrationStyle.scss';
import { fetchCompanyData } from '../../../../API/user';
import { RegistrationForm } from '../../../components/RegistarationForm/RegistrationForm';

export const BCURegistration: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (!user) return;
    dispatch(fetchCredits());
    dispatch(fetchBaselines());
    dispatch(fetchCompanyData());
  }, []);

  return (
    <div className="bcu-registration">
      <RegistrationForm company={user!.company} />
    </div>
  );
};
