import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import { useAppDispatch } from '../../../../store/hooks';
import { Col } from 'react-bootstrap';
import { Search } from '../../../components/Table/Search/Search';
import { DownloadFileButton } from '../../../components/Buttons/DownloadFileButton/DownloadFileButton';
import { EditablePaginatedTable } from '../../../components/Table/Table';
import React, { useState } from 'react';
import {
  downloadAdminBCUs,
  fetchAdminBCUs,
  fetchAdminEditBCU,
} from '../../../../API/admin/bcu-list';
import {
  selectBCUsList,
  selectBCUsListStatus,
} from '../../../../store/reducers/admin/bcuListReducer';
import { BCU_LIST_COLUMNS_LIST } from './models/bcu-list-columns.constant';
import { hasChanges, transformData, validate } from './models/validate';
import { setSelectedRow } from '../../../../store/reducers/admin/adminReducer';
import { useNavigate } from 'react-router-dom';
import {
  DateQueryTypes,
  DateSelect,
} from '../../../components/Table/DateSelect';
import { Select, Option } from '../../../components/Form/Select/Select';
import { STATUSES } from './models/options.constant';
import { SingleValue } from 'react-select';
import { useNotification } from '../../../../hooks/useNotification';
import { Prisma } from '@prisma/client';

export type CreditListItem = Prisma.CreditGetPayload<{ include: { company: true; posFiles: { include: { attachment: true }; supplierFiles: { include: { attachment: true }}}} }>;

export const BCUList: React.FC = () => {
  const toast = useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [status, setStatus] = useSearchParamsState('status', null);
  const [startDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    '',
  );
  const [endDate, setEndDate] = useSearchParamsState<string>('endDate', '');
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );
  const [sortBy] = useSearchParamsState<string | null>(
      'sortBy',
      null,
  );
  const [sortOrder] = useSearchParamsState<string | null>(
      'sortOrder',
      null,
  );
  const [statusLabel, setStatusLabel] = useState(null);

  const handleTransformAndDispatchData = async (formData: any) => {
    try {
      await dispatch(fetchAdminEditBCU(transformData(formData)));
      toast.success({ message: 'Success', description: 'BCU has been updated' });
    } catch (error) {
      toast.error({ message: 'Error', description: 'BCU has not been updated' });
    }
  };

  const onRowClick = (item: CreditListItem) => {
    dispatch(setSelectedRow(item));
    navigate(item.id.toString());
  };

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onChangeStatus = (options: SingleValue<Option>) => {
    setStatusLabel(options?.value);
    setStatus(options?.value);
  };

  const onClear = () => setSearch(null);

  const params: any = {
    status: status || undefined,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    search: search || undefined,
    sortBy: sortBy || undefined,
    sortOrder: sortOrder || undefined,
  };

  return (
    <div className="users">
      <div className="users__header">
        <h1 className="users__header_text">BCU List</h1>
        <div className="controls">
          <DownloadFileButton fetchDownload={() => downloadAdminBCUs(params)} />
          <Select
            className="users__header_filter"
            placeholder="Statuses"
            options={STATUSES}
            isClearable={false}
            value={statusLabel}
            onChange={onChangeStatus}
          />
          <DateSelect onDateChange={onDateChange} />
          <Col className="search">
            <Search
              isClearable={Boolean(search)}
              className="search__input"
              defaultValue={search ? search : ''}
              onClear={onClear}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </div>
      </div>
      <EditablePaginatedTable
        className="users__table"
        fetchData={fetchAdminBCUs}
        getColumns={BCU_LIST_COLUMNS_LIST}
        selectData={selectBCUsList}
        selectStatus={selectBCUsListStatus}
        validate={validate}
        transformAndDispatchData={handleTransformAndDispatchData}
        filters={{
          search,
          status,
          startDate,
          endDate,
        }}
        variant="bcuList"
        hasChanges={hasChanges}
        onRowClick={onRowClick}
        isPopup={false}
      />
    </div>
  );
};
