import { Big as BBig } from "big.js";


export class Big extends BBig {
    constructor(value: string | number) {
        try {
            super(value);
        } catch (e) {
            console.warn(e);
            super(0);
        }
    }
}