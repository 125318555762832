import { FC } from 'react';
import './batchStyles.scss';
import { useAppSelector } from '../../../../../../store/hooks';
import SummaryField from '../../SummaryField';
import { BatchPreviewData } from '../../../../BCUData/BCUData';

interface IBatchSumProps {
  title: string;
}

const BatchSum: FC<IBatchSumProps> = ({ title }) => {
  const batchSummary = useAppSelector(state => state.form.batchForm);
  const formValues = useAppSelector(state => state.form.fields);

  const modifiedBatchSummary = batchSummary.map(batch => {
    return {
      ...batch,
      fieldBatchCountry: formValues.fieldBatchCountry,
    };
  });

  if (batchSummary.length === 1) {
    return (
      <div className="batch-form--sum1">
        <h2 className="batch-form--title">{title}</h2>
        <SummaryField
          nameProp="Batch ID end product"
          selected={batchSummary[0].fieldID}
          border
        />
        <SummaryField
          nameProp="Neat amount in tonnes"
          selected={batchSummary[0].fieldTonnes}
          border
        />
        <SummaryField
          nameProp="Neat amount in MJ"
          selected={batchSummary[0].fieldMJ}
          border
        />
        <SummaryField
          nameProp="Year of neat product production"
          selected={batchSummary[0].dateProd}
          border
        />
        <SummaryField
          nameProp="Date of blending"
          selected={batchSummary[0].dateBlend}
          border
        />
        <SummaryField
          nameProp={
            <span>
              LCA GHG value (gCO<sub>2</sub>eq/MJ)
            </span>
          }
          selected={batchSummary[0].fieldCO2}
          border
        />
        <SummaryField
          nameProp="GHG Emission Reduction (%)"
          selected={batchSummary[0].fieldGHG}
          border
        />
        <SummaryField
          nameProp="Country of physical end product delivery"
          selected={batchSummary[0].fieldBatchCountryOf}
          border
        />
        <SummaryField
          nameProp="Airport of physical end product delivery"
          selected={batchSummary[0].fieldSaf}
          border
        />
        <SummaryField
          nameProp="Additional claim as allowed under the RSB certification system"
          selected={batchSummary[0].fieldRsb}
          border
        />
        <SummaryField
          nameProp="Declaration of incentives and tax credits"
          selected={batchSummary[0].fieldDec}
        />
      </div>
    );
  }

  return (
    <div className="batch-form--sum1">
      <h2 className="batch-form--title">{title}</h2>
      <div style={{ padding: '10px 0' }}>
        <BatchPreviewData batches={modifiedBatchSummary} />
      </div>
    </div>
  );
};

export default BatchSum;
