import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import './AdminUsersInfoStyle.scss';
import InfoData from '../../../../components/InfoData/InfoData';
import { getUserKeys } from '../../Users/Tabs/UserDetailsInfo';

export const AdminUserProfile: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);

  const [user, keys] = React.useMemo(() => {
    if (!details) return [null, null];

    if (!details.userId) return [null, null];
  
    const user = (details?.company?.users || []).find((u: any) => u.id === details.userId);

    if (!user) return [null, null];

    return [{
        ...user,
        company: details?.company,
    }, getUserKeys(user)];
  }, [details]);

  if (!user || !keys) return null;

  return (
    <div style={{ margin: '20px 0 0' }}>
        <InfoData data={user} keys={keys} editable={false} />
    </div>
  );
};
