import { Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CONTACT_US, STATEMENTS } from '../../structure/routes/routes';
import Waiting from '../../assets/waiting.png';

import './EmailVerificationStyle.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { fetchConfirmEmail } from '../../API/login';
import { AxiosResponse } from 'axios';
import { ServerResponse } from '../../API/types';
import { useNotification } from '../../hooks/useNotification';

export const EmailVerification = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  const [email, setEmail] = useState<string>();

  useEffect(() => {
    if (!token) {
      toast.error({ message: 'Error', description: 'Token is required.' });
      navigate(STATEMENTS);

      return;
    }

    dispatch(fetchConfirmEmail(token))
      .then((resp: AxiosResponse<ServerResponse<string>>) =>
        setEmail(resp.data.data),
      )
      .catch(e => {
        toast.error(e.response.data.message);

        navigate(STATEMENTS);
      });
  }, []);

  return (
    <div className="email-verification">
      <Row className="email-verification__text">
        <h1>Thank you for confirming your email</h1>
      </Row>
      <Row className="email-verification__text">
        <img alt="Waiting" src={Waiting} />
      </Row>
      <Row className="email-verification__text">
        Your application is currently being reviewed by the administrator.
      </Row>
      <Row className="email-verification__text">
        We’ll send you a notification to your email address:
      </Row>
      <Row className="email-verification__text email">{email}</Row>
      <Row className="email-verification__text">
        Didn’t receive the email? Check your Spam folder, it may have been
        caught by a filter.
      </Row>
      <Row className="email-verification__text">
        If you still don’t see it, you can
        <Link to={CONTACT_US}> contact us.</Link>
      </Row>
    </div>
  );
};
