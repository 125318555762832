import { FC, useState, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import { UserStatus } from '@prisma/client';

import NextButton from '../Buttons/NextButton/NextButton';
import { Phone, PhoneValue } from '../Form/Phone';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';

import './TableListStyle.scss';

export type TableListValue = string | null | PhoneValue;
type TableData = {
  key: string;
  label: string;
  value: TableListValue;
  isEdit?: boolean;
};

interface ITableListProps {
  data: TableData[];
  validator?: any;
  onSave?: (value: { [key: string]: TableListValue }) => void;
}

const TableList: FC<ITableListProps> = ({ data, validator, onSave }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState<TableData[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const user = useAppSelector(selectUser);
  const isUserSuspended = user && user.status === UserStatus.Suspended;

  const isDataEdit = data.some(({ isEdit }) => isEdit);

  const onEditStart = () => setEditMode(true);

  const onEdiCancel = () => {
    setEditedData(data);
    setEditMode(false);
  };

  const onDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const editedValue = editedData.map(item =>
      item.key === e.currentTarget.name
        ? { ...item, value: e.currentTarget.value }
        : item,
    );
    setEditedData(editedValue);
  };

  const onPhoneChange = (value: PhoneValue) => {
    setEditedData(
      editedData.map(item =>
        item.key === 'cellPhone' ? { ...item, value } : item,
      ),
    );
  };

  const getValueByKey = (key: string) =>
    editedData.find(item => item.key === key)?.value || '';

  const getCurrentValue = (key: string, value: TableListValue) => {
    return typeof value !== 'string' && key === 'cellPhone'
      ? `${value?.code}${value?.number}`
      : value || '';
  };

  const onDataSave = () => {
    const editedFields = editedData.filter(({ isEdit }) => isEdit);
    const validatedDate = editedFields.filter(
      ({ key, value }) => !validator[key](value),
    );
    const errorKeys = validatedDate.map(({ key }) => key);

    if (!validatedDate.length && onSave) {
      if (errors.length) setErrors([]);

      const data = {
        firstName: getValueByKey('firstName') as string,
        lastName: getValueByKey('lastName') as string,
        email: getValueByKey('email') as string,
        position: getValueByKey('position') as string,
        cellPhone: getValueByKey('cellPhone') as PhoneValue,
      };
      setEditMode(false);
      onSave(data);
    } else {
      setErrors(errorKeys);
    }
  };

  useEffect(() => {
    setEditedData(data.filter(({ value }) => value));
  }, [data]);

  return (
    <div className="table-list">
      {editedData.map(({ key, label, value, isEdit = false }, index) => (
        <div
          key={key}
          className={classNames('table-list__row', {
            'table-list__underlight': !(index % 2),
          })}>
          <span>{`${label}:`}</span>
          {isEdit && isEditMode ? (
            <div
              className={classNames('table-list__input', {
                error: errors.includes(key),
              })}>
              {key === 'cellPhone' ? (
                <div className="table-list__phone">
                  <Phone
                    value={value as PhoneValue}
                    isLabel={false}
                    onChange={onPhoneChange}
                  />
                </div>
              ) : (
                <input
                  name={key}
                  value={`${getCurrentValue(key, value)}`}
                  onChange={onDataChange}
                />
              )}
              {errors.includes(key) && (
                <span>{`${label} should be correct`}</span>
              )}
            </div>
          ) : (
            <span>{`${getCurrentValue(key, value)}`}</span>
          )}
        </div>
      ))}
      {isDataEdit && (
        <div className="table-list__buttons">
          {!isEditMode && (
            <NextButton
              handleClick={onEditStart}
              disabled={isUserSuspended}
              text="Edit"
              style={{
                width: '73px',
              }}
            />
          )}
          {isEditMode && (
            <NextButton
              handleClick={onEdiCancel}
              text="Cancel"
              styleText={{ color: '#07ACA6' }}
              style={{
                width: '73px',
                backgroundColor: '#FFFFFF',
                border: '1px solid #07ACA6',
              }}
            />
          )}
          {isEditMode && (
            <NextButton handleClick={onDataSave} text="Save changes" />
          )}
        </div>
      )}
    </div>
  );
};

export default TableList;
