import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import TableList from '../../components/TableList';

import './ProfileInfo.scss';
import { PhoneValue } from '../../components/Form/Phone';
import { CODES } from '../../components/Form/models/codes.constant';
import { phoneRegexp } from '../../../constants';
import { editUserData, fetchUserData } from '../../../API/user';
import { UserType } from '../../../structure/models/user/user';
import { diff } from 'deep-object-diff';
import { useNotification } from '../../../hooks/useNotification';

export const ProfileInfo: React.FC = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  if (!user) return null;

  const isPrimaryUser = user.type === UserType.Primary;

  const { firstName, lastName, position, email, cellPhone } = user;
  const rows = [
    {
      key: 'firstName',
      label: 'Name',
      value: firstName,
      isEdit: isPrimaryUser,
    },
    {
      key: 'lastName',
      label: 'Surname',
      value: lastName,
      isEdit: isPrimaryUser,
    },
    {
      key: 'position',
      label: 'Position',
      value: position,
      isEdit: true,
    },
    {
      key: 'email',
      label: 'Email',
      value: email,
      isEdit: isPrimaryUser,
    },
    {
      key: 'cellPhone',
      label: 'Cell phone',
      value: {
        code: (cellPhone as any)?.code || '',
        number: (cellPhone as any)?.number || '',
      },
      isEdit: true,
    },
  ];
  const validator = {
    firstName: (value: string) => value.length,
    lastName: (value: string) => value.length,
    position: (value: string) => value.length,
    email: (value: string) => value.length,
    cellPhone: ({ code, number }: PhoneValue) =>
      CODES.includes(code || '') || phoneRegexp.test(number || ''),
  };

  const onSave = (data: any) => {
    const filteredData: any = diff({ firstName, lastName, position, email, cellPhone }, data);

    // No changes
    if (Object.keys(filteredData).length === 0) {
      return;
    }

    if (filteredData.cellPhone) {
      if (filteredData.cellPhone?.code && !filteredData.cellPhone?.number) {
        filteredData.cellPhone.number = data.cellPhone.number;
      }
      if (filteredData.cellPhone?.number && !filteredData.cellPhone?.code) {
        filteredData.cellPhone.code = data.cellPhone.code;
      }
    }

    dispatch(editUserData(filteredData)).then(() => {
      if (user.type === UserType.Primary) {
        toast.success(
            { message: 'Success', description: 'Profile change request has been created. Data will be updated after admin approval' }
        );
      } else {
        toast.success({ message: 'Success', description: 'Profile has been updated' });
      }
    }).catch(({ response }) => {
      const messages: string[] = response?.data?.message || [];
      toast.error({ message: 'Error', description: 'Profile has not been updated' });

      messages.forEach((message: string) => {
        toast.error({ message: 'Error', description: message });
      });
    });
  };

  return (
    <div className="profile-info">
      <TableList data={rows} validator={validator} onSave={onSave} />
    </div>
  );
};
