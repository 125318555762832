import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetCreditsQuery } from '../../../../API';
import DataTable, { DataKeyType } from '../../DataTable/DataTable';

const getScope1InfoKeys = (data: any): { fields: DataKeyType[]; label?: string }[] => {
  return [
      {
          fields: [
              {
                  label: 'Scope 1 transportation service provider',
                  value: v => v?.scope1Company?.name,
              },
              {
                  label: 'Country where BCU will be claimed',
                  value: v => v?.creditClaimCountry,
              },
              {
                  label: 'Reporting scheme',
                  value: v => v?.reporting,
              }
          ],
      },
  ];
};

const getScope3InfoKeys = (data: any): { fields: DataKeyType[]; label?: string }[] => {
  return [
      {
          fields: [
              {
                  label: 'Scope 3 logistics service provider',
                  value: v => v?.scope3LogisticCompany?.name,
              },
              {
                  label: 'Scope 3 general',
                  value: v => v?.scope3Company?.name,
              },
              {
                  label: 'Scope 3 general anonymous',
                  value: v => typeof v?.scope3Anonymity !== 'undefined' ? (v?.scope3Anonymity ? 'yes' : 'no') : '',
              }
          ],
      },
  ];
};

export const useGroups = (credit: any, scope: 'scope1' | 'scope3') => {
  const groups = useMemo(() => (scope === 'scope1' ? getScope1InfoKeys : getScope3InfoKeys)(credit).map(keys => {
    const processedFields = (keys.fields || []).map(field => ({
      ...field,
      options: field.options && field.options(credit),
      value: field.value(credit),
      ...(field.render ? { render: field.render(credit) } : {}),
    }));

    return {
        ...keys,
        processedFields,
    };
  }), [credit, scope]);

  return groups;
};

export const ScopeInfo: FC<{ scope?: 'scope1' | 'scope3', credit?: any }> = ({ scope, credit }) => {
  const {creditId} = useParams();
  const [getCredits] = useLazyGetCreditsQuery();

  const [innerCredit, setInnerCredit] = useState<any>(credit);

  useEffect(() => {
    if (!credit && !innerCredit && creditId) {
      getCredits().then(({ data }) => {
        // eslint-disable-next-line eqeqeq
        const c = (data || []).find((c: any) => c.id == creditId);
        setInnerCredit(c);
      });
    }
  }, [credit, innerCredit, creditId, getCredits]);

  const groups = useGroups(innerCredit, scope ?? 'scope1');

  return (
    <div style={{ margin: '20px 0 30px' }}>
      <div className="dashboard-bcu-info__table">
        <DataTable data={innerCredit} edit={false} columns={groups} />
      </div>
    </div>
  );
};
