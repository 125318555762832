import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../store/hooks';
import { fetchBaselinesCreate } from '../../../../../API/credits';
import { MultiValue } from 'react-select';
import NextButton from '../../../../components/Buttons/NextButton/NextButton';
import { Input } from '../../../../components/Form/Input';
import '../ReferenceValues.scss';
import { useNotification } from '../../../../../hooks/useNotification';

type CertificationSchemesType = {
  name: string;
  baselineValue: string;
};

export const CreateCertificationSchemes: React.FC = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();

  const initData = {
    name: '',
    baselineValue: '',
  };

  const [isError, setError] = useState(false);
  const [data, setData] = useState<CertificationSchemesType>(initData);

  const errorText = 'Field is required';

  const onChange = (name: string, value: string | MultiValue<any>) => {
    setData({
      ...data,
      [name]: +value
        ? +value
        : Array.isArray(value)
        ? value.map(item => item.label)
        : value,
    });
    setError(false);
  };

  const onSave = () => {
    if (!data.name || !data.baselineValue) {
      setError(true);
      return;
    }

    return dispatch(fetchBaselinesCreate({
      ...data,
      fuelEmissionFactor: 0,
    }))
      .then(() => {
        toast.success({ message: 'Success', description: 'Reference Values has been created' });
      })
      .catch(() => {
        toast.error({ message: 'Error', description: 'Reference Values create failed' });
      })
      .finally(() => {
        setData(initData);
      });
  };

  return (
    <div className="certificationForm">
      <form>
        <div>
          <Input
            label="Scheme"
            placeholder="Scheme Name"
            type="text"
            name="scheme"
            value={data.name}
            error={isError && !data.name ? errorText : ''}
            onChange={e => onChange('name', e.target.value)}
          />
          <Input
            label="Baseline Value"
            placeholder="Baseline Value"
            type="number"
            name="baselineValue"
            value={data.baselineValue}
            error={isError && !data.baselineValue ? errorText : ''}
            onChange={e => onChange('baselineValue', e.target.value)}
          />
        </div>
      </form>
      <div className="certificationForm__button">
        <NextButton disabled={isError} handleClick={onSave} text="Save" />
      </div>
    </div>
  );
};
