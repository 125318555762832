import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './RegistrationSelectStyles.scss';
import classNames from 'classnames';

interface Option {
  value: string;
  label: string;
}

interface IRegistrationSelectProps {
  id: string;
  name: string;
  label: string;
  label2?: string;
  isClearable?: boolean;
  placeholder?: string;
  options: Option[];
  value: string;
  touched?: boolean;
  error?: string | boolean | undefined;
  onChange: (value: string, label: string) => void;
  required?: boolean;
  errorVisible?: boolean;
  isErrorText?: boolean;
}

const RegistrationSelect: FC<IRegistrationSelectProps> = ({
  id,
  name,
  label,
  label2,
  placeholder = '',
  options,
  value,
  touched,
  isClearable = true,
  error,
  errorVisible,
  onChange,
  required,
  isErrorText = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [localValue, setLocalValue] = useState('');
  const [isOptionsShow, setOptionsShow] = useState(false);

  const onOptionsClose = useCallback(() => {
    setOptionsShow(false);
  }, []);

  const onOptionsOpen = () => {
    setOptionsShow(true);
  };

  const handleChange = (value: string, label: string) => {
    setLocalValue(label);
    onOptionsClose();

    onChange(value, label);
  };

  const onClearValue = () => {
    setLocalValue('');
    onChange('', '');
  };

  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOptionsClose();
      }
    };
    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [onOptionsClose]);

  return (
    <div className="registration-dropdown__container">
      {label2 && (
        <label className="registration-dropdown__label2">{label2}</label>
      )}
      {label && (
        <label className="registration-dropdown__label" htmlFor={id}>
          {label}
          {required && (
            <span
              className="registration-dropdown__required"
              style={{ color: 'red' }}>
              *
            </span>
          )}
        </label>
      )}
      <div className="registration-dropdown__wrapper" ref={ref}>
        {isClearable && (value || localValue) && (
          <span
            className={classNames(
              'material-icons-outlined',
              'registration-dropdown__close-icon',
            )}
            onClick={onClearValue}>
            close
          </span>
        )}
        {error && errorVisible && (
          <span
            className={classNames(
              'material-icons-outlined',
              'registration-dropdown__error-icon',
            )}>
            error
          </span>
        )}
        <span
          className={classNames(
            'material-icons-outlined',
            'registration-dropdown__arrow-icon',
            { active: isOptionsShow },
          )}>
          arrow_drop_down
        </span>
        <input
          className={classNames('registration-dropdown__select', {
            error: error && errorVisible,
          })}
          placeholder={placeholder}
          id={id}
          value={value || localValue}
          autoComplete="off"
          onClick={onOptionsOpen}
        />
        {isOptionsShow && (
          <div className="registration-dropdown__options">
            {options.map(option => (
              <span
                key={option.value}
                className={classNames('registration-dropdown__option', {
                  active: option.label === localValue,
                })}
                onClick={() => handleChange(option.value, option.label)}>
                {option.label}
              </span>
            ))}
          </div>
        )}
      </div>
      {error && errorVisible && isErrorText && (
        <span className="registration-dropdown__error">{error}</span>
      )}
    </div>
  );
};

export default RegistrationSelect;
