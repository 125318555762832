const convertToNumber = (str: string): number => {
  const trimmedStr: string = str.trim().replaceAll(',', '');
  const number: number = Number(trimmedStr);
  if (Number.isNaN(number)) {
    throw new Error(`Invalid number: ${trimmedStr}`);
  }

  return number;
};

export default convertToNumber;
