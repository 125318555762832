import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  fetchAdminBCUCredit,
} from '../../../../../API/admin/bcu-list';
import { useParams } from 'react-router-dom';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import BCUData, { getBCUInfoKeys } from "../../../../components/BCUData/BCUData";
import { fetchIncomingCredits } from '../../../../../API/credits';
import { selectIncomingCredits } from '../../../../../store/reducers/user/creditsReducer';


export const BCUInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const user = useAppSelector(selectUser);
  const data = useAppSelector(selectIncomingCredits);

  // eslint-disable-next-line eqeqeq
  const currentBCU = useMemo(() => {
    if (Array.isArray(data) && id) {
      const transaction = data.find((i) => i.id.toString() === id);
      
      if (transaction) {
        return transaction.newCredit || transaction.credit;
      }
      
      return null;
    }
    return null;
  }, [data, id]);

  useEffect(() => {
    dispatch(fetchIncomingCredits());
  }, []);

  return (
    <div className="admin-bcu-info__table">
      {currentBCU && (
          <BCUData
              editable={false}
              user={user}
              data={currentBCU}
              keysFn={getBCUInfoKeys}
          />
      )}
    </div>
  );
};
