import React, { useEffect, useState } from 'react';
import DefaultTable from '../../../components/DefaultTable';
import { OnFilterChange } from '../../../components/DefaultTable/TableFilters';
import classNames from 'classnames';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../core/models/contstants/format-date';
import {
  Transaction,
  selectIncomingCredits,
} from '../../../../store/reducers/user/creditsReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  fetchCredits,
  fetchIncomingCreditApprove,
  fetchIncomingCreditReject,
  fetchIncomingCredits,
} from '../../../../API/credits';
import './IncomingTransactions.scss';
import { ConfirmationPrompt } from '../../../components/ConfirmationPrompt';
import { useNavigate } from 'react-router-dom';
import { CreditInfoRequestStatus } from '../../../../structure/models/credits/credits';
import { fetchCompanyData } from '../../../../API/user';
import { TransactionStatus } from '@prisma/client';
import { TRANSFER_ROUTES } from '../../../../structure/routes/routes';

type Action = (id: string) => void;
enum ActionType {
  approve = 'approve',
  reject = 'reject',
}

const mapItemStatusToComponent = (status: string) => {
  switch (status) {
    case TransactionStatus.Approved:
      return 'Approved';
    case TransactionStatus.Pending:
      return 'For consideration';
    case TransactionStatus.Denied:
      return 'Rejected';
  }
};

export const getColumns = (onApproveClick: Action, onRejectClick: Action) => {
  const onAction = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: ActionType,
    id: string,
  ) => {
    e.stopPropagation();

    if (action === ActionType.approve) {
      onApproveClick(id);
      return;
    }
    onRejectClick(id);
  };

  return [
    {
      title: 'Date and time',
      sort: true,
      keyItem: 'approveDate',
      render: (item: any) =>
        formatDate(item?.approveDate, DATE_WITH_TIME_FORMAT),
    },
    {
      title: 'From/by',
      keyItem: 'fromBy',
      render: (item: any) => item.company.name,
    },
    {
      title: 'BCU ID',
      keyItem: 'BCUID',
      render: (item: any) => item.newCredit?.BCUID || item.credit?.BCUID,
    },
    {
      title: 'User',
      keyItem: 'user',
      render: (item: any) => `${item.sender.firstName} ${item.sender.lastName}`,
    },
    {
      title: 'Amount',
      keyItem: 'amount',
      render: (item: any) => (
        <div style={{ textAlign: 'right' }}>
          {item.amount.toLocaleString('en-US', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}{' '}
          BCU
        </div>
      ),
    },
    {
      filters: [
        { title: 'Approved', value: TransactionStatus.Approved },
        {
          title: 'For consideration',
          value: TransactionStatus.Pending,
        },
        { title: 'Rejected', value: TransactionStatus.Denied },
      ],
      filterTitle: 'All statuses',
      title: 'Status',
      render: (item: any) => (
        <div
          className={classNames('status-account--wrapper', {
            pending: item.status === TransactionStatus.Pending,
            approved: item.status === TransactionStatus.Approved,
            denied: item.status === TransactionStatus.Denied,
          })}>
          <span className={classNames('status-account--text')}>
            {mapItemStatusToComponent(item.status)}
          </span>
        </div>
      ),
    },
    {
      title: 'Actions',
      keyItem: 'actions',
      render: (item: any) =>
        item.status === CreditInfoRequestStatus.Pending ? (
          <div className="c-incoming-transactions__actions">
            <button onClick={e => onAction(e, ActionType.approve, item.id)}>
              Approve
            </button>
            <button onClick={e => onAction(e, ActionType.reject, item.id)}>
              Reject
            </button>
          </div>
        ) : (
          ''
        ),
    },
  ];
};

export const IncomingTransactionsPage: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectIncomingCredits);

  const [items, setItems] = useState(data || []);
  const [context, setContext] = useState<ActionType | null>(null);
  const [selectedId, setSelectedId] = useState('');

  const onConfirmationClose = (status: boolean) => {
    if (status) {
      if (context === ActionType.approve)
        dispatch(fetchIncomingCreditApprove(selectedId));
      if (context === ActionType.reject)
        dispatch(fetchIncomingCreditReject(selectedId));
    }
    setSelectedId('');
    setContext(null);
    dispatch(fetchCompanyData());
  };

  const onRowClicked = async (
    item: any,
    index?: number,
    columnIndex?: number,
  ) => {
    if (!columnIndex) {
      return; // prevent this event from firing twice (on row and on cell click)
    }
    navigate(TRANSFER_ROUTES.INCOMING_TRANSACTION.replace(':id', item.id));
  };

  const onDateSort = (data: Transaction[]) => {
    return [...data].sort(
      (curr, next) =>
        new Date(next.approveDate || new Date()).getTime() -
        new Date(curr.approveDate || new Date()).getTime(),
    );
  };

  const onSearch = (query: string) => {
    if (query === '') {
      return setItems(onDateSort(data));
    }

    const sortedData = onDateSort(data);
    setItems(
      (sortedData || []).filter((item, index) => {
        return `${item.credit.BCUID} ${item.sender.firstName} ${item.sender.lastName}`
          .toLowerCase()
          .includes(query);
      }),
    );
  };

  const onFilter = ({ values }: OnFilterChange) => {
    const sortedData = onDateSort(data);
    if (!values.length) return setItems(sortedData);
    setItems(sortedData.filter(({ status }) => values.includes(status)));
  };

  const onApproveClick = (id: string) => {
    setContext(ActionType.approve);
    setSelectedId(id);
  };
  const onRejectClick = (id: string) => {
    setContext(ActionType.reject);
    setSelectedId(id);
  };

  useEffect(() => {
    const sortedData = onDateSort(data);
    setItems(sortedData || []);
  }, [data]);

  useEffect(() => {
    dispatch(fetchIncomingCredits());
    dispatch(fetchCredits());
  }, []);

  const columns = getColumns(onApproveClick, onRejectClick);

  return (
    <>
      <DefaultTable<any>
        items={items || []}
        showBottomFilters={false}
        headers={columns}
        onSearch={onSearch}
        onFilter={onFilter}
        onRowClicked={onRowClicked}
      />
      <ConfirmationPrompt
        onClosed={onConfirmationClose}
        show={Boolean(context)}>
        {context === ActionType.approve && (
          <p>Are you sure you want to approve?</p>
        )}
        {context === ActionType.reject && (
          <p>Are you sure you want to reject?</p>
        )}
      </ConfirmationPrompt>
    </>
  );
});
