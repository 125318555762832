import { SubmissionsRequest } from '../../structure/request/admin';
import { AppThunk, AppThunkDispatch } from '../../store/store';
import { actions } from '../fetchProvider/fetch';
import { URL_ADMIN_LOGS, URL_ADMIN_LOGS_DOWNLOAD } from '../urls/urls';
import {
  setAdminLogs,
  setAdminLogsStatus,
} from '../../store/reducers/admin/logsReducer';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

export const fetchAdminLogs =
  ({ page, limit, ...query }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setAdminLogsStatus('loading'));

    return actions
      .get(URL_ADMIN_LOGS, {
        queries: {
          skip: (page - 1) * limit,
          limit,
          ...query,
        },
      })
      .then((response: any) => {
        dispatch(setAdminLogsStatus('idle'));
        dispatch(setAdminLogs(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setAdminLogs('failed'));
        throw e;
      });
  };

export const downloadAdminLogs = ({ page, limit, ...query}: SubmissionsRequest): Promise<any> =>
  actions
    .get<AxiosResponse>(URL_ADMIN_LOGS_DOWNLOAD, {
        queries: {
            ...query,
        },
    })
    .then(response => {
      fileDownload(response.data, 'logs.csv');
    })
    .catch(e => {
      throw e;
    });
