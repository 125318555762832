import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface BatchFormFields {
  fieldID: string;
  dateBlend: string;
  dateProd: string;
  fieldTonnes: string;
  fieldMJ: string;
  fieldBatchCountry: string;
  fieldCO2: string;
  fieldGHG: string;
  fieldBatchCountryOf: string;
  fieldSaf: string;
  fieldRsb: string;
  fieldDec: string;
}

interface FormState {
  fields: {
    endProductType: string;
    sustainabilityCertification: string;
    rawMaterialFeedstock: string;
    sustainabilityCertificationNumber: string;
    lowerHeatingValue: string;
    conversionProcess: string;
    radioButton: string;
    blendRadioButton: string;
    fieldPoSNumber: string;
    fieldUpDateProd: string;
    fieldName: string;
    dateAcq: string;
    posIssueDate: string;
    dateEntry: string;
    fieldStreet1: string;
    fieldStreet2: string;
    fieldCountry: string;
    fieldCountryOf: string;
    fieldChain: string;
    fieldPoS: string;
    fieldUpDate: string;
    fieldPost: string;
    fieldCity: string;
    fieldCertif: string;
    sameAddress: string;
    blendFieldName: string;
    blendFieldStreet1: string;
    blendFieldStreet2: string;
    blendFieldCountry: string;
    blendFieldPost: string;
    blendFieldCity: string;
    fieldID: string;
    dateProd: string;
    dateBlend: string;
    fieldTonnes: string;
    fieldMJ: string;
    fieldBatchCountry: string;
    fieldCO2: string;
    fieldGHG: string;
    fieldBatchCountryOf: string;
    fieldSaf: string;
    fieldRsb: string;
    fieldDec: string;
    fieldBatchPoS: string;
    fieldBatchUpDate: string;
    dateSustainabilitySAF: string;
    fieldSustainabilityCO2: string;
    fieldSustainabilityLand: string;
    fieldSustainabilityLandfill: string;
    fieldSustainabilityRecycling: string;
    LCAGHGValue: string;
    GHGEmissionReduction: string;
    neatAmountInMJ: string;
    volume: string;
    abatementBaselineScheme: string;
  };
  batchForm: BatchFormFields[];
  fileData: string | null;
  isFileUploaded: boolean;
  fileDataTwoStep: string | null;
  isFileUploadedTwoStep: boolean;
  selfProduction: boolean;
  sameAddress: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FormState = {
  fields: {
    endProductType: '',
    sustainabilityCertification: '',
    sustainabilityCertificationNumber: '',
    lowerHeatingValue: '',
    rawMaterialFeedstock: '',
    conversionProcess: '',
    radioButton: '',
    blendRadioButton: '',
    fieldPoSNumber: '',
    fieldUpDateProd: '',
    fieldName: '',
    dateAcq: '',
    dateEntry: '',
    fieldStreet1: '',
    fieldStreet2: '',
    fieldCountry: '',
    fieldCountryOf: '',
    fieldCertif: '',
    fieldChain: '',
    fieldPoS: '',
    fieldUpDate: '',
    fieldPost: '',
    fieldCity: '',
    sameAddress: '',
    blendFieldName: '',
    blendFieldStreet1: '',
    blendFieldStreet2: '',
    blendFieldCountry: '',
    blendFieldPost: '',
    blendFieldCity: '',
    fieldID: '',
    dateProd: '',
    posIssueDate: '',
    dateBlend: '',
    fieldTonnes: '',
    fieldMJ: '',
    fieldBatchCountry: '',
    fieldCO2: '',
    fieldGHG: '',
    fieldBatchCountryOf: '',
    fieldSaf: '',
    fieldRsb: '',
    fieldDec: '',
    fieldBatchPoS: '',
    fieldBatchUpDate: '',
    dateSustainabilitySAF: '',
    fieldSustainabilityCO2: '',
    fieldSustainabilityLand: '',
    fieldSustainabilityLandfill: '',
    fieldSustainabilityRecycling: '',
    LCAGHGValue: '',
    GHGEmissionReduction: '',
    neatAmountInMJ: '',
    volume: '',
    abatementBaselineScheme: '',
  },
  batchForm: [
    {
      fieldID: '',
      dateBlend: '',
      dateProd: '',
      fieldTonnes: '',
      fieldMJ: '',
      fieldBatchCountry: '',
      fieldCO2: '',
      fieldGHG: '',
      fieldBatchCountryOf: '',
      fieldSaf: '',
      fieldRsb: '',
      fieldDec: '',
    },
  ],
  fileData: null,
  isFileUploaded: false,
  fileDataTwoStep: null,
  isFileUploadedTwoStep: false,
  selfProduction: false,
  sameAddress: false,
  status: 'idle',
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
    setField: (
      state,
      action: PayloadAction<{
        fieldName: keyof FormState['fields'];
        value: string;
      }>,
    ) => {
      const { fieldName, value } = action.payload;
      state.fields[fieldName] = value;
    },
    setSelfProduction: (state, action: PayloadAction<boolean>) => {
      state.selfProduction = action.payload;
    },
    setSameAddress: (state, action: PayloadAction<boolean>) => {
      state.sameAddress = action.payload;
    },
    setFileData: (state, action: PayloadAction<string>) => {
      state.fileData = action.payload;
    },
    setFileUploaded: (state, action: PayloadAction<boolean>) => {
      state.isFileUploaded = action.payload;
    },
    setFileDataTwoStep: (state, action: PayloadAction<string>) => {
      state.fileDataTwoStep = action.payload;
    },
    setFileUploadedTwoStep: (state, action: PayloadAction<boolean>) => {
      state.isFileUploadedTwoStep = action.payload;
    },
    setSelectedAirport: (
      state,
      action: PayloadAction<{ index: number; value: string }>,
    ) => {
      const { index, value } = action.payload;
      state.batchForm[index].fieldSaf = value;
    },
    addBatch: state => {
      if (state.batchForm.length < 20) {
        state.batchForm.push({
          fieldID: '',
          dateBlend: '',
          dateProd: '',
          fieldTonnes: '',
          fieldMJ: '',
          fieldBatchCountry: '',
          fieldCO2: '',
          fieldGHG: '',
          fieldBatchCountryOf: '',
          fieldSaf: '',
          fieldRsb: '',
          fieldDec: '',
        });
      }
    },
    updateBatchField: (
      state,
      action: PayloadAction<{
        batchIndex: number;
        fieldName: keyof FormState['batchForm'][0];
        value: string;
      }>,
    ) => {
      const { batchIndex, fieldName, value } = action.payload;
      state.batchForm[batchIndex][fieldName] = value;
    },
    removeBatch: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.batchForm.splice(index, 1);
    },
    clearFormFields: state => {
      state.fields = {
        endProductType: '',
        sustainabilityCertification: '',
        sustainabilityCertificationNumber: '',
        lowerHeatingValue: '',
        rawMaterialFeedstock: '',
        conversionProcess: '',
        radioButton: '',
        blendRadioButton: '',
        fieldPoSNumber: '',
        fieldUpDateProd: '',
        fieldName: '',
        dateAcq: '',
        dateEntry: '',
        fieldStreet1: '',
        fieldStreet2: '',
        fieldCountry: '',
        fieldCountryOf: '',
        fieldCertif: '',
        fieldChain: '',
        fieldPoS: '',
        fieldUpDate: '',
        fieldPost: '',
        fieldCity: '',
        sameAddress: '',
        blendFieldName: '',
        blendFieldStreet1: '',
        blendFieldStreet2: '',
        blendFieldCountry: '',
        blendFieldPost: '',
        blendFieldCity: '',
        fieldID: '',
        dateProd: '',
        posIssueDate: '',
        dateBlend: '',
        fieldTonnes: '',
        fieldMJ: '',
        fieldBatchCountry: '',
        fieldCO2: '',
        fieldGHG: '',
        fieldBatchCountryOf: '',
        fieldSaf: '',
        fieldRsb: '',
        fieldDec: '',
        fieldBatchPoS: '',
        fieldBatchUpDate: '',
        dateSustainabilitySAF: '',
        fieldSustainabilityCO2: '',
        fieldSustainabilityLand: '',
        fieldSustainabilityLandfill: '',
        fieldSustainabilityRecycling: '',
        LCAGHGValue: '',
        GHGEmissionReduction: '',
        neatAmountInMJ: '',
        volume: '',
        abatementBaselineScheme: '',
      };
      state.batchForm = [
        {
          fieldID: '',
          dateBlend: '',
          dateProd: '',
          fieldTonnes: '',
          fieldMJ: '',
          fieldBatchCountry: '',
          fieldCO2: '',
          fieldGHG: '',
          fieldBatchCountryOf: '',
          fieldSaf: '',
          fieldRsb: '',
          fieldDec: '',
        },
      ];
      state.selfProduction = false;
      state.sameAddress = false;
      state.isFileUploaded = false;
      state.isFileUploadedTwoStep = false;
      state.fileData = null;
      state.fileDataTwoStep = null;
    },
  },
});

export const {
  setField,
  setFormStatus,
  setSelfProduction,
  setSameAddress,
  setFileData,
  setFileUploaded,
  setFileDataTwoStep,
  setFileUploadedTwoStep,
  addBatch,
  updateBatchField,
  setSelectedAirport,
  removeBatch,
} = formSlice.actions;
export const { clearFormFields } = formSlice.actions;
export const selectFormFields = (state: RootState) => state.form.fields;
export const selectFormStatus = (state: RootState) => state.form.status;

export default formSlice.reducer;
