import { Company, CompanyStatus, CompanyType } from '@prisma/client';

export {
  CompanyType,
  RegistrationIdentificationType as RegistrationIdentifications,
} from '@prisma/client';
export type { Company, Fee as AccountFee } from '@prisma/client';

export const COMPANY_TYPE_NAMES: Record<CompanyType | string, string> = {
  [CompanyType.Supplier]: 'Supplier',
  [CompanyType.Intermediary]: 'Intermediary',
  [CompanyType.Airline]: 'Transport Service Provider (TSP)',
  [CompanyType.Forwarder]: 'Transport Logistics Provider (TLP)',
  // [CompanyType.Aggregator]: 'Facilitator',
  [CompanyType.Corporate]: 'Corporate End-user',
  [CompanyType.Admin]: 'System Admin',
  [CompanyType.Auditor]: 'Sustainability Auditor',
  // [CompanyType.RegistryOperator]: 'Registry Operator',
};

export interface CompanyOption {
  readonly value: Company['id'];
  readonly label: string;
}

export const CompanyTypeOptions: {
  value: Company['type'] | null;
  label: string;
}[] = [
  { value: null, label: 'All' },
  ...Object.values(CompanyType).map(type => ({
    value: type,
    label: COMPANY_TYPE_NAMES[type],
  })),
].filter(typeOption => typeOption.label);

export const CompanyStatusOptions: {
  value: Company['status'] | null;
  label: string;
}[] = [
  { value: null, label: 'All' },
  ...Object.values(CompanyStatus).map(status => ({
    value: status,
    label: status,
  })),
];
