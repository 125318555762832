import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  status: 'idle',
  logs: {
    results: [],
    count: 0,
  },
  bcuLogsStatus: 'idle',
  bcuLogs: [],
};

const logsSlice = createSlice({
  name: 'admin-logs',
  initialState,
  reducers: {
    setAdminLogs: (state, action) => {
      state.logs = action.payload;
    },
    setAdminLogsStatus: (state, action) => {
      state.status = action.payload;
    },
    setBcuLogs: (state, action) => {
      state.bcuLogs = action.payload;
    },
    setBcuLogsStatus: (state, action) => {
      state.bcuLogsStatus = action.payload;
    },
  },
});

export const {
  setAdminLogs,
  setAdminLogsStatus,
  setBcuLogs,
  setBcuLogsStatus,
} = logsSlice.actions;

export const selectAdminLogs = (state: RootState) => state.adminLogs.logs;
export const selectAdminLogsStatus = (state: RootState): any =>
  state.adminLogs.status;
export const selectBcuLogs = (state: RootState) => state.adminLogs.bcuLogs;

export default logsSlice.reducer;
