import { FC } from 'react';
import './stepStyle.scss';
import classNames from 'classnames';

interface StepProps {
  title: string;
  description: string;
  number: number;
  currentStep: number;
  handleCurrentStep: (step: number) => void;
  isFinishedStep: boolean;
  blockStep?: boolean;
  isVisibleStep?: number[];
}

const Step: FC<StepProps> = ({
  title,
  description,
  number,
  currentStep,
  handleCurrentStep,
  isFinishedStep,
  blockStep,
  isVisibleStep,
}) => {
  const circleClass = classNames('circle', {
    'circle-active': number === currentStep,
    'circle-inactive': number !== currentStep,
    'circle-filled': number === 1,
  });

  const circleTextClass = classNames('circle-text', {
    'circle-text-active': number === currentStep,
    'circle-text-inactive': number !== currentStep,
  });

  const renderStepNumber = () => {
    if (number < isVisibleStep!.length) {
      return (
        <div className="svg-container">
          <svg
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.8184 5.69728C2.96163 5.84051 3.20512 5.84051 3.34835 5.69728L7.55929 1.48634C7.70251 1.34311 7.70251 1.09962 7.55929 0.95639L7.04366 0.440765C6.90043 0.297536 6.67126 0.297536 6.52804 0.440765L3.09054 3.87827L1.47205 2.2741C1.32882 2.13087 1.09965 2.13087 0.956421 2.2741L0.440796 2.78972C0.297567 2.93295 0.297567 3.17644 0.440796 3.31967L2.8184 5.69728Z"
              fill={number === currentStep ? '#FFFFFF' : '#07ACA6'}
            />
          </svg>
        </div>
      );
    } else {
      return <h3 className={circleTextClass}>{number}</h3>;
    }
  };

  const handleClick = (): void => {
    if (isVisibleStep && isVisibleStep.includes(number - 1)) {
      handleCurrentStep(number);
    }
  };

  return (
    <div className="step" onClick={handleClick}>
      <div className={circleClass}>{renderStepNumber()}</div>
      <div className="step-text">
        <h3 className="step-title">{title}</h3>
        <span className="step-description">{description}</span>
      </div>
    </div>
  );
};

export default Step;
