import { FC } from "react";
import "./selfStyles.scss";
import SummaryField from "../../SummaryField";
import { useAppSelector } from "../../../../../../store/hooks";



interface Props {
    title: string;
  }
  
  const SelfSum: FC<Props> = ({ title }) => {
    const formValues = useAppSelector((state) => state.form.fields);
    return (
      <div className="form-sum1">
        <h2 className="form-title">{title}</h2>
        <SummaryField
          nameProp="Name" 
          selected={formValues.fieldName}
          border
        />
        <SummaryField
          nameProp="Street 1" 
          selected={formValues.fieldStreet1}
          border
        />
        <SummaryField
          nameProp="Street 2" 
          selected={formValues.fieldStreet2}
          border
        />
        <SummaryField
          nameProp="Post Code" 
          selected={formValues.fieldPost}
          border
        />
        <SummaryField
          nameProp="City" 
          selected={formValues.fieldCity}
          border
        />
        <SummaryField
          nameProp="Country"
          selected={formValues.fieldCountry}
          border
        />
        <SummaryField
          nameProp="Country of the last production/processing site"
          selected={formValues.fieldCountryOf}
          border
        />
        <SummaryField
          nameProp="Chain of custody model"
          selected={formValues.fieldChain}
          
        />
      </div>
    );
  };

export default SelfSum;