import { formatDate } from '../../core/models/contstants/format-date';
import { TRetirementStatementTableEntry } from '../Statements';
import { Column } from '../../components/DefaultTable/types';
import classNames from 'classnames';
import { DownloadLink } from '../../components/DownloadLink/DownloadLink';

export const RETIREMENT_STATEMENTS_COLUMNS: Column<TRetirementStatementTableEntry>[] =
  [
    {
      title: 'BCU ID',
      keyItem: 'BCUID',
      sort: true,
      render: (item: Partial<TRetirementStatementTableEntry>) => {
        if (item.credits && item.credits.length > 0) {
          return (
            <div className={classNames('c-table__row', { isPointer: true })}>
              <span className="c-table__arrow"></span>
              <strong>
                {item.BCUID} ({item.credits.length})
              </strong>
            </div>
          );
        }
        return <div className="c-table__row">{item.BCUID}</div>;
      },
    },
    {
      title: 'Statement ID',
      keyItem: 'RSID',
      sort: true,
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {item.RSID || '-'}
        </div>
      ),
    },
    {
      title: 'Date of issue',
      keyItem: 'issueDate',
      sort: true,
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {formatDate(item.issueDate)}
        </div>
      ),
    },
    {
      title: 'Product',
      keyItem: 'productType',
      sort: true,
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {item.productType}
        </div>
      ),
    },
    {
      title: 'Amount BCUs',
      keyItem: 'amount',
      sort: true,
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {parseFloat(item.amount || '0').toLocaleString('en-US', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
        </div>
      ),
    },
    {
      title: 'Scope 1',
      keyItem: 'scope1',
      sort: true,
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {typeof item.scope1 === 'string'
            ? item.scope1
            : item.scope1 > 1
            ? `${item.scope1} companies`
            : item.scope1 === 0
            ? '-'
            : `${item.scope1} company`}
        </div>
      ),
    },
    {
      title: 'Scope 3',
      keyItem: 'scope3',
      sort: true,
      render: (item: any) => {
        if (item.credits) {
          if (item.credits.length === 0) {
            return (
              <div
                className={classNames('c-table__row', {
                  isPointer: item.credits?.length,
                })}>
                -
              </div>
            );
          }

          const size = item.scope3 + item.scope3Logistic;

          if (size === 0) {
            return (
              <div
                className={classNames('c-table__row', {
                  isPointer: item.credits?.length,
                })}>
                -
              </div>
            );
          }

          return (
            <div
              className={classNames('c-table__row', {
                isPointer: item.credits?.length,
              })}>
              {`${size} ${size > 1 ? 'companies' : 'company'}`}
            </div>
          );
        }

        if (item.scope3 || item.scope3Logistic) {
          return (
            <div
              className={classNames('c-table__row', {
                isPointer: item.credits?.length,
              })}>
              {[item.scope3, item.scope3Logistic].filter(i => !!i).join(', ')}
            </div>
          );
        }

        return (
          <div
            className={classNames('c-table__row', {
              isPointer: item.credits?.length,
            })}>
            -
          </div>
        );
      },
    },
    {
      title: 'Certificate',
      keyItem: 'RSID',
      render: (item: any) => {
        if (!item.sid) {
          return null;
        }

        return (
          <div
            className={classNames('c-table__row', {
              isPointer: item.credits?.length,
            })}>
            <DownloadLink id={item.sid} name={`${item.RSID}.pdf`} />
          </div>
        );
      },
    },
  ];

export const StatementsColumns = () => RETIREMENT_STATEMENTS_COLUMNS;
