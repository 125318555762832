import { FC } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../../../../store/reducers/user/userReducer';
import { selectCreditDetail } from '../../../../store/reducers/user/creditsReducer';
import BCUData from "../../BCUData/BCUData";

export const CreditBCUValues: FC = () => {
  const credit = useAppSelector(selectCreditDetail);
  const user = useAppSelector(selectUser);

  return (
    <div style={{ margin: '20px 0 30px' }}>
      <div className="dashboard-bcu-info__table">
        {credit && <BCUData data={credit?.origin || credit} user={user} editable={false} />}
      </div>
    </div>
  );
};
