import { Table as AntdTable, TableProps } from 'antd';
import React from 'react';

export const BaseTable: React.FC<TableProps<any>> = ({ pagination, ...props }) => {
    return (
        <AntdTable
            className="has-striped-rows"
            size={'middle'}
            pagination={{
                defaultPageSize: 50,
                className: 'c-rsb-pagination',
                pageSizeOptions: [1, 25, 50, 75, 100],
                position: ['topRight', 'bottomRight'],
                showTotal: (total) => `${total} results`,
                itemRender: (_, type, elem) => {
                    return type === 'page' ? null : elem;
                },
                locale: {
                    page_size: '',
                    jump_to: 'Jump to page',
                    page: '',
                    items_per_page: '',
                    jump_to_confirm: '',
                },
                showSizeChanger: true,
                showQuickJumper: true,
                size: 'small',
                ...pagination,
            }}
            {...props}
        />
    )
};