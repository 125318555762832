import { CompanyType, UserRole, UserStatus } from '@prisma/client';
import React, { useEffect, useMemo, useState } from 'react';

import { UserBalance } from '../components/UserBalance/UserBalance';
import { PaginatedTable } from '../components/Table/Table';
import { fetchUserTransactions } from '../../API/user-transactions';
import {
  selectTransactions,
  selectUserTransactionsStatus,
} from '../../store/reducers/user/transactionsReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { Search } from '../components/Table/Search/Search';
import { useSearchParamsState } from '../components/Table/Pagination/PaginationHook';
import { COLUMNS } from './models/columns.constant';
import { fetchCredits } from '../../API/credits';
import { downloadUserTransactions, fetchCompanyData } from '../../API/user';
import { DateQueryTypes, DateSelect } from '../components/Table/DateSelect';
import { Select } from '../components/Form/Select/Select';
import {
  TransactionTypeOptions,
  TransactionType,
} from '../../structure/models/transaction/transaction';
import { SortOrder } from '../components/DefaultTable/types';
import { fetchPrivateRetirementStatements } from '../../API/retirement-statements';
import { SubTable, TRetirementStatement } from '../home/Statements';
import { RETIREMENT_STATEMENTS_COLUMNS } from '../home/models/columns.constant';
import AsyncTable from '../components/DefaultTable/async';
import {
  initPublicList,
  selectRetirementStatementsPrivateList,
  setRetirementStatementsPrivateList,
} from '../../store/reducers/user/retirementStatementsReducer';
import { isInTypes } from '../../helpers/types';
import { DownloadFileButton } from '../components/Buttons/DownloadFileButton/DownloadFileButton';

import './TransactionsStyle.scss';

export const Transactions: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(selectRetirementStatementsPrivateList);

  const user = useAppSelector(selectUser);

  const isUserSuspended = useMemo(
    () => user?.status === UserStatus.Suspended,
    [user],
  );

  const [activeRows, setActiveRows] = useState<{ [key: string]: boolean }>({});
  const [sortBy, setSortBy] = useState<{ key?: string; direction: SortOrder }>({
    key: 'id',
    direction: 'desc',
  });
  const [type, setType] = useSearchParamsState<string>('type', '');
  const [startDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    '',
  );
  const [endDate, setEndDate] = useSearchParamsState<string>('endDate', '');
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );

  const onSearch = (e: any) => setSearch(e.target.value);

  const onTypeChange = (option: any) => {
    setType(option.value);
  };

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (!user) return;

    dispatch(fetchCredits());
    dispatch(fetchCompanyData());
  }, []);

  const transactionTypeOptions = TransactionTypeOptions.filter(option => {
    const isSupplier = user?.company?.type === CompanyType.Supplier;

    if (
      (!isSupplier && option.value === TransactionType.Register) ||
      (isSupplier && option.value === TransactionType.Retirement)
    ) {
      return false;
    }

    return option;
  }) as any;

  const onClear = () => setSearch(null);

  const handleSortChange = ({ column }: { column: any }) => {
    setSortBy(prevState => {
      let direction: SortOrder = 'asc';

      if (prevState.key === column.keyItem) {
        direction = prevState.direction === 'asc' ? 'desc' : 'asc';
      }

      return {
        ...prevState,
        direction,
        key: column.keyItem,
      };
    });
  };

  const handleRowClicked = (item: TRetirementStatement) => {
    setActiveRows({
      ...activeRows,
      [item.BCUID as any]: !activeRows[item.BCUID as any],
    });
  };

  const showRetirementTable = user?.company.type
    ? !Boolean(
        isInTypes(user?.role, [
          UserRole.SuperAdmin,
          UserRole.Admin,
          UserRole.Auditor,
        ]) || user.company.type === CompanyType.Supplier,
      )
    : false;

  useEffect(() => {
    const params = {
      page: list.page,
      limit: list.limit,
      sortBy: sortBy.key,
      sortOrder: sortBy.direction,
      accountId: user?.company.id,
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(search ? { search: search } : {}),
    };
    dispatch(fetchPrivateRetirementStatements(params));
  }, [
    list.page,
    list.limit,
    sortBy.key,
    sortBy.direction,
    startDate,
    endDate,
    search,
  ]);

  const userColumns = useMemo(() => COLUMNS(user, type), [user, type]);

  return (
    <div className="transactions">
      <UserBalance company={user!.company} />
      <div className="transactions__header">
        <h1 className="transactions__header_text">Transaction History</h1>
        <div className="transactions__filters">
          {isUserSuspended || (
            <div style={{ marginRight: 10 }}>
              <DownloadFileButton
                fetchDownload={() =>
                  downloadUserTransactions({
                    search: search || null,
                    type: type || null,
                    startDate: startDate || null,
                    endDate: endDate || null,
                  })
                }
              />
            </div>
          )}
          <Select
            placeholder="Type of the transaction"
            options={transactionTypeOptions}
            value={type}
            onChange={onTypeChange}
          />
          <DateSelect onDateChange={onDateChange} />
          <Search
            isClearable={Boolean(search)}
            defaultValue={search ? search : ''}
            onClear={onClear}
            onChange={onSearch}
          />
        </div>
      </div>
      <div className="transactions__container">
        <PaginatedTable
          showHeader={false}
          columns={userColumns}
          fetchData={fetchUserTransactions}
          selectStatus={selectUserTransactionsStatus}
          selectData={selectTransactions}
          filters={{ search, type, startDate, endDate }}
        />
      </div>
      {showRetirementTable && (
        <div className="transactions__operations">
          <h2 className="transactions__operations_title">
            Account retirement operations
          </h2>
          <AsyncTable<any>
            isLoading={list.loading}
            showSearch={false}
            slots={{
              rowAfter: SubTable,
            }}
            slotProps={{
              head: {
                current: sortBy,
                onSortChange: handleSortChange,
              },
              rowAfter: { activeRows },
            }}
            onRowClicked={handleRowClicked}
            onLimitChange={limit =>
              dispatch(
                setRetirementStatementsPrivateList({
                  ...initPublicList,
                  limit,
                  page: 1,
                }),
              )
            }
            onPageChange={page =>
              dispatch(
                setRetirementStatementsPrivateList({ ...initPublicList, page }),
              )
            }
            headers={RETIREMENT_STATEMENTS_COLUMNS}
            {...list}></AsyncTable>
        </div>
      )}
    </div>
  );
});
