import React, { FC, useEffect, useState } from 'react';
import './BatchFormStyles.scss';
import { batchInformation, IBatchFormProps } from './types';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import Field from '../../Field/Field';
import FormTitle from '../../FormTitle/FormTitle';
import {
  COUNTRY,
  INCENTIVES_TAX_CREDITS,
} from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  handleEnterLatinAndNumbers,
  handleEnterNumbers,
} from '../../../../../../helpers/handleKeyPress';
import { useBatchForm } from './useBatchForm';
import { DefaultSelect } from '../../../../Form/DefaultSelect/DefaultSelect';
import {
  useLazyGetAirportByCodeQuery,
  useLazyGetAirportsQuery,
} from '../../../../../../API/airports';
import { toOptions } from '../../../../../../helpers/toOptions';
import { validateBatchForm } from './validateBatchForm';
import { BatchFormFields } from '../../../../../../store/reducers/user/bcuFormReducer';
import { selectBaseLines } from '../../../../../../store/reducers/user/abatementBaselinesReducer';
import { format } from 'date-fns';

interface IBatchProps {
  onRemove: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFieldChange: (key: keyof batchInformation, value: any) => void;
  batch: batchInformation;
  index: number;
  errorVisible: boolean;
  showRSB: boolean;
  errors?: Record<keyof batchInformation, string>;
}

const Batch: FC<IBatchProps> = ({
  onRemove,
  onFieldChange,
  onBlur,
  batch,
  index,
  errors,
  errorVisible,
  showRSB,
}) => {
  const isFirstInBatch = index === 0;
  const [getAirports] = useLazyGetAirportsQuery();
  const [getAirportByCode] = useLazyGetAirportByCodeQuery();
  const [selectedAirport, setSelectedAirport] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [airportOptions, setAirportOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const getDateProdOptions = () => {
    const currentYear = new Date().getFullYear();
    const minYear = 1990;
    const yearRange = currentYear + 1 - minYear;
    const years = Array.from(
      Array(yearRange),
      (_, x) => `${minYear + x}`,
    ).reverse();

    return toOptions(years);
  };

  const onFieldDecChange = (options: any) => {
    const value = options.map((item: any) => item.value).join(', ');

    onFieldChange('fieldDec', value);
  };

  useEffect(() => {
    if (!batch.fieldBatchCountryOf) {
      setAirportOptions([]);
      return;
    }

    getAirports({ country: batch.fieldBatchCountryOf }, true).then(
      ({ data }) => {
        const transformedOptions = (data || []).map(
          ({ code, name }: { code: string; name: string }) => ({
            value: code,
            label: `${name} [${code}]`,
          }),
        );

        setAirportOptions(transformedOptions);
      },
    );
  }, [batch.fieldBatchCountryOf]);

  useEffect(() => {
    if (!batch.fieldSaf || !batch.fieldBatchCountryOf) {
      setSelectedAirport(null);
      return;
    }

    getAirportByCode(batch.fieldSaf, true).then(({ data }) => {
      if (data && data.code && data.name) {
        setSelectedAirport({
          value: data.code,
          label: `${data.name} [${data.code}]`,
        });
      }
    });
  }, [batch.fieldBatchCountryOf, batch.fieldSaf]);

  const dateProdOptions = getDateProdOptions();

  return (
    <div className="batch-form--wrapper mb-5">
      <FormTitle
        title={
          index > 0
            ? `Batch ${index + 1} Information`
            : `4. Batch 1 Information`
        }
        handleRemoveBatch={() => onRemove()}
        index={index}
      />
      <Field
        required
        type="fieldID"
        label="Batch ID end product"
        placeholder="Batch ID end product"
        id="fieldID"
        value={batch.fieldID}
        onChange={e => onFieldChange('fieldID', e.target.value)}
        onBlur={onBlur}
        errorVisible={errorVisible}
        error={errors?.fieldID}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        type="string"
        required
        label="Neat amount in [tonnes]"
        placeholder="Neat amount in [tonnes]"
        id="fieldTonnes"
        value={batch.fieldTonnes}
        onChange={e => onFieldChange('fieldTonnes', e.target.value)}
        onBlur={onBlur}
        error={errors?.fieldTonnes}
        errorVisible={errorVisible}
        formatOnBlur
        decimals={5}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        required
        type="string"
        label="Neat amount in [MJ]"
        placeholder="Neat amount in [MJ]"
        id="fieldMJ"
        value={batch.fieldMJ}
        onChange={e => onFieldChange('fieldMJ', e.target.value)}
        onBlur={onBlur}
        error={errors?.fieldMJ}
        formatOnBlur
        decimals={0}
        errorVisible={errorVisible}
      />
      <DefaultSelect
        required
        inputId="dateProd"
        name="dateProd"
        label="Year of neat product production"
        placeholder="Year of neat product production"
        error={errors?.dateProd}
        isErrorText={errorVisible}
        isSearchable
        options={dateProdOptions as any}
        value={batch.dateProd}
        isDisabled={!isFirstInBatch}
        onChange={(option: any) => {
          onFieldChange('dateProd', option.value);
        }}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        label="Date of blending"
        placeholder="Date of blending"
        required
        type="date"
        id="dateBlend"
        onChange={e => onFieldChange('dateBlend', e.target.value)}
        value={batch.dateBlend}
        max={format(new Date(), 'yyyy-MM-dd')}
        onBlur={onBlur}
        error={errors?.dateBlend}
        errorVisible={errorVisible}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        required
        label={
          <span>
            LCA GHG value [gCO<sub>2</sub>eq/MJ]
          </span>
        }
        placeholder="LCA GHG value [gCO2eq/MJ]"
        id="fieldCO2"
        onChange={e => onFieldChange('fieldCO2', e.target.value)}
        onBlur={onBlur}
        value={batch.fieldCO2}
        error={errors?.fieldCO2}
        errorVisible={errorVisible}
        formatOnBlur
        decimals={2}
      />
      <Field
        disabled
        label="GHG Emission Reduction [%]"
        id="fieldGHG"
        onChange={e => onFieldChange('fieldGHG', e.target.value)}
        onBlur={onBlur}
        value={batch.fieldGHG}
        error={errors?.fieldGHG}
        errorVisible={errorVisible}
        placeholder="0"
      />
      <DefaultSelect
        required
        isSearchable
        inputId="fieldCountryOf"
        name="fieldCountryOf"
        label="Country of physical end product delivery"
        placeholder="Country of physical end product delivery"
        error={errors?.fieldBatchCountryOf || ''}
        options={COUNTRY as any}
        onBlur={onBlur}
        isDisabled={!isFirstInBatch}
        isErrorText={errorVisible}
        value={COUNTRY.find(c => c.value === batch.fieldBatchCountryOf) || null}
        onChange={(option: any) => {
          setSelectedAirport(null);
          onFieldChange('fieldSaf', '');
          onFieldChange('fieldBatchCountryOf', option.value);
        }}
      />
      <DefaultSelect
        isSearchable
        inputId="fieldSaf"
        name="fieldSaf"
        label="Airport of physical end product delivery"
        placeholder="Airport of physical end product delivery"
        isDisabled={!isFirstInBatch || !batch.fieldBatchCountryOf}
        error={errors?.fieldSaf || ''}
        options={airportOptions}
        value={selectedAirport}
        isErrorText={errorVisible}
        onBlur={onBlur}
        onChange={(option: any) => {
          onFieldChange('fieldSaf', option.value);
        }}
      />
      {showRSB && (
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          type="text"
          label="Additional claim as allowed under the RSB certification system"
          placeholder="Additional claim as allowed under the RSB certification system"
          id="fieldRsb"
          value={batch.fieldRsb}
          disabled={!isFirstInBatch}
          onChange={e => onFieldChange('fieldRsb', e.target.value)}
          onBlur={onBlur}
          error={errors?.fieldRsb}
          errorVisible={errorVisible}
        />
      )}
      <DefaultSelect
        isMulti
        isSearchable
        inputId="fieldDec"
        name="fieldDec"
        label="Declaration of incentives and tax credits"
        placeholder="Declaration of incentives and tax credits"
        isDisabled={!isFirstInBatch}
        error={errors?.fieldDec || ''}
        isErrorText={errorVisible}
        options={INCENTIVES_TAX_CREDITS}
        value={
          INCENTIVES_TAX_CREDITS.filter(v =>
            batch.fieldDec.includes(v.value),
          ) || null
        }
        onBlur={onBlur}
        onChange={(option: any) => {
          onFieldDecChange(option);
        }}
      />
    </div>
  );
};

const BatchForm: FC<IBatchFormProps> = ({
  handleClick,
  setBatchNumber,
  batchNumber,
}) => {
  const sustainabilityCertification = useAppSelector(
    store => store.form.fields.sustainabilityCertification,
  );
  const baselines = useAppSelector(selectBaseLines);
  const selectedBaseline = (baselines || []).find(
    b => b.id === sustainabilityCertification,
  );
  const {
    formik,
    batchIndex,
    handleFieldChange,
    handleAddBatch,
    isRequiredFieldEmpty,
    handleRemoveBatch,
  } = useBatchForm(setBatchNumber);

  const [sharedData, setSharedData] = useState<
    Pick<
      BatchFormFields,
      'fieldBatchCountryOf' | 'fieldSaf' | 'dateProd' | 'fieldRsb' | 'fieldDec'
    >
  >({
    fieldBatchCountryOf: '',
    fieldDec: '',
    fieldRsb: '',
    fieldSaf: '',
    dateProd: '',
  });

  useEffect(() => {
    if (formik.values.batchInformation.length === 0) {
      return;
    }

    const { fieldSaf, fieldDec, fieldRsb, dateProd, fieldBatchCountryOf } =
      formik.values.batchInformation[0];

    setSharedData({
      fieldSaf,
      fieldRsb,
      fieldDec,
      fieldBatchCountryOf,
      dateProd,
    });
  }, [formik.values.batchInformation]);

  const [errorVisible, setErrorVisible] = useState(false);

  const handleFormClick = () => {
    const hasEmptyRequiredFields = isRequiredFieldEmpty();
    const validate = validateBatchForm(
      {
        ...formik.values,
        batchInformation: formik.values.batchInformation.map((b, i) =>
          i > 0 ? { ...b, ...sharedData } : b,
        ),
      },
      formik.setErrors,
    );
    const areErrors = Boolean(validate.batchInformation?.length);

    setErrorVisible(hasEmptyRequiredFields || areErrors);

    if (!hasEmptyRequiredFields && !areErrors) {
      handleClick();
    }
  };

  const onChange = (
    index: number,
    key: keyof batchInformation,
    value: string,
  ) => {
    handleFieldChange(index, key, value);
    setErrorVisible(false);
  };

  const isCorsiaStep =
    selectedBaseline &&
    ['RSB CORSIA', 'RSB_CORSIA', 'ISCC CORSIA', 'ISCC_CORSIA'].indexOf(
      selectedBaseline.name,
    ) > -1;

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.batchInformation.map((batch, index) => (
          <Batch
            key={index}
            batch={index > 0 ? { ...batch, ...sharedData } : batch}
            index={index}
            onBlur={formik.handleBlur}
            onRemove={() => handleRemoveBatch(index)}
            onFieldChange={(key, value) => onChange(index, key, value)}
            errors={
              formik.errors.batchInformation &&
              typeof formik.errors.batchInformation !== 'string'
                ? (formik.errors.batchInformation[index] as Record<
                    keyof batchInformation,
                    string
                  >)
                : undefined
            }
            errorVisible={errorVisible}
            showRSB={
              selectedBaseline &&
              selectedBaseline.name &&
              selectedBaseline.name.includes('RSB')
            }
          />
        ))}
        <div className="batch-form--btn">
          <NextButton
            handleClick={handleAddBatch}
            disabled={batchIndex > 18}
            text="Add another batch"
            styleText={{ color: '#07ACA6' }}
            style={{
              marginLeft: '0',
              width: '165px',
              background: '#FFFFFF',
              border: '1px solid #07ACA6',
            }}
          />
          <NextButton
            handleClick={handleFormClick}
            text={isCorsiaStep ? 'Next step' : 'Preview'}
            style={{ marginLeft: '223px' }}
          />
        </div>
      </form>
    </div>
  );
};

export default BatchForm;
