import { FC, useEffect, useRef } from 'react';
import format from 'date-fns/format';
import { Calendar } from 'react-date-range';
import classNames from 'classnames';

interface DateFieldProps {
  isCalendarOpen: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  date?: Date | null;
  color?: string;
  onDateSelect: (date: Date) => void;
  onCalendarToggle: (value: boolean) => void;
  onClick?: () => void;
}

const DATE_FORMAT = 'dd.MM.yyyy';

export const DateField: FC<DateFieldProps> = ({
  isCalendarOpen,
  minDate,
  maxDate,
  date,
  color = '#07ACA6',
  onDateSelect,
  onCalendarToggle,
  onClick,
}) => {
  const calendarRef = useRef<HTMLDivElement>(null);

  const onChange = (date: Date) => {
    onDateSelect(date);
  };

  const onClickOutside = (e: any) => {
    if (calendarRef.current && !calendarRef.current.contains(e.target)) {
      onCalendarToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);

    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, []);

  const currentMinDate = minDate !== null ? (minDate as Date) : undefined;
  const currentMaxDate = maxDate !== null ? (maxDate as Date) : undefined;

  return (
    <div
      className={classNames('date-select__input', { filled: Boolean(date) })}
      onClick={onClick}>
      <span
        className={classNames('material-icons-outlined', 'date-select__icon')}>
        calendar_month
      </span>
      <input
        readOnly
        value={date ? format(date, DATE_FORMAT) : ''}
        placeholder="DD.MM.YYYY"
      />
      {isCalendarOpen && (
        <div ref={calendarRef}>
          <Calendar
            className="date-select__calendar"
            date={date || new Date()}
            color={color}
            minDate={currentMinDate}
            maxDate={currentMaxDate}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};
