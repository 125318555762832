import { ADMIN_REFERENCE_VALUES_ROUTES } from '../../../../../structure/routes/routes';

export const TABS = [
  {
    title: 'Certification Schemes',
    path: ADMIN_REFERENCE_VALUES_ROUTES.CERTIFICATION_SCHEMES,
  },
  {
    title: 'Create Certification Schemes',
    path: ADMIN_REFERENCE_VALUES_ROUTES.CREATE_CERTIFICATION_SCHEMES,
  },
];
