import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import classNames from 'classnames';

import { MyFormValues } from './types';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import FormTitle from '../../FormTitle/FormTitle';
import Field from '../../Field/Field';
import {
  CHAIN_OF_CUSTODY_MODEL,
  COUNTRY,
} from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import PdfFileUpload from '../../PdfFileUpload/PdfFileUpload';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setField } from '../../../../../../store/reducers/user/bcuFormReducer';
import {
  handleEnterLatinAndNumbers,
  handleEnterLatinLetters,
  handleEnterNumbers,
  handleEnterNumbersAndSlash,
} from '../../../../../../helpers/handleKeyPress';
import { DefaultSelect } from '../../../../Form/DefaultSelect/DefaultSelect';

import './NeatFormStyles.scss';

interface INeatFormProps {
  handleClick: () => void;
  onFileUpload: (file: File) => void;
  onDragAndDrop: (file: File | null) => void;
}
const NeatForm: FC<INeatFormProps> = ({
  handleClick,
  onFileUpload,
  onDragAndDrop,
}) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(state => state.form);
  const [errorVisible, setErrorVisible] = useState(false);
  const isFileUploaded = formValues.isFileUploadedTwoStep;

  const handleValidation = (values: any) => {
    const errors = {} as any;

    if (!values.dateAcq.length) {
      errors.dateAcq = 'Field date of acquisition is required';
    }
    if (!values.dateEntry.length) {
      errors.dateEntry = 'Field date of acquisition is required';
    }
    if (values.fieldName.length === 0) {
      errors.fieldName = 'Field name is required';
    } else if (values.fieldName.length < 2) {
      errors.fieldName = 'Field name must have at least 2 characters';
    }
    if (!values.fieldStreet1) {
      errors.fieldStreet1 = 'Field street 1 is required';
    }
    if (!values.fieldCountry) {
      errors.fieldCountry = 'Field country is required';
    }
    if (!values.fieldCountryOf) {
      errors.fieldCountryOf = 'Field country of 2 is required';
    }
    if (!values.fieldChain) {
      errors.fieldChain = 'Field chain of custody model is required';
    }
    if (!values.fieldPoS) {
      errors.fieldPoS = 'Field PoS ID is required';
    }
    if (!isFileUploaded) {
      errors.fieldUpDate = 'Please upload pdf';
    }
    if (!values.fieldPost || values.fieldPost.length < 3) {
      errors.fieldPost = 'Field must have at least 3 characters';
    }
    if (!values.fieldCity) {
      errors.fieldCity = 'Field city is required';
    }

    formik.setErrors(errors);

    return errors;
  };

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });

  const handleFieldChange = (field: keyof MyFormValues, value: string) => {
    dispatch(setField({ fieldName: field, value: value }));
    formik.setFieldValue(field, value);
  };

  const isRequiredFieldEmpty = () => {
    const requiredFields: Array<keyof MyFormValues> = [
      'dateAcq',
      'fieldName',
      'fieldStreet1',
      'fieldCountry',
      'fieldCountryOf',
      'fieldChain',
      'fieldPoS',
      'fieldPost',
      'fieldCity',
    ];
    return (
      requiredFields.some(field => !formik.values[field]) || !isFileUploaded
    );
  };

  const handleFormClick = () => {
    const hasEmptyRequiredFields = isRequiredFieldEmpty();
    handleValidation(formik.values);

    setErrorVisible(hasEmptyRequiredFields);
    if (!hasEmptyRequiredFields) {
      handleClick();
    }
  };

  return (
    <div>
      <FormTitle title="2. Neat production information" />
      <form className="form-style" onSubmit={formik.handleSubmit}>
        <div
          className={classNames('date-form', {
            error: formik.errors.dateAcq || formik.errors.dateEntry,
          })}>
          <Field
            required
            handleKeyPress={handleEnterNumbers}
            type="date"
            label="Date of acquisition"
            id="dateAcq"
            max={format(new Date(), 'yyyy-MM-dd')}
            value={formik.values.dateAcq}
            onChange={e => handleFieldChange('dateAcq', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.dateAcq}
            errorVisible={errorVisible}
          />
          <Field
            required
            handleKeyPress={handleEnterNumbers}
            label="Date of entry in tracking system"
            type="date"
            id="dateEntry"
            min={
              formik.values.dateAcq &&
              format(new Date(formik.values.dateAcq), 'yyyy-MM-dd')
            }
            max={format(new Date(), 'yyyy-MM-dd')}
            onChange={e => handleFieldChange('dateEntry', e.target.value)}
            value={formik.values.dateEntry || formik.values.dateAcq}
            onBlur={formik.handleBlur}
            error={formik.errors.dateEntry}
            errorVisible={errorVisible}
          />
          {!Boolean(formik.errors.dateEntry) && (
            <span className="option">
              If different from the date of acquisition
            </span>
          )}
        </div>
        <Field
          handleKeyPress={handleEnterLatinLetters}
          required
          label="Name"
          placeholder="Name"
          id="fieldName"
          value={formik.values.fieldName}
          onChange={e => handleFieldChange('fieldName', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldName}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          required
          label="Street address"
          placeholder="Street address"
          id="fieldStreet1"
          value={formik.values.fieldStreet1}
          onChange={e => handleFieldChange('fieldStreet1', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldStreet1}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="Street address line 2"
          placeholder="Street address line 2"
          id="fieldStreet2"
          value={formik.values.fieldStreet2}
          onChange={e => handleFieldChange('fieldStreet2', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldStreet2}
          errorVisible={errorVisible}
        />

        <div className="field-cp">
          <Field
            handleKeyPress={handleEnterLatinAndNumbers}
            type="text"
            required
            label="Postal code"
            placeholder="Postal code"
            id="fieldPost"
            value={formik.values.fieldPost}
            onChange={e => handleFieldChange('fieldPost', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.fieldPost}
            errorVisible={errorVisible}
          />
          <Field
            handleKeyPress={handleEnterLatinLetters}
            required
            label="City"
            placeholder="City"
            id="fieldCity"
            // type="string"
            value={formik.values.fieldCity}
            onChange={e => handleFieldChange('fieldCity', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.fieldCity}
            errorVisible={errorVisible}
          />
        </div>

        <DefaultSelect
          required
          isSearchable
          inputId="fieldCountry"
          name="fieldCountry"
          label="Country"
          placeholder="Country"
          error={formik.errors.fieldCountry}
          options={COUNTRY as any}
          value={formik.values.fieldCountry}
          onChange={(option: any) => {
            handleFieldChange('fieldCountry', option.value);
          }}
        />
        <DefaultSelect
          required
          isSearchable
          inputId="fieldCountryOf"
          name="fieldCountryOf"
          label="Country of the last production/processing site"
          placeholder="Country of the last production/processing site"
          error={formik.errors.fieldCountryOf}
          options={COUNTRY as any}
          value={formik.values.fieldCountryOf}
          onChange={(option: any) => {
            handleFieldChange('fieldCountryOf', option.value);
          }}
        />
        <DefaultSelect
          required
          isSearchable
          inputId="fieldChain"
          name="fieldChain"
          label="Chain of custody model"
          placeholder="Chain of custody model"
          error={formik.errors.fieldChain}
          options={CHAIN_OF_CUSTODY_MODEL as any}
          value={formik.values.fieldChain}
          onChange={(option: any) => {
            handleFieldChange('fieldChain', option.value);
          }}
        />
        <Field
          handleKeyPress={handleEnterNumbersAndSlash}
          required
          label="PoS ID"
          placeholder="PoS ID"
          id="fieldPoS"
          showOptional={false}
          value={formik.values.fieldPoS}
          onChange={e => handleFieldChange('fieldPoS', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldPoS}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterNumbers}
          type="date"
          label="PoS issue date"
          id="posIssueDate"
          value={formik.values.posIssueDate}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={e => handleFieldChange('posIssueDate', e.target.value)}
          onBlur={formik.handleBlur}
          errorVisible={errorVisible}
        />
        <PdfFileUpload
          setFieldValue={formik.setFieldValue}
          onDragAndDrop={onDragAndDrop}
          onFileUpload={onFileUpload}
          label="PoS pdf upload"
          required
          id="fieldUpDate"
          name="fieldUpDate"
          error={formik.errors.fieldUpDate}
          errorVisible={errorVisible}
        />
        <NextButton handleClick={handleFormClick} text="Next step" />
      </form>
    </div>
  );
};

export default NeatForm;
