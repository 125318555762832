import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import './UsersStyle.scss';
import { USERS_COLUMNS } from './models/columns.constant';
import { EditablePaginatedTable } from '../../../components/Table/Table';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import {
  selectUsersList,
  selectUsersStatus,
} from '../../../../store/reducers/admin/usersReducer';
import { Search } from '../../../components/Table/Search/Search';
import {
  downloadUsers,
  fetchEditUser,
  fetchUsers,
} from '../../../../API/admin/users';
import { DownloadFileButton } from '../../../components/Buttons/DownloadFileButton/DownloadFileButton';
import { hasChanges } from './models/has-changes.constant';
import { transformData, validate } from './models/validate';
import { useAppDispatch } from '../../../../store/hooks';
import {
  setSelectedRow,
  setVisiblePopUp,
} from '../../../../store/reducers/admin/adminReducer';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../hooks/useNotification';
import { UserStatusOptions } from '../../../../structure/models/user/user';
import { DateQueryTypes, DateSelect } from '../../../components/Table/DateSelect';
import { Select, Option } from '../../../components/Form/Select/Select';
import { SingleValue } from 'react-select';


export const Users: React.FC = () => {
  const toast = useNotification();
  const navigate = useNavigate();

  const [status, setStatus] = useSearchParamsState('status', null);
  const [startDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    '',
  );
  const [endDate, setEndDate] = useSearchParamsState<string>('endDate', '');
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );
  const [sortBy] = useSearchParamsState<string | null>(
      'sortBy',
      null,
  );
  const [sortOrder] = useSearchParamsState<string | null>(
      'sortOrder',
      null,
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setVisiblePopUp(false));
  };

  const onRowClick = (item: any) => {
    dispatch(setSelectedRow(item));
    navigate(item.id.toString());
  };

  const onClear = () => setSearch(null);

  useEffect(() => {
    return () => handleClose();
  }, []);

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onChangeStatus = (options: SingleValue<Option>) => {
    setStatus(options?.value);
  };

  const params: any = {
    search: search || undefined,
    status: status || undefined,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    sortBy: sortBy || undefined,
    sortOrder: sortOrder || undefined,
  };

  return (
    <div className="users">
      <div className="users__header">
        <h1 className="users__header_text">Users</h1>
        <div className="controls">
          <DownloadFileButton fetchDownload={() => downloadUsers(params)} />
          <Select
            className="users__header_filter"
            placeholder="Status"
            options={UserStatusOptions}
            isClearable={false}
            value={status}
            onChange={onChangeStatus}
          />
          <DateSelect onDateChange={onDateChange} />
          <Col className="search">
            <Search
              isClearable={Boolean(search)}
              className="search__input"
              defaultValue={search ? search : ''}
              onClear={onClear}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </div>
      </div>
      <EditablePaginatedTable
        className="users__table"
        fetchData={fetchUsers}
        getColumns={USERS_COLUMNS}
        selectData={selectUsersList}
        selectStatus={selectUsersStatus}
        validate={validate}
        transformAndDispatchData={formData => {
          return dispatch(fetchEditUser(transformData(formData))).then(() => {
            toast.success({ message: 'Success', description: 'User has been updated' });
          });
        }}
        filters={{
          search,
          status,
          startDate,
          endDate,
        }}
        variant="users"
        hasChanges={hasChanges}
        onRowClick={onRowClick}
        isPopup={false}
      />
    </div>
  );
};
