import { FC } from 'react';
import './genStyles.scss';
import SummaryField from '../../SummaryField';
import { useAppSelector } from '../../../../../../store/hooks';
import { selectBaseLines } from '../../../../../../store/reducers/user/abatementBaselinesReducer';

interface Props {
  title: string;
}

const GenField: FC<Props> = ({ title }) => {
  const formValues = useAppSelector(state => state.form.fields);
  const baselines = useAppSelector(selectBaseLines) || [];
  const selectedBaseline = baselines.find(
    ({ id }) => id === formValues.sustainabilityCertification,
  );

  return (
    <div className="form-sum">
      <h2 className="form-title">{title}</h2>
      <SummaryField
        nameProp="End product type"
        selected={formValues.endProductType}
        border
      />
      <SummaryField
        nameProp="Sustainability certification"
        selected={selectedBaseline ? selectedBaseline.name : ''}
        border
      />
      <SummaryField
        nameProp="Sustainability certification number"
        selected={formValues.sustainabilityCertificationNumber}
        border
      />
      <SummaryField
        nameProp="Certification Body"
        selected={formValues.fieldCertif}
        border
      />
      <SummaryField
        nameProp="Neat product source"
        selected={formValues.radioButton}
        border
      />
      <SummaryField
        nameProp="Raw material (feedstock)"
        selected={formValues.rawMaterialFeedstock}
        border
      />
      <SummaryField
        nameProp="Country of feedstock origin"
        selected={formValues.fieldBatchCountry}
        border
      />
      <SummaryField
        nameProp="Feedstock conversion process"
        selected={formValues.conversionProcess}
        border
      />
      <SummaryField
        nameProp="Lower heating value"
        selected={formValues.lowerHeatingValue}
      />
    </div>
  );
};

export default GenField;
