import React, {
  ComponentType,
  ElementType,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import './styles.scss';
import './async.scss';
import { SearchTable } from '../SearchTable';
import { PaginationBoardInTable } from '../../transfer/PaginationBoardInTable';
import { OnFilterChange, Filters } from './TableFilters';
import { ComponentWithSlots, Slot } from '../component';
import { Column, RowAfterProps, RowBeforeProps, TableHeadProps } from './types';
import { TableHead } from './head';

type AsyncTableProps<T = any> = {
  items: T[];
  total: number;
  limit: number;
  page: number;
  headers: Column<any>[];
  isLoading?: boolean;
  showPagination?: boolean;
  showSearch?: boolean;
  showFilters?: boolean;
  onRowClicked?: (item: T, rowIndex?: number, columnIndex?: number) => void;
  onLimitChange: (limit: number) => void;
  onPageChange: (page: number) => void;
  onFilter?: (filter: OnFilterChange) => void;
  onSearch?: (query: string) => void;
  slotProps?: {
    head?: TableHeadProps<T>;
    rowBefore?: RowBeforeProps<T>;
    rowAfter?: RowAfterProps<T>;
  };
};

type AsyncTableSlots<
  T = any,
  H extends ElementType | ComponentType<TableHeadProps<T>> = 'thead',
  Rb extends ComponentType<{ item?: T; index?: number }> = any,
  Ra extends ComponentType<{ item?: T; index?: number }> = any,
> = {
  head?: Slot<H>;
  rowBefore?: Slot<Rb>;
  rowAfter?: Slot<Ra>;
};

const AsyncTable = <T = any,>({
  items,
  limit,
  page,
  onPageChange,
  onLimitChange,
  total,
  headers,
  slots,
  slotProps,
  onRowClicked,
  onSearch,
  onFilter,
  isLoading = false,
  showPagination = true,
  showSearch = true,
  showFilters = true,
}: ComponentWithSlots<AsyncTableProps<T>, AsyncTableSlots<T>>) => {
  const [rows, setRows] = useState<ReactNode[][]>([]);

  const Head = slots?.head ?? TableHead;
  const RowBefore = slots?.rowBefore ?? null;
  const RowAfter = slots?.rowAfter ?? null;

  const totalPages = Math.max(Math.ceil(total / limit), 1);

  // Prepare table presentation data
  useEffect(() => {
    setRows(
      (items || []).map((item, index) => {
        return headers.map(
          column => column.render && column.render(item, index),
        );
      }),
    );
  }, [items, headers]);

  const handleRowsPerPageChange = (selectedRowsPerPage: number) => {
    onLimitChange(selectedRowsPerPage);
  };

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  return (
    <div className={`c-table c-table--async${isLoading ? ' is-loading' : ''}`}>
      {(showSearch || showFilters) && (
        <div className="c-table__header">
          <div className="d-flex">
            {showSearch && (
              <SearchTable
                onChange={event =>
                  onSearch ? onSearch(event.target.value.trim()) : undefined
                }
              />
            )}
            {showFilters && (
              <Filters
                columns={headers}
                items={items}
                onFilterChange={onFilter ? onFilter : undefined}
              />
            )}
          </div>
        </div>
      )}
      <table className="c-table__table">
        <Head {...{ headers, ...(slotProps?.head || {}) }}>
          <tr className="c-table__tr">
            {headers.map(column => (
              <th className="c-table__td" key={column.title}>
                <span className="c-table__text-head">{column.title}</span>
              </th>
            ))}
          </tr>
        </Head>

        <tbody>
          {rows.map((row, index) => {
            return (
              <React.Fragment key={index}>
                {RowBefore && (
                  <RowBefore
                    {...{
                      item: items[index],
                      index,
                      ...(slotProps?.rowBefore || {}),
                    }}
                  />
                )}
                <tr
                  className={`c-table__tr ${index % 2 === 0 ? 'is-even' : ''}`}
                  onClick={() =>
                    onRowClicked ? onRowClicked(items[index], index) : undefined
                  }>
                  {row.map((column, columnIndex) => (
                    <td
                      className="c-table__td"
                      key={columnIndex}
                      onClick={() =>
                        onRowClicked
                          ? onRowClicked(items[index], index, columnIndex)
                          : undefined
                      }>
                      <div className="c-table__text">{column}</div>
                    </td>
                  ))}
                </tr>
                {RowAfter && (
                  <RowAfter
                    {...{
                      item: items[index],
                      index,
                      ...(slotProps?.rowAfter || {}),
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
          {rows.length === 0 && (
            <tr>
              <td className="c-table__empty" colSpan={headers.length}>
                <span>No items found</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {(showPagination) && (
        <div className="transfer-contact-list-list_end">
          <PaginationBoardInTable
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              rowsPerPage={limit}
              totalItems={total}
              currentPage={page}
              totalPages={totalPages}
            />
        </div>
      )}
    </div>
  );
};

export default AsyncTable;
