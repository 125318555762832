import { FC } from 'react';
import classnames from 'classnames';
import './statusStyles.scss';

export interface IStatusProps {
  statusOfComponent:
    | 'Active'
    | 'Retired'
    | 'Removed'
    | 'Inactive'
    | 'Deposited'
    | 'Expired'
    | 'Suspended'
    | 'Closed'
    | 'Withdrawn'
    | 'Disconnected'
    | 'Pending'
    | 'Denied'
    | 'Approved'
    | 'Transit'
    | 'Merged'
    | 'Transcribed'
    | 'Failed'
    | 'Split'
    | '';
}

const Status: FC<IStatusProps> = ({ statusOfComponent }) => {
  const statusClassNames = {
    Active: 'active',
    Deposited: 'deposited',
    Retired: 'retired',
    Removed: 'removed',
    Inactive: 'inactive',
    Expired: 'inactive',
    Suspended: 'suspended',
    Closed: 'closed',
    Withdrawn: 'closed',
    Disconnected: 'disconnected',
    Pending: 'pending',
    Approved: 'approved',
    Denied: 'denied',
    Transit: 'transit',
    Merged: 'merged',
    Transcribed: 'transcribed',
    Failed: 'failed',
    Split: 'split',
    '': '',
  };

  const containerClassName = classnames(
    'status-account--wrapper',
    statusClassNames[statusOfComponent],
  );
  const spanClassName = classnames(
    'status-account--text',
    statusClassNames[statusOfComponent],
  );

  return (
    <div className={containerClassName}>
      <span className={spanClassName}>{statusOfComponent}</span>
    </div>
  );
};

export default Status;
