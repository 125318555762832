import React from 'react';
import { ContactUs } from '../../../ContactUs/ContactUs';
import { UserMenu } from '../UserMenu/UserMenu';
import { BaseButton } from '../../../Buttons/BaseButton/BaseButton';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { UserRole } from '@prisma/client';
import { isInTypes } from '../../../../../helpers/types';

export const HeaderActions: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <div className="main-header__actions">
      {(!user ||
        (user && !isInTypes(user!.role, [UserRole.Admin, UserRole.SuperAdmin]))) && (
        <ContactUs />
      )}
      {user && <UserMenu />}
      {!user && (
        <>
          <BaseButton link={'/registration'} active={true}>
            Sign up
          </BaseButton>
          <BaseButton className="main-header__actions-login" link={'/login'}>
            Log in
          </BaseButton>
        </>
      )}
    </div>
  );
};
