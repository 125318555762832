import { FC, useState, ChangeEvent, useMemo } from 'react';
import { useFormik } from 'formik';
import './BlendAccountFormStyles.scss';
import { MyFormValues } from './types';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import FormTitle from '../../FormTitle/FormTitle';
import Field from '../../Field/Field';
import { COUNTRY } from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setField } from '../../../../../../store/reducers/user/bcuFormReducer';
import { selectUser } from '../../../../../../store/reducers/user/userReducer';
import {
  handleEnterLatinAndNumbers,
  handleEnterLatinLetters,
} from '../../../../../../helpers/handleKeyPress';
import { DefaultSelect } from '../../../../Form/DefaultSelect/DefaultSelect';

interface IBlendAccountFormProps {
  handleClick: () => void;
}

const BlendAccountForm: FC<IBlendAccountFormProps> = ({ handleClick }) => {
  const [sameAddress, setSameAddress] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const formValues = useAppSelector(state => state.form);
  const [errorVisible, setErrorVisible] = useState(false);

  const handleValidation = (values: any) => {
    const errors = {} as any;

    if (values.blendFieldName.length === 0) {
      errors.blendFieldName = 'Field Name is required';
    } else if (values.blendFieldName.length < 2) {
      errors.blendFieldName = 'Field Name must have at least 2 characters';
    }
    if (!values.blendFieldStreet1) {
      errors.blendFieldStreet1 = 'Field Street address is required';
    }
    if (!values.blendFieldStreet2) {
      errors.blendFieldStreet2 = 'Field Street address line 2 is required';
    }
    if (!values.blendFieldCountry) {
      errors.blendFieldCountry = 'Field Country is required';
    }
    if (!values.blendFieldPost || values.blendFieldPost.length < 3) {
      errors.blendFieldPost = 'Field must have at least 3 characters';
    }
    if (!values.blendFieldCity) {
      errors.blendFieldCity = 'Field City is required';
    }

    formik.setErrors(errors);

    return errors;
  };

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });

  const accountAddress = useMemo(
    () => ({
      blendFieldName: user?.company?.name || '',
      blendFieldStreet1: user?.company?.address || '',
      blendFieldStreet2: user?.company?.addressNumber || '',
      blendFieldCountry: user?.company?.country || '',
      blendFieldPost: user?.company?.zip || '',
      blendFieldCity: user?.company?.city || '',
    }),
    [user],
  );

  const {
    fieldName,
    fieldStreet1,
    fieldStreet2,
    fieldCountry,
    fieldPost,
    fieldCity,
    radioButton,
  } = formValues.fields;
  const selfProductionAddress = useMemo(
    () => ({
      blendFieldName: fieldName,
      blendFieldStreet1: fieldStreet1,
      blendFieldStreet2: fieldStreet2,
      blendFieldCountry: fieldCountry,
      blendFieldPost: fieldPost,
      blendFieldCity: fieldCity,
    }),
    [fieldName, fieldStreet1, fieldStreet2, fieldCountry, fieldPost, fieldCity],
  );

  const handleFieldChange = (field: keyof MyFormValues, value: string) => {
    dispatch(setField({ fieldName: field, value: value }));
    formik.setFieldValue(field, value);
  };

  const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === 'same as account address') {
      setSameAddress(true);
      handleFieldChange('blendRadioButton', value);

      dispatch(
        setField({
          fieldName: 'blendFieldName',
          value: accountAddress.blendFieldName,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldStreet1',
          value: accountAddress.blendFieldStreet1,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldStreet2',
          value: accountAddress.blendFieldStreet2,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldCountry',
          value: accountAddress.blendFieldCountry,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldPost',
          value: accountAddress.blendFieldPost,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldCity',
          value: accountAddress.blendFieldCity,
        }),
      );

      formik.setFieldValue('blendFieldName', accountAddress.blendFieldName);
      formik.setFieldValue(
        'blendFieldStreet1',
        accountAddress.blendFieldStreet1,
      );
      formik.setFieldValue(
        'blendFieldStreet2',
        accountAddress.blendFieldStreet2,
      );
      formik.setFieldValue(
        'blendFieldCountry',
        accountAddress.blendFieldCountry,
      );
      formik.setFieldValue('blendFieldPost', accountAddress.blendFieldPost);
      formik.setFieldValue('blendFieldCity', accountAddress.blendFieldCity);
    } else if (value === 'same as self-production address') {
      setSameAddress(false);
      handleFieldChange('blendRadioButton', value);

      dispatch(
        setField({
          fieldName: 'blendFieldName',
          value: selfProductionAddress.blendFieldName,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldStreet1',
          value: selfProductionAddress.blendFieldStreet1,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldStreet2',
          value: selfProductionAddress.blendFieldStreet2,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldCountry',
          value: selfProductionAddress.blendFieldCountry,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldPost',
          value: selfProductionAddress.blendFieldPost,
        }),
      );
      dispatch(
        setField({
          fieldName: 'blendFieldCity',
          value: selfProductionAddress.blendFieldCity,
        }),
      );

      formik.setFieldValue(
        'blendFieldName',
        selfProductionAddress.blendFieldName,
      );
      formik.setFieldValue(
        'blendFieldStreet1',
        selfProductionAddress.blendFieldStreet1,
      );
      formik.setFieldValue(
        'blendFieldStreet2',
        selfProductionAddress.blendFieldStreet2,
      );
      formik.setFieldValue(
        'blendFieldCountry',
        selfProductionAddress.blendFieldCountry,
      );
      formik.setFieldValue(
        'blendFieldPost',
        selfProductionAddress.blendFieldPost,
      );
      formik.setFieldValue(
        'blendFieldCity',
        selfProductionAddress.blendFieldCity,
      );
    } else {
      setSameAddress(false);
      handleFieldChange('blendRadioButton', value);

      [
        'blendFieldName',
        'blendFieldStreet1',
        'blendFieldStreet2',
        'blendFieldCountry',
        'blendFieldPost',
        'blendFieldCity',
      ].forEach(field => {
        dispatch(
          setField({ fieldName: field as keyof MyFormValues, value: '' }),
        );
        formik.setFieldValue(field, '');
      });
    }
  };

  const isRequiredFieldEmpty = () => {
    const requiredFields: Array<keyof MyFormValues> = [
      'blendFieldName',
      'blendFieldStreet1',
      'blendFieldCountry',
      'blendFieldPost',
      'blendFieldCity',
    ];

    return requiredFields.some(field => !formik.values[field]);
  };

  const handleFormClick = () => {
    const hasEmptyRequiredFields = isRequiredFieldEmpty();
    handleValidation(formik.values);

    setErrorVisible(hasEmptyRequiredFields);
    if (!hasEmptyRequiredFields) {
      handleClick();
    }
  };
  const isSupplier = radioButton === 'Supplier';

  return (
    <div>
      <FormTitle title="3. Blending site information" />
      <form onSubmit={formik.handleSubmit}>
        <div className="first-2">
          <div className="first">
            <label className="rad-1">
              <input
                type="radio"
                name="radioButton"
                value="same as account address"
                checked={
                  formik.values.blendRadioButton === 'same as account address'
                }
                onChange={e => handleRadioButtonChange(e)}
              />
              <span className="input">same as account address</span>
            </label>
          </div>
          {!isSupplier && (
            <div className="first">
              <label className="rad-1">
                <input
                  type="radio"
                  name="radioButton"
                  value="same as self-production address"
                  checked={
                    formik.values.blendRadioButton ===
                    'same as self-production address'
                  }
                  onChange={e => handleRadioButtonChange(e)}
                />
                <span className="input">same as self-production address</span>
              </label>
            </div>
          )}
          <div className="first">
            <label className="rad-1">
              <input
                type="radio"
                name="radioButton"
                value="new address"
                checked={formik.values.blendRadioButton === 'new address'}
                onChange={e => handleRadioButtonChange(e)}
              />
              <span className="input">new address</span>
            </label>
          </div>
          {formik.errors.blendRadioButton &&
            formik.touched.blendRadioButton && (
              <div>{formik.errors.blendRadioButton}</div>
            )}
        </div>

        <Field
          handleKeyPress={handleEnterLatinLetters}
          required
          label="Name"
          placeholder="Name"
          id="fieldName"
          value={formik.values.blendFieldName}
          onChange={e => handleFieldChange('blendFieldName', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.blendFieldName}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          required
          label="Street address"
          placeholder="Street address"
          id="fieldStreet1"
          value={formik.values.blendFieldStreet1}
          onChange={e => handleFieldChange('blendFieldStreet1', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.blendFieldStreet1}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="Street address line 2"
          placeholder="Street address line 2"
          id="fieldStreet2"
          value={formik.values.blendFieldStreet2}
          onChange={e => handleFieldChange('blendFieldStreet2', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.blendFieldStreet2}
          errorVisible={errorVisible}
        />

        <div className="field-cp">
          <Field
            handleKeyPress={handleEnterLatinAndNumbers}
            required
            label="Post Code"
            placeholder="Post Code"
            id="fieldPost"
            value={formik.values.blendFieldPost}
            onChange={e => handleFieldChange('blendFieldPost', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.blendFieldPost}
            errorVisible={errorVisible}
          />

          <Field
            handleKeyPress={handleEnterLatinLetters}
            required
            label="City"
            placeholder="City"
            id="fieldCity"
            value={formik.values.blendFieldCity}
            onChange={e => handleFieldChange('blendFieldCity', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.blendFieldCity}
            errorVisible={errorVisible}
          />
        </div>

        <DefaultSelect
          required
          isSearchable
          inputId="fieldCountry"
          name="fieldCountry"
          label="Country"
          placeholder="Country"
          error={formik.errors.blendFieldCountry}
          options={COUNTRY as any}
          value={formik.values.blendFieldCountry}
          onChange={(option: any) => {
            handleFieldChange('blendFieldCountry', option.value);
          }}
        />

        <NextButton handleClick={handleFormClick} text="Next step" />
      </form>
    </div>
  );
};

export default BlendAccountForm;
