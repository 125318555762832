import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Input } from '../components/Form/Input';
import { Phone, PhoneValue } from '../components/Form/Phone';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';

import './ContactUsStyle.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { useFormActions } from '../components/Form/FormHook';
import { validate } from './models/valiadate.constant';
import { fetchContactUs, fetchContactUsAuth } from '../../API/contact-us';
import { STATEMENTS } from '../../structure/routes/routes';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../hooks/useNotification';

export const ContactUs: React.FC = () => {
  const toast = useNotification();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    companyName: user?.company.name,
    position: user?.position,
    email: user?.email,
    cellPhone: user?.cellPhone,
    message: '',
  });
  const disabled = !!user;
  const createOnChange =
    (field: string) =>
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | PhoneValue,
    ) => {
      setFormData({
        ...formData,
        [field]: 'target' in event ? event.target.value : event,
      });
    };

  const { showErrorsBlock, loading, onSubmit } = useFormActions({
    validate: validate(formData),
    transformAndDispatchData: () => {
      return dispatch(
        user ? fetchContactUsAuth(formData) : fetchContactUs(formData),
      ).then(() => {
        toast.success({ message: 'Success', description: 'Your message has been sent' });

        navigate(STATEMENTS);
      });
    },
  });

  return (
    <div className="contact-us-page">
      <form onSubmit={onSubmit}>
        <h2>How can we help you?</h2>
        <div className="contact-us-group">
          <Row>
            <Col>
              <Input
                label="Name"
                placeholder="Name"
                name="firstname"
                required
                value={formData.firstName || ''}
                onChange={createOnChange('firstname')}
                error={showErrorsBlock['firstname']}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Input
                label="Surname"
                placeholder="Surname"
                name="lastname"
                value={formData.lastName || ''}
                required
                onChange={createOnChange('lastname')}
                error={showErrorsBlock['lastname']}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Company"
                placeholder="Company"
                name="companyName"
                required
                value={formData.companyName}
                error={showErrorsBlock['companyName']}
                onChange={createOnChange('companyName')}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Input
                label="Position"
                placeholder="Position"
                name="position"
                value={formData.position || undefined}
                required
                onChange={createOnChange('position')}
                error={showErrorsBlock['position']}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Email"
                placeholder="Email"
                name="email"
                required
                value={formData.email}
                onChange={createOnChange('email')}
                error={showErrorsBlock['email']}
                disabled={disabled}
              />
            </Col>
            <Col>
              <Phone
                label="Phone number"
                placeholder="Phone number"
                name="phone"
                required
                value={formData.cellPhone as any}
                onChange={createOnChange('cellPhone')}
                error={
                  showErrorsBlock['phone_number'] ||
                  showErrorsBlock['phone_code']
                }
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <textarea
                rows={15}
                placeholder="Your message *"
                name="message"
                value={formData.message}
                onChange={createOnChange('message')}
              />
              {showErrorsBlock['message'] && (
                <div className="error">{showErrorsBlock['message']}</div>
              )}
            </Col>
          </Row>
        </div>
        <div className="action-buttons">
          <BaseButtonSubmit active lock={loading} loading={loading}>
            Submit
          </BaseButtonSubmit>
        </div>
      </form>
    </div>
  );
};
