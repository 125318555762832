import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Prisma } from '@prisma/client';

const initialState = {
  status: 'idle',
  credit: null,
  credits: [],
  incomingCredits: [],
};

const creditsReducer = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    setCredits: (state, action) => {
      state.credits = action.payload;
    },
    setIncomingCredits: (state, action) => {
      state.incomingCredits = action.payload;
    },
    setDetailCredit: (state, action) => {
      state.credit = action.payload;
    },
  },
});

export const { setCredits, setDetailCredit, setIncomingCredits } =
  creditsReducer.actions;

export type Transaction = Prisma.CreditTransactionGetPayload<{ include: { credit: true, newCredit: true, company: true, sender: true  }}>;

export const selectCredits = (state: RootState): any[] => state.credits.credits;
export const selectIncomingCredits = (state: RootState): Transaction[] =>
  state.credits.incomingCredits;
export const selectCreditStatus = (state: RootState): string =>
  state.credits.status;
export const selectCreditDetail = (state: RootState): any =>
  state.credits.credit;

export default creditsReducer.reducer;
