import React from 'react';
import {  useAppSelector } from '../../../../../store/hooks';
import { ACCOUNT_USERS_TITLE } from '../models/account.constant';
import { selectedRow } from '../../../../../store/reducers/admin/adminReducer';
import { List } from '../../../../components/List';
import { UserType } from '../../../../../structure/models/user/user';
import StatusComponent from '../../../../components/StatusComponent';

export const AccountUserInfo: React.FC = () => {
  const account = useAppSelector(selectedRow);
  const users = account.users.map((user: any) => ({
    ...user,
    phone: user.cellPhone ? `${user.cellPhone?.code || ''}${user.cellPhone?.number || ''}` : '',
    name: user.firstName || user.lastName ? `${user.firstName || ''} ${user.lastName || ''}` : '',
    role: user.type === UserType.Primary ? 'Account owner' : 'User',
    status: <StatusComponent statusOfComponent={user.status} />,
  }));

  return (
    <div className="account-detail__table">
      <List titles={ACCOUNT_USERS_TITLE} data={users} />
    </div>
  );
};
