import React, { useEffect, useMemo, useState } from 'react';
import { Prisma, UserStatus } from '@prisma/client';
import { useFormik } from 'formik';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, TablePaginationConfig } from 'antd';
import {
  ColumnType,
  FilterValue,
  SortOrder,
  SorterResult,
} from 'antd/es/table/interface';

import {
  useDeleteContactMutation,
  useGetContactListQuery,
  useUpdateContactListMutation,
} from '../../../API';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useNotification } from '../../../hooks/useNotification';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { COMPANY_TYPE_NAMES } from '../../../structure/models/company/company';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import Status from '../StatusComponent';

import './style.scss';

export type Contact = Prisma.ContactGetPayload<{
  include: {
    to: {
      select: {
        id: true;
        name: true;
        type: true;
        country: true;
        status: true;
      };
    };
  };
}>;

export const getContactColumns = (
  onRemove: (id: string) => void,
  isUserSuspended: boolean,
): ColumnType<Contact>[] => {
  const onItemRemove = (
    id: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    onRemove(id);
  };

  return [
    {
      title: 'Company name',
      // keyItem: 'name',
      render: item => item.to?.name,
    },
    {
      title: 'Country',
      // keyItem: 'country',
      render: item => item.to?.country,
    },
    {
      title: 'Company type',
      // keyItem: 'type',
      render: item => COMPANY_TYPE_NAMES[item.to?.type],
    },
    {
      title: 'Account status',
      // keyItem: 'status',
      render: (item: Contact) => <Status statusOfComponent={item.to?.status} />,
    },
    {
      title: 'Actions',
      // keyItem: 'status',
      render: item => (
        <button
          disabled={isUserSuspended}
          title="Remove contact"
          style={{
            border: 'none',
            outline: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
          onClick={e => onItemRemove(item.to?.id?.toString(), e)}>
          <span
            className="material-icons-outlined"
            style={{ fontSize: '18px' }}>
            delete
          </span>
        </button>
      ),
    },
  ];
};

type ContactFormValues = {
  token: string;
};

interface TableParams {
  pagination?: TablePaginationConfig;
  order?: SortOrder;
  field?: React.Key | readonly React.Key[];
  filters?: Record<string, FilterValue | null>;
}

const ContactList: React.FC = React.memo(() => {
  const toast = useNotification();
  const { data } = useGetContactListQuery();
  const user = useAppSelector(selectUser);

  const isUserSuspended = useMemo(
    () => user?.status === UserStatus.Suspended,
    [user],
  );

  const [updateContactList] = useUpdateContactListMutation();
  const [deleteContactFromList] = useDeleteContactMutation();

  const [items, setItems] = useState((data || []) as Contact[]);
  const [idValueForRemoving, setIdValueForRemoving] = useState<string | null>(
    null,
  );

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 50,
      position: ['bottomRight'],
    },
    field: 'createdAt',
    order: 'ascend',
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Contact> | SorterResult<Contact>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  const form = useFormik({
    initialValues: {
      token: '',
    },
    onSubmit: async values => {
      // We want to omit the form validation
      // in this case, so just triggering toasts manually
      if (!values.token) {
        toast.error({
          message: 'Error',
          description: 'Token field is required',
        });
        return;
      }

      const result: any = await updateContactList({
        data: { token: values.token },
      });

      handleFieldChange('token', '');

      if (result.error) {
        if (result.error?.code === 412) {
          return toast.success({
            message: result?.error?.message ? 'Error' : 'Success',
            description: `${result?.error?.message || ''}`,
          });
        }

        return toast.error({
          message: 'Error',
          description: result?.error?.message || 'Unknown error',
        });
      }

      toast.success({
        message: 'Success',
        description: 'Company has been added',
      });
    },
  });

  const handleFieldChange = (field: keyof ContactFormValues, value: string) => {
    form.setFieldValue(field, value);
  };

  const handleSearch = (query: string) => {
    if (query === '') {
      return setItems(data);
    }

    setItems(
      (data as Contact[]).filter((item, index) => {
        return `${item.to?.name} ${item.to?.country} ${item.to?.type} ${item.to?.status}`
          .toLowerCase()
          .includes(query.toLowerCase());
      }),
    );
  };

  const handleRemove = (id: string) => {
    setIdValueForRemoving(id);
  };

  const onRemoveItem = async (value: boolean) => {
    if (value) {
      await deleteContactFromList({ id: idValueForRemoving });
    }
    setIdValueForRemoving(null);
  };

  const columns = getContactColumns(handleRemove, isUserSuspended);

  return (
    <div className="contact-list-wrapper">
      <div className="contact-list-token">
        <div className="contact-list-token_header">Contact List</div>
        <div className="contact-list-token_body">
          <Input
            id="field-contact-list-token"
            placeholder="Token"
            size="large"
            required
            value={form.values.token}
            onChange={e => handleFieldChange('token', e.target.value)}
          />
          <Button
            icon={<PlusOutlined />}
            shape="round"
            size="large"
            type="primary"
            color="primary"
            onClick={form.submitForm}
            disabled={isUserSuspended}
            style={{ minWidth: '155px' }}>
            Add contact
          </Button>
        </div>
      </div>

      <div className="contact-list-table">
        <Input
          suffix={<SearchOutlined />}
          className="contact-list-search"
          size="large"
          placeholder="Search"
          onChange={e => handleSearch(e.target.value)}
          allowClear
        />
        <BaseTable
          className="has-striped-rows"
          rowKey={record => record.toId || ''}
          dataSource={items || []}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          columns={columns}
        />
      </div>
      <ConfirmationPrompt
        onClosed={onRemoveItem}
        show={Boolean(idValueForRemoving)}>
        <p>Are you sure you want to delete this contact?</p>
      </ConfirmationPrompt>
    </div>
  );
});

export default ContactList;
