import { useMemo, useState, ReactNode } from 'react';
import { CreditOption } from '../Transfer';
import Select, { DefaultOptionType } from 'antd/es/select';
import Field from '../../components/RegistarationForm/components/Field/Field';

export const AmountSelectorOptions: {
  [key: string]: { label: string | ReactNode; value: string };
} = {
  BCU: {
    label: 'BCU',
    value: 'BCU',
  },
  CO2: {
    label: (
      <span>
        t CO<sub>2</sub>eq
      </span>
    ),
    value: 'CO2eq',
  },
  MJ: { label: 'MJ', value: 'MJ' },
};

export const useAmountSelector = (
  selectedBCU: CreditOption | undefined,
  form: any,
  fieldKey = 'volume',
  labelText = 'Value to be transferred',
) => {
  const [amountType, setAmountType] = useState(AmountSelectorOptions.BCU.value);

  const formValueField = useMemo(
    () => form.values[fieldKey],
    [form.values, fieldKey],
  );

  const maxValue = useMemo(() => {
    if (!selectedBCU) {
      return '0';
    }

    switch (amountType) {
      case AmountSelectorOptions.BCU.value:
        return parseFloat(selectedBCU.credit.volume || '0').toLocaleString(
          'en-US',
          {
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          },
        );
      case AmountSelectorOptions.CO2.value:
        return parseFloat(
          selectedBCU.credit.tonnesOfCO2eq || '0',
        ).toLocaleString('en-US', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        });
      case AmountSelectorOptions.MJ.value:
        return Math.round(
          selectedBCU.credit.neatAmountInMJ || '0',
        ).toLocaleString('en-US', {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        });
      default:
        return '0';
    }
  }, [selectedBCU, amountType]);

  const amountSelectorItems: DefaultOptionType[] = useMemo(
    () => [
      {
        value: AmountSelectorOptions.BCU.value,
        label: <span>{AmountSelectorOptions.BCU.label}</span>,
      },
      {
        value: AmountSelectorOptions.CO2.value,
        label: AmountSelectorOptions.CO2.label,
      },
      {
        value: AmountSelectorOptions.MJ.value,
        label: <span>{AmountSelectorOptions.MJ.label}</span>,
      },
    ],
    [],
  );

  const ratio = useMemo(() => {
    return (
      parseFloat(formValueField.replaceAll(',', '') || '0') /
        parseFloat(maxValue.replaceAll(',', '')) || 1
    );
  }, [maxValue, formValueField]);

  const amountMJ = useMemo(() => {
    if (!selectedBCU) {
      return '0';
    }

    return (
      ratio * parseFloat(selectedBCU.credit.neatAmountInMJ || '0')
    ).toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }, [ratio, selectedBCU]);

  const amountCO2 = useMemo(() => {
    if (!selectedBCU) {
      return '0';
    }

    return (
      ratio * parseFloat(selectedBCU.credit.tonnesOfCO2eq || '0')
    ).toLocaleString('en-US', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3,
    });
  }, [ratio, selectedBCU]);

  const amountBCU = useMemo(() => {
    if (!selectedBCU) {
      return '0';
    }

    return (
      ratio * parseFloat(selectedBCU.credit.volume || '0')
    ).toLocaleString('en-US', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3,
    });
  }, [ratio, selectedBCU]);

  const amountSelector = useMemo(() => {
    const label = selectedBCU && (
      <div style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
        <div>{`max ${maxValue}`}</div>
        <div>
          <Select
            size="small"
            popupMatchSelectWidth={false}
            defaultValue={AmountSelectorOptions.BCU.value}
            options={amountSelectorItems}
            onChange={setAmountType}
          />
        </div>
      </div>
    );

    const fieldAfter = selectedBCU && (
      <small
        style={{
          display: 'inline-flex',
          width: '100%',
          gap: '5px',
          justifyContent: 'end',
          alignItems: 'end',
          padding: '5px',
        }}>
        {amountType !== AmountSelectorOptions.BCU.value && (
          <span>BCU: {amountBCU}</span>
        )}
        {amountType !== AmountSelectorOptions.CO2.value && (
          <span>
            t CO<sub>2</sub>eq reduction: {amountCO2}
          </span>
        )}
        {amountType !== AmountSelectorOptions.MJ.value && (
          <span>MJ: {amountMJ}</span>
        )}
      </small>
    );

    switch (amountType) {
      case AmountSelectorOptions.BCU.value:
        return (
          <Field
            id="volume"
            type="string"
            min={0}
            max={selectedBCU ? selectedBCU.credit.volume : undefined}
            step={0.001}
            formatOnBlur
            decimals={3}
            label={labelText}
            label2={label}
            labelStyles={{ marginBottom: '6px' }}
            fieldAfter={fieldAfter}
            error={form.errors[fieldKey]}
            errorVisible={true}
            required
            onChange={e => form.setFieldValue(fieldKey, e.target.value)}
            value={form.values[fieldKey]}
          />
        );
      case AmountSelectorOptions.CO2.value:
        return (
          <Field
            id="volume"
            type="string"
            min={0}
            decimals={3}
            formatOnBlur
            step={0.001}
            label={labelText}
            label2={label}
            labelStyles={{ marginBottom: '6px' }}
            fieldAfter={fieldAfter}
            error={form.errors[fieldKey]}
            errorVisible={true}
            required
            onChange={e => form.setFieldValue(fieldKey, e.target.value)}
            value={form.values[fieldKey]}
          />
        );
      case AmountSelectorOptions.MJ.value:
        return (
          <Field
            id="volume"
            type="string"
            decimals={0}
            min={0}
            formatOnBlur
            step={1}
            label={labelText}
            label2={label}
            labelStyles={{ marginBottom: '6px' }}
            fieldAfter={fieldAfter}
            error={form.errors[fieldKey]}
            errorVisible={true}
            required
            onChange={e => form.setFieldValue(fieldKey, e.target.value)}
            value={form.values[fieldKey]}
          />
        );
      default:
        return null;
    }
  }, [amountType, form, selectedBCU, maxValue, fieldKey, amountSelectorItems]);

  return {
    amountType,
    amountSelector,
  };
};
