import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectedRow } from '../../../../../store/reducers/admin/adminReducer';
import { fetchAdminTransactions } from '../../../../../API/admin/accounts';
import { transactionTypes } from '../Transactions/models/columns.constant';
import { selectTransactions } from '../../../../../store/reducers/user/transactionsReducer';
import { ColumnType } from 'antd/lib/table';
import {
  DATE_WITH_FULL_MONTH,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { TablePaginationConfig, Tag } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Transaction } from '../../../../../structure/models/transaction/transaction';
import { SortOrder } from 'antd/es/table/interface';
import { ArrowSortIcon } from '../../../../components/ArrowSortIcon/ArrowSortIcon';
import { BaseTable } from '../../../../components/BaseTable/BaseTable';
import { TransactionStatus } from '@prisma/client';

interface TableParams {
  pagination?: TablePaginationConfig;
  order?: SortOrder;
  field?: React.Key | readonly React.Key[];
  filters?: Record<string, FilterValue | null>;
}

export const AccountHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectedRow);
  const transactions = useAppSelector(selectTransactions);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 50,
    },
    field: 'approveDate',
    order: 'ascend',
  });
  const tableParamsString = JSON.stringify(tableParams);

  useEffect(() => {
    if (company?.id) {
      dispatch(
        fetchAdminTransactions({
          id: company?.id,
          page: tableParams.pagination?.current,
          limit: tableParams.pagination?.pageSize || 50,
          sortBy: tableParams.field,
          sortOrder: tableParams.order === 'ascend' ? 'asc' : 'desc',
        }),
      );
    }
  }, [company?.id, tableParamsString]);

  useEffect(() => {
    setTableParams(t => ({
      ...t,
      pagination: {
        ...t.pagination,
        total: transactions?.count || 0,
      },
    }));
  }, [transactions]);

  const columns: ColumnType<any>[] = [
    {
      title: 'Date and time',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortIcon: ({ sortOrder }) => <ArrowSortIcon sortOrder={sortOrder} />,
      dataIndex: 'approveDate',
      render: (_: any, item: any) => {
        const date = item?.approveDate || item?.updatedAt || item?.createdAt;
        return <>{date ? formatDate(date, DATE_WITH_FULL_MONTH) : ''}</>;
      },
    },
    {
      title: 'From/by',
      dataIndex: 'fromBy',
      render: (_: any, item: any) => <>{item?.company?.name}</>,
    },
    {
      title: 'To',
      dataIndex: 'to',
      render: (_: any, item: any) => <>{item?.receiver?.name}</>,
    },
    {
      title: 'BCU ID',
      dataIndex: 'BCUID',
      render: (_: any, item: any) => (
        <>{item?.newCredit?.BCUID || item?.credit?.BCUID}</>
      ),
    },
    {
      title: 'User',
      dataIndex: 'user',
      render: (_: any, item: any) => (
        <>
          {item?.sender && (
            <>
              {item?.sender?.firstName} {item?.sender?.lastName}
            </>
          )}
          {!item?.sender && <>-</>}
        </>
      ),
    },
    {
      title: 'Transaction type',
      dataIndex: 'type',
      render: (v: keyof typeof transactionTypes) => transactionTypes[v],
    },
    {
      title: 'Volume',
      dataIndex: 'amount',
      render: (_: any, item: any) => {
        return (
          <div className="admin-transactions__info">
            <div>
              {parseFloat(item?.amount || '0').toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}{' '}
              BCU
            </div>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, item: any) => (
        <Tag
          bordered={false}
          color={
            item?.status === TransactionStatus.Approved ? 'green' : 'default'
          }>
          {item?.status}
        </Tag>
      ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Transaction> | SorterResult<Transaction>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <div style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
      <BaseTable
        className="has-striped-rows"
        rowKey={record => record.id || ''}
        dataSource={transactions?.results || []}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        columns={columns}
      />
    </div>
  );
};
