import { Company } from '../company/company';
import { PhoneValue } from '../../../app/components/Form/Phone';
import { UserRole as Role, UserStatus, UserType } from '@prisma/client';
export { UserRole as Role, UserStatus, UserType } from '@prisma/client';

export const UserStatusOptions = [{ value: null, label: 'All' }, ...Object.values(UserStatus).map(v => ({
  label: v,
  value: v,
}))];

export interface AccountFields {
  companyName: string;
  accountType: string;
  inviteToken: string;
  registrationNumber: string;
  registrationIdentification: string;
  rsb: string;
  certificateStartDate: string;
  certificateEndDate: string;
  streetAddress: string;
  streetAddressLineTwo?: string;
  zip: string;
  city: string;
  country: string;
  companyPhone_code: string;
  companyPhone_number: string;
  userPhone_code: string;
  userPhone_number: string;
  website?: string;
  name: string;
  surname: string;
  email: string;
  position: string;
  agreement: boolean;
}
export interface BasedUserFields {
  name: string;
  surname: string;
  position: string;
  phone_code: string;
  phone_number: string;
  agreement: boolean;
  password: string;
  confirmPassword: string;
}

export interface User {
  id: string;
  company: Company;
  role: Role;
  SUID: string;
  firstName: string;
  lastName: string;
  position: string;
  cellPhone: PhoneValue;
  email: string;
  parent?: User | number;
  type: UserType;
  isEmailConfirmed: boolean;
  isAdminApproved: boolean;
  status: UserStatus;
}

export interface Account {
  companyName: string;
  accountType: string;
  token?: string;
  inviteToken: string;
  registrationNumber: string;
  registrationIdentification: string;
  rsb: string;
  certificateStartDate: string;
  certificateEndDate: string;
  streetAddress: string;
  streetAddressLineTwo: string;
  ZIP: string;
  city: string;
  country: string;
  companyPhone: PhoneValue;
  website: string;
  name: string;
  surname: string;
  email: string;
  userPhone: PhoneValue;
  position: string;
  password: string;
  agreement: boolean;
}

export interface AccountSupplier extends Account {
  rsb: string;
  certificateStartDate: string;
  certificateEndDate: string;
}

export interface AccountGeneral extends Account {
  token: string;
}
