import React, { CSSProperties, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Collapse } from 'react-bootstrap';
import { Table } from '../Table/Table';
import classNames from 'classnames';
import { ViewElement } from '../ViewElement/ViewElement';
import { COLUMNS } from './models/columns.constant';
import {
  selectCredits,
  setDetailCredit,
} from '../../../store/reducers/user/creditsReducer';
import { Company } from '../../../structure/models/company/company';
import './UserBalanceStyle.scss';
import { limitOfNumbers } from '../../../helpers/limitOfNumbers';
import { selectUserAccountData } from '../../../store/reducers/user/userReducer';
import { fetchCompanyInfo } from '../../../API/company';
import { BalanceContext } from '../../../contexts/balance.context';

interface Props {
  company: Company;
  className?: string;
  style?: CSSProperties | undefined;
}

export const UserBalance: React.FC<Props> = React.memo(
  ({ company, className, style }) => {
    const { isOpened, setIsOpened } = useContext(BalanceContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const credits = useAppSelector(selectCredits);
    const account = useAppSelector(selectUserAccountData);

    if (!account) {
      dispatch(fetchCompanyInfo());
    }

    const toggleCollapse = () => setIsOpened(!isOpened);

    const onRowClick = (item: any) => {
      dispatch(setDetailCredit(item));
      navigate(`/bcu/${item.id}/bcu-info`);
    };

    const balance = account?.balance ? limitOfNumbers(account.balance) : 0;

    return (
      <div className={classNames('user-balance', className)} style={style}>
        <div
          className={classNames('user-balance__header', {
            opened: isOpened,
          })}
          onClick={toggleCollapse}
          aria-controls="user-balance-credits"
          aria-expanded={isOpened}>
          <div className="user-balance__header__info">
            <ViewElement label="Company" description={company.name} />
            <ViewElement
              label="Total Balance"
              description={`${balance.toLocaleString()} BCU`}
            />
          </div>
          <div className="user-balance__header__arrow">
            <span className="material-icons-outlined">
              {`keyboard_arrow_${isOpened ? 'up' : 'down'}`}
            </span>
          </div>
        </div>
        <Collapse className="user-balance__body" in={isOpened}>
          <div id="user-balance-credits">
            <Table
              columns={COLUMNS}
              data={credits}
              rowKey={item => item.id}
              onRowClick={onRowClick}
              showHeader
            />
          </div>
        </Collapse>
      </div>
    );
  },
);
