import React, { useEffect } from 'react';
import { ChangePasswordForm } from './ChangePasswordForm';
import { fetchResetPassword } from '../../API/login';
import { DASHBOARD } from '../../structure/routes/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotification } from '../../hooks/useNotification';

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const toast = useNotification();

  useEffect(() => {
    if (!token) {
      toast.error({ message: 'Error', description: 'Token is required.' });
      navigate(DASHBOARD);
    }
  });

  return (
    <ChangePasswordForm isReset fetchData={fetchResetPassword} token={token!} />
  );
};
