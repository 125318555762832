import React from 'react';

export const TransferInfoEntry: React.FC<{
    label: string;
    value?: string;
    icon?: string;
    iconClass?: string;
}> = ({value, label, icon, iconClass}) => (
    <div className="c-transfer-info__entry">
        <p className="c-transfer-info__entry__label">{label}</p>
        <div className="c-transfer-info__entry__value">
            <span
                className={`material-icons-outlined ${iconClass ? iconClass : (value ? 'is-icon-success' : 'is-icon-warning')}`}>{icon ? icon : (value ? 'check_circle' : 'report')}</span>
            <span>{value || 'Unknown'}</span>
        </div>
    </div>
);