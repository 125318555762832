import React, { createContext, useState } from "react";

export type BalanceContextType = {
    isOpened: boolean;
    setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const BalanceContext = createContext<BalanceContextType>({} as BalanceContextType);

function BalanceContextProvider({ children }: React.PropsWithChildren) {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <BalanceContext.Provider value={{ isOpened, setIsOpened }}>
        {children}
    </BalanceContext.Provider>
  );
}

export { BalanceContextProvider, BalanceContext };