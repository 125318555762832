import { FC, useState } from 'react';
import { useFormik } from 'formik';
import './SustainabilityFormStyles.scss';
import { MyFormValues } from './types';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import Field from '../../Field/Field';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setField } from '../../../../../../store/reducers/user/bcuFormReducer';
import FormTitle from '../../FormTitle/FormTitle';
import {
  handleEnterLatinAndNumbers,
  handleEnterNumbers,
} from '../../../../../../helpers/handleKeyPress';

interface SustainabilityFormProps {
  handleClick: () => void;
}

const SustainabilityForm: FC<SustainabilityFormProps> = ({ handleClick }) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(state => state.form);

  const [errorVisible, setErrorVisible] = useState(false);

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });

  const handleValidation = (values: any) => {
    const errors = {} as any;
    formik.setErrors(errors);
    return errors;
  };

  const handleFieldChange = (field: keyof MyFormValues, value: string) => {
    dispatch(setField({ fieldName: field, value: value }));
    formik.setFieldValue(field, value);
  };

  const handleFormClick = () => {
    handleClick();
  };

  return (
    <div>
      <FormTitle title="5. ICAO CORSIA information" />
      <form onSubmit={formik.handleSubmit}>
        <Field
          handleKeyPress={handleEnterNumbers}
          label="Production date of neat SAF"
          id="dateSaf"
          onChange={e =>
            handleFieldChange('dateSustainabilitySAF', e.target.value)
          }
          value={formik.values.dateSustainabilitySAF}
          onBlur={formik.handleBlur}
          type="date"
          error={formik.errors.dateSustainabilitySAF}
          errorVisible={errorVisible}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label={
            <span>
              Default ILUC value (gCO<sub>2</sub>eg/MJ)
            </span>
          }
          placeholder="Default ILUC value (gCO2eg/MJ)"
          id="fieldCO2"
          value={formik.values.fieldSustainabilityCO2}
          onChange={e =>
            handleFieldChange('fieldSustainabilityCO2', e.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.errors.fieldCO2}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="If applicable, disaggregated value for Direct Land Use Change"
          placeholder="If applicable, disaggregated value for Direct Land Use Change"
          id="fieldLand"
          value={formik.values.fieldSustainabilityLand}
          onChange={e =>
            handleFieldChange('fieldSustainabilityLand', e.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.errors.fieldSustainabilityLand}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="If applicable, disaggregated value for Landfill Emission Credit"
          placeholder="If applicable, disaggregated value for Landfill Emission Credit"
          id="fieldLandfill"
          value={formik.values.fieldSustainabilityLandfill}
          onChange={e =>
            handleFieldChange('fieldSustainabilityLandfill', e.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.errors.fieldSustainabilityLandfill}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="If applicable, disaggregated value Recycling Emission Credit (Rec)"
          placeholder="If applicable, disaggregated value Recycling Emission Credit (Rec)"
          id="fieldRecycling"
          value={formik.values.fieldSustainabilityRecycling}
          onChange={e =>
            handleFieldChange('fieldSustainabilityRecycling', e.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.errors.fieldSustainabilityRecycling}
        />
        <NextButton
          handleClick={handleFormClick}
          text="Preview"
          style={{ width: '170px', marginLeft: '330px' }}
        />
      </form>
    </div>
  );
};

export default SustainabilityForm;
