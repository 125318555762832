import {SSEEventData} from '../hooks/useSSE';
import {fetchAccounts} from "../API/admin/accounts";
import {fetchAdminBCUs} from "../API/admin/bcu-list";
import {fetchAdminLogs} from "../API/admin/logs";
import { fetchPrivateRetirementStatements, fetchRetirementStatements } from '../API/retirement-statements';
import {fetchUsers} from "../API/admin/users";
import { fetchBaselines, fetchCredits } from '../API/credits';
import { fetchCompanyInfo } from '../API/company';
import { api } from '../API';

export enum TableStructure {
  RetirementStatement = 'retirement-statement',
  Counters = 'counters',
  Logs = 'logs',
  Bcu = 'bcu',
  Accounts = 'accounts',
  Users = 'users',
  SubmissionsRetirementTransactions = 'submissionsRetirementTransactions',
  SubmissionsTransferTransactions = 'submissionsTransferTransactions',
  SubmissionsRegisterTransactions = 'submissionsRegisterTransactions',
  SubmissionsUsers = 'submissionsUsers',
  SubmissionsUserUpdates = 'submissionsUserUpdates',
  ReferenceValues = 'referenceValues',
  Balance = 'balance',
}

export const updateTableData = (
  { id, data }: SSEEventData,
  dispatch: any,
  user?: any,
) => {
  switch (id) {
    case TableStructure.Counters:
      dispatch(api.util.invalidateTags(['Credits', 'IncomingCreditCount', 'InformationRequestsCount']));
      break;
    case TableStructure.Balance:
      dispatch(api.util.invalidateTags(['Credits', 'IncomingCreditCount', 'InformationRequestsCount']));

      dispatch(fetchCompanyInfo());
      dispatch(fetchCredits());
      dispatch(fetchPrivateRetirementStatements({
        page: 1,
        limit: 50,
        sortOrder: 'desc',
        sortBy: 'approveDate',
        accountId: user?.company.id,
      }));
      break;
    case TableStructure.RetirementStatement:
      dispatch(fetchRetirementStatements({ page: 1, limit: 50, sortBy: 'BCUID', sortOrder: 'desc' }));
      break;

    case TableStructure.Logs:
      dispatch(fetchAdminLogs({ page: 1, limit: 50, sortBy: 'createdAt', sortOrder: 'desc' } as any));
      break;

    case TableStructure.Bcu:
      dispatch(fetchAdminBCUs({ page: 1, limit: 50, sortBy: 'registrationApproveDate', sortOrder: 'desc' }));
      break;

    case TableStructure.Accounts:
      dispatch(fetchAccounts({ page: 1, limit: 50, sortBy: 'name', sortOrder: 'desc' }));
      break;

    case TableStructure.Users:
      dispatch(fetchUsers({ page: 1, limit: 50, sortBy: 'SUID', sortOrder: 'desc' } as any));
      break;

    case TableStructure.SubmissionsTransferTransactions:
      dispatch(fetchAdminBCUs({ page: 1, limit: 50, sortBy: 'registrationApproveDate', sortOrder: 'desc' }));
      break;

    case TableStructure.SubmissionsRetirementTransactions:
      dispatch(fetchAdminBCUs({ page: 1, limit: 50, sortBy: 'registrationApproveDate', sortOrder: 'desc' }));
      break;

    case TableStructure.SubmissionsRegisterTransactions:
      dispatch(fetchAdminBCUs({ page: 1, limit: 50, sortBy: 'registrationApproveDate', sortOrder: 'desc' }));
      break;

    case TableStructure.SubmissionsUsers:
      dispatch(fetchAccounts({ page: 1, limit: 50, sortBy: 'name', sortOrder: 'desc' }));
      dispatch(fetchUsers({ page: 1, limit: 50, sortBy: 'SUID', sortOrder: 'desc' } as any));
      break;

    case TableStructure.SubmissionsUserUpdates:
      dispatch(fetchUsers({ page: 1, limit: 50, sortBy: 'SUID', sortOrder: 'desc' } as any));
      break;

    case TableStructure.ReferenceValues:
      dispatch(fetchBaselines());
      break;

    default:
      break;
  }
};
