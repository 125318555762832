import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import { ScopeInfo } from '../../../../components/UserBalance/Tabs/ScopeInfo';

export const AdminScope1Info: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);

  if (!details) return null;

  const transaction = details?.creditTransaction;
  const credit = transaction?.newCredit || transaction?.credit;

  return (
    <>{credit && <ScopeInfo scope="scope1" credit={{ ...credit, scope1Company: credit?.scope1Company || transaction?.scope1Company, scope3Company: credit?.scope3Company || transaction?.scope3Company }} />}</>
  );
};
