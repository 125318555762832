import React, { FC, useEffect, useState } from 'react';
import { EditButtons } from '../EditButtons/EditButtons';
import DataTable, { DataKeyType, ProcessedDataKeyType } from '../DataTable/DataTable';
import { diff } from 'deep-object-diff';

const InfoData: FC<{
    data: any,
    keys: { fields: DataKeyType[]; label?: string }[],
    editable?: boolean;
    onSave?: (data: any) => Promise<boolean>;
    renderAdditionalParams?: { [key: string]: any };
    renderEditAdditionalParams?: { [key: string]: any };
}> = ({ data, keys, editable, onSave, renderAdditionalParams, renderEditAdditionalParams }) => {
    const [groups, setGroups] = useState<Array<{
        label?: string;
        fields: DataKeyType[],
        processedFields: ProcessedDataKeyType[]
    }>>([]);
    const [isEdit, setIsEdit] = useState(false);
    const [innerData, setInnerData] = useState(JSON.parse(JSON.stringify(data || {})));
    const [preSaveData, setPreSaveData] = useState(JSON.parse(JSON.stringify(data || {})));

    const handleFieldChange = (field: ProcessedDataKeyType, value: any) => {
        if (typeof field.key !== 'undefined') {
            setInnerData((prevData: any) => ({
                ...prevData,
                [field.key as string]: value,
            }));
        } else {
            setInnerData((prevData: any) => ({
                ...prevData,
                ...value,
            }));
        }

    };

    useEffect(() => {
        setInnerData(JSON.parse(JSON.stringify(data)));
        setPreSaveData(JSON.parse(JSON.stringify(data)));
    }, [data]);

    useEffect(() => {
        setGroups(keys.map(k => {
            const processedFields = (k.fields || []).map(field => ({
                ...field,
                options: field.options && field.options(innerData),
                value: field.value(innerData),
                ...(field.render ? { render: field.render(innerData, renderAdditionalParams) } : {}),
            }));

            return {
                ...k,
                processedFields: processedFields.map((processedField) => ({
                    ...processedField,
                    ...(processedField.renderEdit ? { renderEdit: processedField.renderEdit(innerData, (v) => handleFieldChange(processedField, v), renderEditAdditionalParams) } : {}),
                })),
            };
        }));
    }, [innerData, keys]);

    const handleEditCancel = () => {
        setInnerData(JSON.parse(JSON.stringify(data)));
        setIsEdit(false);
    };

    const handleEditSave = async () => {
        if (!onSave) {
            return true;
        }

        const differences: any = diff(preSaveData, innerData);

        // No changes made
        if (Object.keys(differences).length === 0) {
            setIsEdit(false);
            return true;
        }

        if (differences.cellPhone) {
            differences.cellPhone = {
                code: differences.cellPhone.code || preSaveData.cellPhone?.code,
                number: differences.cellPhone.number || preSaveData.cellPhone?.number,
            };
        }

        // Only save differences between the original and inner data
        return onSave({
            ...differences,
            id: data.id,
        }).then((isSaveSuccessful) => {
            if (isSaveSuccessful) {
                setIsEdit(false);
                setPreSaveData({
                    ...innerData,
                    ...differences,
                });
            }
            return isSaveSuccessful;
        });
    };

    return (
        <div className="c-info-data">
            <div className="c-info-data__wrapper">
                <DataTable data={innerData} edit={isEdit} columns={groups} onChange={handleFieldChange} />
                {editable && <EditButtons isEdit={isEdit} onEdit={() => setIsEdit(true)} onSave={handleEditSave}
                                          onCancel={handleEditCancel} />}
            </div>
        </div>
    );
};

export default InfoData;
