import React, { FC, ReactNode, useEffect, useState } from 'react';
import './DataTable.scss';
import { handleEnterNumbersAndSlash } from '../../../helpers/handleKeyPress';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { DatePicker, Input, Select } from 'antd';
import { isDate, startOfDay } from 'date-fns';
import { dropTimeZone } from '../../core/models/contstants/format-date';

export type DataKeyType<T = any> = {
  label: string | ReactNode;
  type?: 'select' | 'text' | 'number' | 'numberAndSlashes' | 'date';
  editable?: boolean;
  key?: keyof T;
  options?: (v: T) => any[];
  renderEdit?: (
    v: T,
    onChange: (v: any) => any,
    params?: { [key: string]: any },
  ) => any;
  render?: (v: T, params?: { [key: string]: any }) => any;
  value: (v: T) => any;
  meta?: T;
};

export type ProcessedDataKeyType<T = any> = Omit<
  DataKeyType<T>,
  'options' | 'value' | 'render'
> & {
  options?: any[];
  renderEdit?: any;
  render?: any;
  value: any;
};

const DateFnsDatePicker = DatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
);

const DataTable: FC<{
  data: any;
  edit: boolean;
  columns: Array<{
    label?: string | ReactNode;
    fields: DataKeyType[];
    processedFields: ProcessedDataKeyType[];
  }>;
  onChange?: (field: ProcessedDataKeyType, value: any) => any;
}> = ({ data, edit, columns, onChange }) => {
  const [groups, setGroups] = useState<
    Array<{
      label?: string | ReactNode;
      fields: DataKeyType[];
      processedFields: ProcessedDataKeyType[];
    }>
  >(columns);

  useEffect(() => {
    setGroups(columns);
  }, [columns]);

  return (
    <div className="c-data-table">
      {groups.map((group, idy) => (
        <div className="c-data-table__wrap" key={idy}>
          {group.label && (
            <div className="c-data-table__delimiter">{group.label}</div>
          )}
          {group.processedFields.map((field, idx) => (
            <div className="c-data-table__cell-wrap" key={idx}>
              <div
                className={`c-data-table__cell${
                  idx % 2 === 0 ? ' is-even' : ' is-odd'
                }`}>
                {field.label}
              </div>
              <div
                className={`c-data-table__cell${
                  idx % 2 === 0 ? ' is-even' : ' is-odd'
                }`}>
                {!edit && (field.render || field.value)}
                {edit && field.renderEdit}
                {edit &&
                  !field.renderEdit &&
                  {
                    text: (
                      <Input
                        className="c-data-table__input"
                        type="text"
                        defaultValue={field.value}
                        onChange={e => onChange?.(field, e.target.value)}
                      />
                    ),
                    date: (
                      <DateFnsDatePicker
                        className="c-data-table__input"
                        format="dd.MM.yyyy"
                        defaultValue={
                          field.value ? dropTimeZone(field.value) : undefined
                        }
                        onChange={v =>
                          onChange?.(
                            field,
                            isDate(v) ? startOfDay(v as Date) : v,
                          )
                        }
                      />
                    ),
                    select: (
                      <Select
                        className="c-data-table__input"
                        showSearch={true}
                        options={field.options as any}
                        value={field.value}
                        onChange={v => {
                          onChange?.(field, v);
                        }}
                      />
                    ),
                    number: (
                      <Input
                        className="c-data-table__input c-data-table__input--no-controls"
                        type="number"
                        defaultValue={field.value}
                        onChange={e => {
                          onChange?.(field, e.target.value);
                          e.target.value = parseFloat(
                            e.target.value,
                          ).toLocaleString();
                        }}
                        onWheelCapture={e => e.currentTarget?.blur()}
                      />
                    ),
                    numberAndSlashes: (
                      <Input
                        className="c-data-table__input"
                        type="text"
                        onKeyDown={handleEnterNumbersAndSlash}
                        defaultValue={field.value}
                        onChange={e => onChange?.(field, e.target.value)}
                      />
                    ),
                    default: field.render || field.value,
                  }[field.type || 'default']}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DataTable;
