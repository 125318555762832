import React from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { Outlet } from 'react-router-dom';

import './MainLayoutStyle.scss';

export const MainLayout: React.FC = () => (
  <div className="main-layout">
    <Header />
    <div className="container">
      <Outlet />
    </div>
    <Footer />
  </div>
);
