import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import './summaryFieldStyles.scss';

interface FieldSumProps {
  nameProp: string | ReactNode;
  selected: string;
  border?: boolean;
}

const SummaryField: FC<FieldSumProps> = ({ nameProp, selected, border }) => {
  const containerClassName = classNames('summary-container', {
    border: border,
  });

  return (
    <div className={containerClassName}>
      <span className="selected">{nameProp}</span>
      <span className="selected-two">{selected}</span>
    </div>
  );
};

export default SummaryField;
