import React from 'react';
import { InputProps } from '../Form/Input';
import { useDebouncedCallback } from 'use-debounce';
import  SearchSvg from '../../../assets/search.svg'

import './styles.scss';

export const SearchTable: React.FC<Omit<InputProps, 'someBefore'>> = ({
  className,
  onChange,
  ...props
}) => {
  const debouncedOnChange = useDebouncedCallback(
    event => onChange?.(event),
    500,
  );

  return (
    <div className="c-search-contact-list">
        <img src={SearchSvg} alt="" />
        <input 
            className="c-search-contact-list__input"
            onChange={debouncedOnChange}
            placeholder="Search within table"
        />
    </div>
  );
};
