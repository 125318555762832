import React from 'react';
import { AdminSubmissionsDetailsLayout } from './AdminSubmissionsDetailsLayout';
import {
    fetchSubmissionsProfileInfo,
    fetchUserUpdateApprove,
    fetchUserUpdateReject,
} from '../../../../API/admin/submissions';
import {
    ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES
} from '../../../../structure/routes/routes';
import { SubmissionsType } from '../../../../structure/request/admin';

export const AdminProfileUpdateLayout: React.FC = () => {
    return (
        <AdminSubmissionsDetailsLayout
            fetchData={fetchSubmissionsProfileInfo}
            fetchReject={fetchUserUpdateReject}
            fetchApprove={fetchUserUpdateApprove}
            type={SubmissionsType.ProfileUpdate}
            tabs={[
                {
                    title: 'Profile info',
                    path: ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.PROFILE_INFO,
                },
                {
                    title: 'Note',
                    path: ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.NOTE,
                },
            ]}
        />
    );
};
