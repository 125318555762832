import React from 'react';
import { MultiValue } from 'react-select';
import { Input } from '../../../../../components/Form/Input';
import { EditableColumn } from '../../../../../components/Table/Table';

export const getColumns = (
  onChange?: (
    value: { name: string; value: string | MultiValue<any> },
    id: number,
  ) => void,
  onDelete?: (id: number) => void,
  isEditable = false,
): EditableColumn<any>[] => {
  const onValueChange = (
    name: string,
    value: string | MultiValue<any>,
    id: number,
  ) => {
    const formattedValue = Array.isArray(value)
      ? value.map(item => item.label)
      : value;
    if (onChange) onChange({ name, value: formattedValue }, id);
  };

  return [
    ...(isEditable
      ? [
          {
            title: 'Remove',
            keyItem: 'name',
            render: (item: any) => (
              <button
                title="Remove baseline value"
                style={{
                  border: 'none',
                  outline: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                }}
                onClick={onDelete ? () => onDelete(item.id) : () => false}>
                <span
                  className="material-icons-outlined"
                  style={{ fontSize: '18px' }}>
                  delete
                </span>
              </button>
            ),
          },
        ]
      : []),
    {
      title: 'Scheme',
      keyItem: 'name',
      render: item => item.name,
      ...(isEditable
        ? {
            renderEdit: (item: any) => (
              <Input
                name="name"
                placeholder="Scheme Name"
                value={item.name}
                onChange={e => onValueChange('name', e.target.value, item.id)}
              />
            ),
          }
        : {}),
    },
    {
      title: (
        <span>
          Baseline value gCO<sub>2</sub>eq/MJ
        </span>
      ),
      keyItem: 'baselineValue',
      render: item => (
        <span style={{ paddingRight: '60px' }}>{item.baselineValue}</span>
      ),
      ...(isEditable
        ? {
            renderEdit: (item: any) => (
              <div style={{ paddingRight: '60px' }}>
                <Input
                  name="baselineValue"
                  value={item.baselineValue}
                  onChange={e =>
                    onValueChange('baselineValue', e.target.value, item.id)
                  }
                />
              </div>
            ),
          }
        : {}),
    },
  ];
};
