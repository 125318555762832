import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';

import { useMemo } from 'react';
import DataTable, {
  DataKeyType,
} from '../../../../components/DataTable/DataTable';

const getInfoKeys = (
  data: any,
): { fields: DataKeyType[]; label?: string }[] => {
  const transaction: any = data?.creditTransaction;
  const credit: any = transaction?.newCredit || transaction?.credit;

  return [
    {
      fields: [
        {
          label: 'BCU ID',
          value: v => credit?.BCUID,
        },
        {
          label: 'Amount BCUs',
          value: v =>
            transaction?.amount.toLocaleString('en-US', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            }),
        },
        {
          label: (
            <span>
              Amount CO<sub>2</sub>eq reduction (t)
            </span>
          ),
          value: v => credit?.tonnesOfCO2eq,
        },
        {
          label: 'Sending company',
          value: v => v?.company?.name,
        },
        {
          label: 'Receiving company',
          value: v => transaction?.receiver?.name,
        },
      ],
    },
  ];
};

export const AdminTransferInfo: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);

  const groups = useMemo(
    () =>
      getInfoKeys(details).map(keys => {
        const processedFields = (keys.fields || []).map(field => ({
          ...field,
          options: field.options && field.options(details),
          value: field.value(details),
          ...(field.render ? { render: field.render(details) } : {}),
        }));

        return {
          ...keys,
          processedFields,
        };
      }),
    [details],
  );

  if (!details) return null;

  return (
    <div style={{ margin: '20px 0 0' }}>
      <div>
        <DataTable data={details} edit={false} columns={groups} />
      </div>
    </div>
  );
};
