import { phoneRegexp } from "../../../constants";
import { FieldsValidator } from "../../../helpers/getFieldsErrors";
import { isInTypes } from "../../../helpers/types";
import { CompanyType } from '../../../structure/models/company/company';
import { AccountFields } from "../../../structure/models/user/user";
import { CODES } from "../../components/Form/models/codes.constant";
import { z } from 'zod';

export const accountFieldsValidator = (
  account: AccountFields,
): FieldsValidator<AccountFields> => {
  return {
    companyName: (value: string) => ({
      error: !(value.length >= 3 && value.length <= 100),
      text: 'Name length must be from 3 to 100 characters',
    }),
    accountType: (value: string) => ({
      error: !value,
      text: 'Type is required',
    }),
    inviteToken: (value: string) => {
      const type = account['accountType'];
      return {
        error: isInTypes(type, [CompanyType.Corporate]) ? !value : false,
        text: 'Token is required',
      };
    },
    registrationNumber: (value: string) => ({
      error: !value,
      text: 'Registration Number is required',
    }),
    registrationIdentification: (value: string) => ({
      error: !value,
      text: 'Registration Identification is required',
    }),
    rsb: (value: string) => {
      const type = account['accountType'];

      return {
        error: type === CompanyType.Supplier ? !value : false,
        text: 'Please provide RSB PO number.',
      };
    },
    certificateStartDate: (value: string) => {
      const type = account['accountType'];
      return {
        error: type === CompanyType.Supplier ? !value : false,
        text: 'Start date is required',
      };
    },
    certificateEndDate: (value: string) => {
      const type = account['accountType'];
      return {
        error: type === CompanyType.Supplier ? !value : false,
        text: 'End date is required',
      };
    },
    streetAddress: (value: string) => ({
      error: !value,
      text: 'Street Address is required',
    }),
    zip: (value: string) => ({
      error: !(value.length > 3),
      text: 'ZIP should be more than 3 characters',
    }),
    city: (value: string) => ({
      error: !value,
      text: 'City is required',
    }),
    country: (value: string) => ({
      error: !value,
      text: 'Country is required',
    }),
    companyPhone_code: (value: string) => ({
      error: !CODES.includes(value),
      text: 'Code must have correct format',
    }),
    companyPhone_number: (value: string) => ({
      error: !phoneRegexp.test(value),
      text: 'Phone must have correct format'
    }),
    name: (value: string) => ({
      error: !value,
      text: 'Name is required',
    }),
    surname: (value: string) => ({
      error: !value,
      text: 'Surname is required',
    }),
    position: (value: string) => ({
      error: !value,
      text: 'Position is required',
    }),
    email: (value: string) => {
      const schema = z.string({ required_error: 'Email is required'}).email({ message: 'Please enter a valid email' });
      const { success } = schema.safeParse(value);

      return {
        error: !success,
        text: 'Please enter a valid email',
      };
    },
    userPhone_code: (value: string) => ({
      error: !CODES.includes(value),
      text: 'Code must have correct format',
    }),
    userPhone_number: (value: string) => ({
      error: !phoneRegexp.test(value),
      text: 'Phone must have correct format',
    }),
    agreement: (value: string) => ({
      error: !value,
      text: 'Agreement is required',
    }),
  };
};
