import { actions } from './fetchProvider/fetch';
import { URL_ADMIN_GET_RETIREMENT_STATEMENTS } from './urls/urls';
import { AxiosResponse } from 'axios';
import { PaginatedData } from '../structure/request/pagination';
import { ServerResponse } from './types';
import {
  setRetirementStatementsPrivateList,
  setRetirementStatementsPublicList,
  setRetirementStatementsStatus,
} from '../store/reducers/user/retirementStatementsReducer';
import { RetirementStatement } from '../structure/models/retirement-statements/retirement-statements';
import { api, transformResponse } from './index';

export const retirementStatements = api.injectEndpoints({
  endpoints: builder => ({
    getRetirementStatements: builder.query<
      any,
      { page: number; limit: number; [key: string | number]: any }
    >({
      keepUnusedDataFor: 120,
      providesTags: (result, e, search) => {
        const individualCache: { type: 'RetirementStatements'; id: string }[] = (
          Array.isArray(result) ? result : []
        ).map(({ code }: { code: string }) => ({
          type: 'RetirementStatements',
          id: code,
        }));
        return [{ type: 'RetirementStatements', search }, ...individualCache];
      },
      query: ({ page, limit, ...query }) => ({
        action: () =>
          actions.get<
            AxiosResponse<ServerResponse<PaginatedData<RetirementStatement>>>
          >(URL_ADMIN_GET_RETIREMENT_STATEMENTS, {
            queries: {
              skip: (page - 1) * limit,
              limit,
              ...query,
            },
          }),
      }),
      transformResponse,
    }),
  }),
});

export const {
  useGetRetirementStatementsQuery,
  useLazyGetRetirementStatementsQuery,
} = retirementStatements;

export const fetchRetirementStatements =
  ({ page, limit, ...query }: any) =>
  (dispatch: any) => {
    dispatch(setRetirementStatementsStatus('loading'));

    return actions
      .get(URL_ADMIN_GET_RETIREMENT_STATEMENTS, {
        queries: {
          skip: (page - 1) * limit,
          limit,
          ...query,
        },
      })
      .then((resp: any) => {
        const { results, count } = resp.data.data;
        const data = {
          page,
          limit,
          items: results,
          loading: false,
          total: count,
        };

        dispatch(setRetirementStatementsStatus('idle'));
        dispatch(setRetirementStatementsPublicList(data));
      })
      .catch(e => {
        dispatch(setRetirementStatementsStatus('failed'));
        throw e;
      });
  };

export const fetchPrivateRetirementStatements =
    ({ page, limit, ...query }: any) =>
        (dispatch: any) => {
          dispatch(setRetirementStatementsStatus('loading'));

          return actions
              .get(URL_ADMIN_GET_RETIREMENT_STATEMENTS, {
                queries: {
                  skip: (page - 1) * limit,
                  limit,
                  ...query,
                },
              })
              .then((resp: any) => {
                const { results, count } = resp.data.data;
                const data = {
                  page,
                  limit,
                  items: results,
                  loading: false,
                  total: count,
                };

                dispatch(setRetirementStatementsStatus('idle'));
                dispatch(setRetirementStatementsPrivateList(data));
              })
              .catch(e => {
                dispatch(setRetirementStatementsStatus('failed'));
                throw e;
              });
        };
