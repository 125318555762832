import {CSSProperties, FC} from "react";
import "./NextButtonStyle.scss";

interface INextButtonProps {
    handleClick: () => void;
    disabled?: boolean;
    loading?: boolean;
    text: string;
    style?: CSSProperties & {
        ":hover"?: CSSProperties;
      };
    styleText?: CSSProperties;
}

const NextButton: FC<INextButtonProps> = ({handleClick, disabled, loading, text, style, styleText }) => {
    return (
        <button disabled={disabled} onClick={handleClick} className={`next-btn${loading ? ' next-btn--loading' : ''}`} type="button"
        style={style}>
            {loading && <span className="next-btn__loader"></span>}
            <span className="next-btn--text" style={styleText}>{text}</span>
        </button>
    );
};
export default NextButton;
