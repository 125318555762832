import { FC } from "react";
import "./neatStyles.scss";
import SummaryField from "../../SummaryField";
import { useAppSelector } from "../../../../../../store/hooks";



interface Props {
    title: string;
  }
  
  const NeatSum: FC<Props> = ({ title }) => {
    const formValues = useAppSelector((state) => state.form);
    return (
      <div className="form-sum1"> 
        <h2 className="form-title">{title}</h2>
        <SummaryField
          nameProp="Date of acquisition"  
          selected={formValues.fields.dateAcq}
          border
        />
        <SummaryField
          nameProp="Date of entry in tracking system"
          selected={formValues.fields.dateEntry}
          border
        />
        <SummaryField
          nameProp="Name" 
          selected={formValues.fields.fieldName}
          border
        />
        <SummaryField
          nameProp="Street 1" 
          selected={formValues.fields.fieldStreet1}
          border
        />
        <SummaryField
          nameProp="Street 2" 
          selected={formValues.fields.fieldStreet2}
          border
        />
        <SummaryField
          nameProp="Post Code" 
          selected={formValues.fields.fieldPost}
          border
        />
        <SummaryField
          nameProp="City" 
          selected={formValues.fields.fieldCity}
          border
        />
        <SummaryField
          nameProp="Country"
          selected={formValues.fields.fieldCountry}
          border
        />
        <SummaryField
          nameProp="Country of the last production/processing site"
          selected={formValues.fields.fieldCountryOf}
          border
        />
        <SummaryField
          nameProp="Chain of custody model"
          selected={formValues.fields.fieldChain}
          border
        />
        <SummaryField
          nameProp="PoS ID" 
          selected={formValues.fields.fieldPoS}
          border
        />
        <SummaryField
          nameProp="PoS issue date" 
          selected={formValues.fields.posIssueDate}
          border
        />
        <SummaryField
          nameProp="PoS pdf upload" 
          selected={formValues.fileDataTwoStep || ""}
        />
      </div>
    );
  };

export default NeatSum;