import React, { useEffect, useState } from 'react';
import { Table } from '../../../../components/Table/Table';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectBaseLines } from '../../../../../store/reducers/user/abatementBaselinesReducer';
import { fetchBaselines, fetchBaselinesDelete, fetchBaselinesEdit } from '../../../../../API/credits';
import { getColumns } from './models/columns.constant';
import { MultiValue } from 'react-select';
import { ConfirmationPrompt } from '../../../../components/ConfirmationPrompt';
import { Role } from '../../../../../structure/models/user/user';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { useNotification } from '../../../../../hooks/useNotification';
import { isInTypes } from '../../../../../helpers/types';
import { UserRole } from '@prisma/client';

export const CertificationSchemes: React.FC = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const baseLines = useAppSelector(selectBaseLines);
  const user = useAppSelector(selectUser);

  const isAuditor = (user && user.role === Role.Auditor) || false;

  const [localData, setLocalData] = useState<any[]>([]);
  const [editedRowId, setEditedRowId] = useState<number | null>( null);
  const [deletedRowId, setDeletedRowId] = useState<number | null>(null);

  const onEditClick = (id: number) => setEditedRowId(id);

  const onEditChange = (
    data: { name: string; value: string | MultiValue<any> },
    id: number,
  ) => {
    const { name, value } = data;
    setLocalData(
      localData.map(item =>
        item.id === id
          ? { ...item, [name]: +value ? +value : !value ? 0 : value }
          : item,
      ),
    );
  };

  const onDelete = (id: number) => {
    setDeletedRowId(id);
  };

  const onConfirmationClosed = (state: boolean) => {
    if (!state) {
      return setDeletedRowId(null);
    }

    return dispatch(fetchBaselinesDelete(deletedRowId!))
      .then(() => {
        toast.success({ message: 'Success', description: 'Reference values has been updated' });
      })
      .catch(() => {
        toast.error({ message: 'Error', description: 'Reference values update failed' });
      })
      .finally(() => {
        setDeletedRowId(null);
      });
  };

  const onEditSave = () => {
    if (!localData.length) {
      setEditedRowId(null);
      return;
    }
    return dispatch(
      fetchBaselinesEdit(
        editedRowId!,
        localData.find(({ id }) => id === editedRowId),
      ),
    )
      .then(() => {
        toast.success({ message: 'Success', description: 'Reference values has been updated' });
      })
      .catch(() => {
        toast.error({ message: 'Error', description: 'Reference values update failed' });
      })
      .finally(() => {
        setEditedRowId(null);
      });
  };

  useEffect(() => {
    setLocalData(baseLines);
  }, [baseLines]);

  useEffect(() => {
    dispatch(fetchBaselines());
  }, []);

  const isEdited = !isInTypes(user!.role, [UserRole.Auditor]);
  const columns = getColumns(onEditChange, onDelete, !isAuditor);

  return (
    <>
      <ConfirmationPrompt
        onClosed={onConfirmationClosed}
        show={deletedRowId !== null}>
        <p>Are you sure you want to delete this baseline value?</p>
      </ConfirmationPrompt>
      <Table
        isEdit={isEdited}
        isDefaultTableEdit
        showHeader
        editedRow={editedRowId as any}
        columns={columns}
        data={localData}
        onEditClick={onEditClick}
        onEditSave={onEditSave}
      />
    </>
  );
};
