import React, { useEffect } from 'react';
import { Table } from 'antd';

import { bcuLogsColumns } from './helpers/BcuLogs.columns';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectAdminCredit } from '../../../../../store/reducers/admin/bcuListReducer';
import { fetchAdminCreditLogs } from '../../../../../API/admin/bcu-list';
import { selectBcuLogs } from '../../../../../store/reducers/admin/logsReducer';

export const BcuLogs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectAdminCredit);
  const bcuLogs = useAppSelector(selectBcuLogs);

  useEffect(() => {
    if (id) {
      dispatch(
        fetchAdminCreditLogs({
          id,
        }),
      );
    }
  }, [id]);

  return (
    <div>
      <Table
        className="has-striped-rows"
        rowKey={record => record.id || ''}
        size={'middle'}
        dataSource={bcuLogs || []}
        pagination={false}
        columns={bcuLogsColumns}
      />
    </div>
  );
};
