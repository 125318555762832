import { useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/index.scss';
import { routes } from './AppRoutes';
import { config } from './config';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import Rollbar from 'rollbar';
import { Flex, Result, Spin, Typography, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: 'efdc7443910249e4800e636639f5b16d',
  environment: `${config.APP_STAGE}`,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureDeviceInfo: true,
  captureUsername: true,
};

const ErrorFallback = ({ error, resetError, uuid }: { error: Error | null, uuid: string | null, resetError: () => void }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator?.clipboard?.writeText(uuid || '');
    setIsCopied(true);
  }

  const ErrorReference = useMemo(() => {
    return !uuid ? <Spin /> : <Typography.Text><Button type="text" onClick={handleCopy} icon={<CopyOutlined />} />{isCopied ? `Copied! Error ID: ${uuid}` : `Error ID: ${uuid}`}</Typography.Text>;
  }, [uuid, isCopied]);

  return (
      <Flex style={{ height: '100vh', width: '100vw', overflow: 'hidden'}} justify='center' align='center'>
          <Result 
              status={500}
              title="Error"
              subTitle="Sorry, something went wrong."
              extra={ErrorReference}
          />
      </Flex>
  );
};

const HigherOrderErrorBoundary = () => {
  const [errorUUID, setErrorUUID] = useState<string | null>(null);

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary
        fallbackUI={({ error, resetError }) => <ErrorFallback error={error} resetError={resetError} uuid={errorUUID} />}
        callback={(err, response: any) => setErrorUUID(response?.result?.uuid || null)}
        >
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  )
};

const container = document.getElementById('root')!;
const root = createRoot(container);
const router = createBrowserRouter(routes);

root.render(
  <HigherOrderErrorBoundary />
);

reportWebVitals();

if (config.APP_STAGE === 'development') {
  import('./API/test').then(({ confirmEmail }) => {
    (window as any).__test = {
      confirmEmail,
    };
  });
}