import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsCompany } from '../../../../../store/reducers/admin/submissionsReducer';
import { List } from '../../../../components/List';
import { USER_INFO_TITLE } from '../models/columns.constant';
import './AdminUsersInfoStyle.scss';
import { UserType } from '../../../../../structure/models/user/user';
import StatusComponent from '../../../../components/StatusComponent';

export const AdminUsersInfo: React.FC = () => {
  const company = useAppSelector(selectSubmissionsCompany);

  if (!company) return null;

  const users = company.users.map((user: any) => ({
    ...user,
    phone: user.cellPhone
      ? `${user.cellPhone.code}${user.cellPhone.number}`
      : '',
    name: `${user.firstName || ''} ${user.lastName || ''}`,
    role: user.type === UserType.Primary ? 'Account owner' : 'User',
    status: <StatusComponent statusOfComponent={user.status} />,
  }));

  return (
    <div className="admin-users-info">
      <List titles={USER_INFO_TITLE} data={users} />
    </div>
  );
};
