import { compile } from 'path-to-regexp';
import { config } from '../../config';
import axios from 'axios';
import { store } from '../../store/store';
import {
  Action,
  DELETE,
  ExecuteType,
  GET,
  OptionsRequest,
  POST,
  PostOptions,
  PUT,
} from './types';
import { fetchLogOut } from '../login';
import { URL_LOGIN, URL_LOGOUT, URL_REFRESH_TOKEN } from '../urls/urls';

const execute: ExecuteType = (path, method, executeOptions) => {
  const token = store.getState().user.token;

  const {
    urlVariable = {},
    queries = {},
    headers = {},
    ...rest
  } = executeOptions;

  const options: OptionsRequest<typeof method> = {
    method,
    headers,
    url: '',
    baseURL: config.API_URL,
    ...rest,
  };

  if (path === URL_REFRESH_TOKEN || path === URL_LOGIN) {
    options.withCredentials = true;
  } else if (path !== URL_LOGIN && token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (method === 'POST' || method === 'PUT') {
    options.data = (executeOptions as PostOptions).payloads;
  }

  if (queries) {
    options.params = queries;
  }

  const compiler = compile(path, { encode: encodeURIComponent });
  options.url = compiler(urlVariable);

  return axios(options).catch(e => {
    // TODO remove after fix cors
    if (
      e?.response?.status === 401 &&
      !e?.request.responseURL.includes(URL_LOGOUT)
    ) {
      const dispatch = store.dispatch;
      dispatch(fetchLogOut());
    }
    return Promise.reject(e);
  });
};

export const actions: Action = {
  get: (path, options = {}) => execute<GET>(path, 'GET', options),
  post: (path, options = {}) => execute<POST>(path, 'POST', options),
  put: (path, options = {}) => execute<PUT>(path, 'PUT', options),
  delete: (path, options = {}) => execute<DELETE>(path, 'DELETE', options),
};
