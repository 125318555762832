import React, { useEffect, useMemo, useState } from 'react';

import './NoteStyle.scss';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import { Note as NoteComponent } from '../../../../components/Note';
import {
  RelationTypes,
  useLazyGetNoteByEntityQuery,
  useSaveNoteByEntityMutation,
} from '../../../../../API/admin/notes';
import { SubmissionsType } from '../../../../../structure/request/admin';
import { useNotification } from '../../../../../hooks/useNotification';
import { isInTypes } from '../../../../../helpers/types';
import { Prisma, TransactionType } from '@prisma/client';

const guessSubmissionTypeByData = (
  data: Prisma.SubmissionGetPayload<{
    include: {
      creditTransaction: true;
      userUpdate: true;
      user: true;
      company: true;
    };
  }>,
): SubmissionsType => {
  switch (true) {
    case !!data.creditTransaction:
      switch (data?.creditTransaction?.type) {
        case TransactionType.Register:
          return SubmissionsType.BCURegistration;
        case TransactionType.Transfer:
          return SubmissionsType.BCUTransfer;
        case TransactionType.Retirement:
          return SubmissionsType.BCURetirement;
        default:
          return SubmissionsType.BCURegistration;
      }
    case !!data.userUpdate:
      return SubmissionsType.ProfileUpdate;
    case !!data.user:
      return SubmissionsType.Registration;
    default:
      throw new Error('Unknown submission type');
  }
};

export const Note: React.FC = z => {
  const toast = useNotification();
  const [getNoteByEntity] = useLazyGetNoteByEntityQuery();
  const [saveNoteByEntity] = useSaveNoteByEntityMutation();
  const [value, setValue] = useState('');

  const details = useAppSelector(selectSubmissionsDetails);
  const type = useMemo(
    () =>
      details && Object.keys(details).length > 0
        ? guessSubmissionTypeByData(details)
        : null,
    [details],
  );

  let id: string = '';

  if (details && type) {
    switch (type) {
      case SubmissionsType.Registration:
        id = details?.companyId || '';
        break;
      case SubmissionsType.ProfileUpdate:
        id = details?.userUpdate?.companyId || '';
        break;
      default:
        id = details?.creditTransaction?.creditId || '';
        break;
    }
  }

  const onChange = (value: string) => {
    setValue(value);
  };

  const isAccount = isInTypes(type, [
    SubmissionsType.Registration,
    SubmissionsType.ProfileUpdate,
  ]);

  useEffect(() => {
    if (id === '') {
      return;
    }

    getNoteByEntity({
      id,
      type: isAccount ? RelationTypes.ACCOUNT : RelationTypes.CREDIT,
    }).then(({ data }) => {
      setValue((data && data.note) || '');
    });
  }, [id, isAccount]);

  const onSend = async (v: string) => {
    if (!id || !type) {
      return;
    }

    const result: any = await saveNoteByEntity({
      id,
      type: isAccount ? RelationTypes.ACCOUNT : RelationTypes.CREDIT,
      note: v || value || '',
    });

    if (result && result.data) {
      toast.success({
        message: 'Success',
        description: 'Your note has been saved',
      });
    } else {
      toast.error({
        message: 'Error',
        description:
          (result.error?.message || []).join('\n') || 'Unknown error',
      });
    }
  };

  return (
    <div className="admin-submissions-note">
      <NoteComponent value={value} onChange={onChange} onSend={onSend} />
    </div>
  );
};
