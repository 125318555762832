import React, { HTMLProps, memo, useEffect, useState } from 'react';

import './TableFiltersStyle.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Column, ColumnWithFilter } from './types';

export interface OnFilterChange<T = any> {
    column: Column<T>;
    values: any[];
}

export interface FilterProps<T = any> extends HTMLProps<HTMLDivElement> {
    column: ColumnWithFilter<T>,
    items: T[];
    onFilterChange?: (filter: OnFilterChange<T>) => void;
}

export const Filter = memo(({column, onFilterChange}: FilterProps) => {
    const [filters, setFilters] = useState<any[]>([]);
    const [selectedFilterTitles, setSelectedFilterTitles] = useState('');

    useEffect(() => {
        setSelectedFilterTitles(
            column.filters
                .filter((f) => filters.indexOf(f.value) > -1)
                .map(({title}) => title)
                .join(', ')
        );
    }, [column, filters]);

    const handleSelect = (value: any) => {
        if (value === null) {
            setFilters([]);

            if (onFilterChange) {
                onFilterChange({column, values: [] });
            }

            return;
        }

        const set = new Set(filters);

        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }

        const arr = Array.from(set.values());

        setFilters(arr);

        if (onFilterChange) {
            onFilterChange({column, values: arr });
        }
    };

    return (
        <div className="c-table-filters__filter c-table-filter">
            <DropdownButton
                className="c-table-filter__dropdown"
                title={<span className="c-table-filter__dropdown-title"><span className="c-table-filter__dropdown-icon material-icons-outlined">filter_list</span> {selectedFilterTitles || column.filterTitle || column.title}</span>}
                onSelect={(value) => handleSelect(value)}
            >
                <Dropdown.Item className="c-table-filter__dropdown-item c-dropdown-item" eventKey={undefined}>
                    <span className="c-dropdown-item__title">Show all</span>
                </Dropdown.Item>
                {column.filters.map(({title, value}, idx) => {
                    const isActive = filters.indexOf(value) > -1;
                    return <Dropdown.Item
                        className={'c-dropdown-item' + (isActive ? ' c-dropdown-item--active' : '')}
                        active={isActive}
                        eventKey={value} key={idx}>
                        <span className="c-dropdown-item__checkbox material-icons-outlined"></span>
                        <span className="c-dropdown-item__title">{title}</span>
                    </Dropdown.Item>;
                })}
            </DropdownButton>
        </div>
    );
});

export interface FiltersProps<T = any> {
    columns: Array<Column<T> | ColumnWithFilter<T>>;
    items: T[];
    onFilterChange?: (filter: OnFilterChange<T>) => void;
}

export const Filters = memo(({columns, items, onFilterChange}: FiltersProps) => {
    const filters = columns.map((column, idx) => {
        const cf = column as ColumnWithFilter;

        if (!cf.filters) {
            return null;
        }

        return (
            <Filter key={idx} column={cf} items={items} onFilterChange={onFilterChange}
                    className="c-table-filters__filter c-table-filter"/>
        );
    });

    if (!filters) {
        return null;
    }

    return (
        <div className="c-table-filters">{filters}</div>
    );
});