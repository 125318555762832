import './FileUploadStyles.scss'
import React, { ChangeEvent, FC, useRef, useState, DragEvent, useEffect } from 'react';
import closeRemovePdf from '../../../assets/closeRemovePdf.svg';

type FileUploadProps = {
    id?: string;
    name?: string;
    onFileUpload: (file: File | undefined) => void;
    label: string;
    accept?: string;
    required?: boolean;
    error?: string;
}

const FileUpload: FC<FileUploadProps> = ({ onFileUpload, label, accept, id, required, name, error }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragActive, setIsDragActive] = useState(false);
    const [file, setFile] = useState<File>();

    useEffect(() => {
        onFileUpload(file);
    }, [file]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files?.[0]);
    };

    const handleRemoveFile = () => {
        setFile(undefined);
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragActive(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragActive(false);
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragActive(false);
        setFile(event.dataTransfer.files[0]);
    };

    return (
        <div className={`c-file-upload${error ? ' c-file-upload--error' : ''}`}>
            <label className={`c-file-upload__label${required ? ' c-file-upload__label--required' : ''}`} htmlFor={id}>
                {label}
            </label>
            <div
                className={`c-file-upload__container${isDragActive ? ' c-file-upload__container--drag-active' : ''}`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {file && (
                    <div className="c-drop-panel">
                        <p className="c-drop-panel__file">{file.name}</p>
                        <img
                            className="c-drop-panel__remove"
                            src={closeRemovePdf}
                            alt="Remove file"
                            onClick={handleRemoveFile}
                        />
                    </div>
                )}
                {!file && (
                    <>
                        <span>Drag and drop file here or</span>
                        <label htmlFor={`${id}_input`} className="c-file-upload__button">
                            <span className="c-file-upload__text">Upload</span>
                        </label>
                    </>
                )}
                <input
                    id={`${id}_input`}
                    type="file"
                    accept={accept || 'application/pdf'}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div>
            {error && (
                <div className="c-file-upload__error">{error}</div>
            )}
        </div>
    )
};

export default FileUpload;
