import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {selectAdminCredit, setAdminCredit} from '../../../../../store/reducers/admin/bcuListReducer';
import {
  fetchAdminBCUCredit,
  fetchAdminEditBCU,
} from '../../../../../API/admin/bcu-list';
import { useParams } from 'react-router-dom';
import { setSelectedRow } from '../../../../../store/reducers/admin/adminReducer';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import BCUData from "../../../../components/BCUData/BCUData";
import {actions} from "../../../../../API/fetchProvider/fetch";
import {URL_POS_FILE, URL_SUPPLIER_FILE} from "../../../../../API/urls/urls";
import fileDownload from "js-file-download";
import { useNotification } from '../../../../../hooks/useNotification';
import { CompanyType } from '@prisma/client';

export const ValuesBCU: React.FC = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const { creditId } = useParams();

  const currentBCU = useAppSelector(selectAdminCredit);
  const user = useAppSelector(selectUser);

  const isEditable = user?.company?.type === CompanyType.Admin;

  useEffect(() => {
    dispatch(fetchAdminBCUCredit(creditId!));
  }, [dispatch, creditId]);

  useEffect(() => {
    dispatch(setSelectedRow(currentBCU));
  }, [dispatch, currentBCU]);

  const originBCU = currentBCU?.origin || currentBCU;

  const handleTransformAndDispatchData = async (data: any) => {
    try {
      await dispatch(fetchAdminEditBCU({
        ...data,
        abatementBaselineScheme: data.abatementBaselineScheme?.id,
        additionalClaimAsAllowedUnderRsb: data.additionalClaimAsAllowedUnderRsb || undefined,
        company: undefined,
        supplierFilePath: undefined,
      })).then((resultingBCU: any) => {
        // TODO: check if it works
        dispatch(setAdminCredit({
            ...currentBCU,
            origin: resultingBCU,
        }));
      });
      toast.success({ message: 'Success', description: 'BCU has been updated' });

      return true;
    } catch (error: any) {
      const messages: string[] = (error?.response?.data?.message || []);

      toast.error({ message: 'Error', description: 'BCU has not been updated' });

      messages.forEach((message: string) => {
        toast.error({ message: 'Error', description: message });
      });

      return false;
    }
  };

  const handleDownloadPosFile = async () => {
    const result: { data: Blob } | undefined = (await actions
        .get(URL_POS_FILE.replace(':id', originBCU.id), {
          responseType: 'blob',
        } as any)
        .catch(({ response }) => {
          toast.error({ message: 'Error', description: response.statusText });
        })) as any;

    if (result?.data) {
      fileDownload(result?.data, `pos_${originBCU.id}.pdf`);
      toast.success({ message: 'Success', description: 'The file has been downloaded successfully' });
    }
  };

  const handleDownloadSupplierFile = async () => {
    const result: { data: Blob } | undefined = (await actions
        .get(URL_SUPPLIER_FILE.replace(':id', originBCU.id), {
          responseType: 'blob',
        } as any)
        .catch(({ response }) => {
          toast.error({ message: 'Error', description: response.statusText });
        })) as any;

    if (result?.data) {
      fileDownload(result?.data, `supplier_${originBCU.id}.pdf`);
      toast.success({ message: 'Success', description: 'The file has been downloaded successfully' });
    }
  };

  return (
    <div className="admin-bcu-info__table">
      {originBCU && (
          <BCUData
              editable={isEditable}
              user={user}
              data={originBCU}
              onSave={handleTransformAndDispatchData}
              onDownloadPosFile={handleDownloadPosFile}
              onDownloadSupplierFile={handleDownloadSupplierFile}
          />
      )}
    </div>
  );
};
