import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  status: 'idle',
  list: {
    results: [] as any[],
    count: 0,
  },
  airports: [] as any [],
  selectedAirport: {} as any,
  splitBCU: [] as any[],
  credit: {},
};

const bcuListSlice = createSlice({
  name: 'admin-bcu-list',
  initialState,
  reducers: {
    setBCUsList: (state, action) => {
      state.list = action.payload;
    },
    updateBCU: (state, action) => {
      const bcu = action.payload;
      const results: any[] = state.list.results.map((item: any): any =>
        item.id === bcu.id ? bcu : item,
      );

      state.list = {
        ...state.list,
        results,
      };
    },
    setSplitBCU: (state, action) => {
      state.splitBCU = action.payload;
    },
    setBCUsListStatus: (state, action) => {
      state.status = action.payload;
    },
    setAdminCredit: (state, action) => {
      state.credit = action.payload;
    },
    setAirports: (state, action) => {
      state.airports = action.payload;
    },
    setSelectedAirport: (state, action) => {
      state.selectedAirport = action.payload
    }
  },
});

export const {
  setBCUsList,
  setBCUsListStatus,
  updateBCU,
  setSplitBCU,
  setAdminCredit,
  setAirports,
  setSelectedAirport,
} = bcuListSlice.actions;

export const selectBCUsList = (state: RootState) => state.adminBCUsList.list;
export const selectBCUsListStatus = (state: RootState): any =>
  state.adminBCUsList.status;
export const selectSplitBCU = (state: RootState) =>
  state.adminBCUsList.splitBCU;
export const selectAdminCredit = (state: RootState): any =>
  state.adminBCUsList.credit;
export const selectAirports = (state: RootState): any =>
  state.adminBCUsList.airports;

export default bcuListSlice.reducer;
