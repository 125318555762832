import React from 'react';
import { Search } from '../../../components/Table/Search/Search';
import { PaginatedTable } from '../../../components/Table/Table';
import { downloadAdminLogs, fetchAdminLogs } from '../../../../API/admin/logs';
import {
  selectAdminLogs,
  selectAdminLogsStatus,
} from '../../../../store/reducers/admin/logsReducer';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import { DownloadFileButton } from '../../../components/Buttons/DownloadFileButton/DownloadFileButton';

import './LogsStyle.scss';
import { DateQueryTypes, DateSelect } from '../../../components/Table/DateSelect';
import { Select } from '../../../components/Form/Select/Select';
import { ACTION_LOGS_OPTIONS } from '../../../../constants/action-logs';
import { COLUMNS } from './models/columns.constant';

export const Logs: React.FC = React.memo(() => {
  const [action, setAction] = useSearchParamsState('action', null);
  const [startDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    '',
  );
  const [endDate, setEndDate] = useSearchParamsState<string>('endDate', '');
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );
  const [sortBy] = useSearchParamsState<string | null>(
      'sortBy',
      null,
  );
  const [sortOrder] = useSearchParamsState<string | null>(
      'sortOrder',
      null,
  );

  const onSearch = (event: any) => setSearch(event.target.value);

  const onActionChange = (option: any) => {
    setAction(option.value);
  };

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onClear = () => setSearch(null);

  const params: any = {
    action: action || undefined,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    search: search || undefined,
    sortBy: sortBy || undefined,
    sortOrder: sortOrder || undefined,
  };

  return (
    <div className="admin-logs">
      <div className="admin-logs__header">
        <h1 className="admin-logs__header_text">Logs List</h1>
        <div className="admin-logs__header__actions">
          <div className="admin-logs__header__action">
            <DownloadFileButton fetchDownload={() => downloadAdminLogs(params)} />
          </div>
          <div className="admin-logs__header__action">
            <Select
              isClearable={false}
              placeholder="Type of Action"
              options={ACTION_LOGS_OPTIONS as any}
              value={action}
              onChange={onActionChange}
            />
          </div>
          <div className="admin-logs__header__action">
            <DateSelect onDateChange={onDateChange} />
          </div>
          <div className="admin-logs__header__action">
            <Search
              isClearable={Boolean(search)}
              onChange={onSearch}
              defaultValue={search ? search : ''}
              onClear={onClear}
            />
          </div>
        </div>
      </div>
      <div className="admin-logs__body">
        <PaginatedTable
          variant="logs"
          columns={COLUMNS}
          fetchData={fetchAdminLogs}
          selectStatus={selectAdminLogsStatus}
          selectData={selectAdminLogs}
          filters={{ search, startDate, endDate, action }}
        />
      </div>
    </div>
  );
});
