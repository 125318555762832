import React, { useEffect, useState } from 'react';
import NextButton from '../Buttons/NextButton/NextButton';
import './NoteStyle.scss';
import classNames from 'classnames';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import { UserRole } from '@prisma/client';

interface NoteProps {
  value: string;
  limit?: number;
  label?: string;
  isEdit?: boolean;
  onSend: (value: string) => void;
  onChange: (value: string) => void;
}

export const Note: React.FC<NoteProps> = ({
  value,
  label = 'Add a note',
  limit = 1000,
  isEdit = true,
  onSend,
  onChange,
}) => {
  const user = useAppSelector(selectUser);
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const [showButtons, setShowButtons] = useState(false);

  const onEditToggle = () => setShowButtons(!showButtons);

  const onCancel = () => {
    onEditToggle();
    setInnerValue(value);
  };

  const onSaveChanges = () => {
    onEditToggle();
    onChange(innerValue);

    onSend(innerValue);
  };

  const isLabelShow = isEdit ? showButtons : !showButtons;
  const isAuditor = user!.role === UserRole.Auditor;

  return (
    <div className="note">
      {!value && !showButtons && <div className="note__empty">No Data</div>}
      {isLabelShow && <div className="note__header">{label}</div>}
      {isEdit && !showButtons ? (
        <span className="note__text">{innerValue}</span>
      ) : (
        <>
          <textarea
            name="message"
            rows={10}
            maxLength={limit}
            onChange={e => setInnerValue(e.target.value || '')}
            value={innerValue}></textarea>
          <div className="note__limit">
            {innerValue.length}/{limit}
          </div>
        </>
      )}
      {!isAuditor && (
        <>
          {!isEdit && (
            <div className="note__button">
              <NextButton handleClick={() => onSend(innerValue)} text="Add" />
            </div>
          )}
          <div
            className={classNames('note__edit-buttons', {
              isEditButton: !showButtons,
            })}>
            {!showButtons && isEdit && (
              <NextButton handleClick={onEditToggle} text="Edit" />
            )}
            {showButtons && (
              <>
                <NextButton handleClick={onCancel} text="Cancel" />
                <NextButton handleClick={onSaveChanges} text="Save changes" />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
