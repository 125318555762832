import { FC, useState } from "react";
import "./summaryStyles.scss";
import GenField from "./GenSum";
import NeatSum from "./NeatSum";
import SelfSum from "./SelfSum";
import BlendSum from "./BlendSum";
import BatchSum from "./BatchSum";
import TotalSum from "./TotalSum";
import SusSum from "./SusSum";
import NextButton from "../../../Buttons/NextButton/NextButton";
import { useAppSelector } from '../../../../../store/hooks';
import { ConfirmationPrompt } from "../../../ConfirmationPrompt";

interface ISummary {
  handleClick: () => void;
  handleSubmit: () => void;
  disabled: boolean;
  loading?: boolean;
}

export const Summary: FC<ISummary> = ({handleClick, handleSubmit, disabled, loading}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const neatProductSource = useAppSelector((state) => state.form.fields.radioButton);
  const preSubmit = () => {
    setShowConfirmation(true);
  };

  const cancelPreSubmit = () => {
    setShowConfirmation(false);
  };

    return (
      <div className="summary-container">
        <form className="summary">
            <h1 className="summary-text">BCU Registration Summary</h1>
            <GenField title="1. General information"/>
            {(neatProductSource === 'Supplier') && <NeatSum title="2. Neat supplier information"/>}
            {(neatProductSource === 'Self-production') && <SelfSum title="2. Self-production information"/>}
            <BlendSum title="3. Blending account information"/>
            <BatchSum title="4. Batch  information"/>
            <SusSum title="5. ICAO CORSIA information"/>
            <TotalSum title="6. Total values"/>
            <div className="btn2">
              <NextButton handleClick={handleClick} text="Edit" style={{ marginLeft: '0', width: '73px', marginTop: '15px'}}/>
              <NextButton handleClick={preSubmit} disabled={disabled} loading={loading} text='Submit registration' style={{ marginLeft: '733px', marginTop: '15px', width: '170px' }}/>
            </div>
        </form>
        <ConfirmationPrompt
        onClosed={(state) => state ? handleSubmit() : cancelPreSubmit()}
        falseText="Return"
        trueText="Submit"
        show={showConfirmation}>
          <p>Are you sure you want to submit registration?</p>
        </ConfirmationPrompt>
      </div>
    )
}