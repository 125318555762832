import React, { useEffect } from 'react';
import './AccountsStyle.scss';
import { ACCOUNTS_COLUMNS } from './models/columns.constant';
import { EditablePaginatedTable } from '../../../components/Table/Table';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import {
  downloadAdminAccounts,
  fetchAccounts,
  fetchEditCompany,
} from '../../../../API/admin/accounts';
import {
  selectAccountsList,
  selectAccountsStatus,
} from '../../../../store/reducers/admin/accountsReducer';
import { Search } from '../../../components/Table/Search/Search';
import { transformData, validate } from './models/accounts.validate';
import { hasChanges } from './models/has-changes.constant';
import { useAppDispatch } from '../../../../store/hooks';
import {
  setSelectedRow,
  setVisiblePopUp,
} from '../../../../store/reducers/admin/adminReducer';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../hooks/useNotification';
import { DownloadFileButton } from '../../../components/Buttons/DownloadFileButton/DownloadFileButton';
import { Select, Option } from '../../../components/Form/Select/Select';
import { CompanyStatusOptions, CompanyTypeOptions } from '../../../../structure/models/company/company';
import { DateQueryTypes, DateSelect } from '../../../components/Table/DateSelect';
import { SingleValue } from 'react-select';

export const Accounts: React.FC = () => {
  const toast = useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const [status, setStatus] = useSearchParamsState('status', null);
  const [type, setType] = useSearchParamsState('type', null);
  const [startDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    '',
  );
  const [endDate, setEndDate] = useSearchParamsState<string>('endDate', '');
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );
  const [sortBy] = useSearchParamsState<string | null>(
      'sortBy',
      null,
  );
  const [sortOrder] = useSearchParamsState<string | null>(
      'sortOrder',
      null,
  );

  const rowKey = (item: any) => item.id;

  const handleClose = () => {
    dispatch(setVisiblePopUp(false));
  };

  const handleTransformAndDispatchData = (formData: any) => {
    return dispatch(fetchEditCompany(transformData(formData)))
      .then(() => {
        toast.success({ message: 'Success', description: 'Account has been updated' });
      })
      .catch(error => {
        toast.error({ message: 'Error', description: 'Account update failed' });
      });
  };

  const onRowClick = (item: any) => {
    dispatch(setSelectedRow(item));
    navigate(item.id.toString());
  };

  const onClear = () => setSearch(null);

  useEffect(() => {
    return () => handleClose();
  }, []);

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onChangeStatus = (options: SingleValue<Option>) => {
    setStatus(options?.value);
  };

  const onChangeType = (options: SingleValue<Option>) => {
    setType(options?.value);
  };

  const params: any = {
    status: status || undefined,
    companyType: type || undefined,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    search: search || undefined,
    sortBy: sortBy || undefined,
    sortOrder: sortOrder || undefined,
  };


  return (
    <div className="accounts">
      <div className="accounts__header">
        <h1 className="accounts__header_text">Accounts</h1>
        <div className="controls">
          <DownloadFileButton fetchDownload={() => downloadAdminAccounts(params)} />
          <Select
            className="users__header_filter"
            placeholder="Type"
            options={CompanyTypeOptions}
            value={type}
            isClearable={false}
            onChange={onChangeType}
          />
          <Select
            className="users__header_filter"
            placeholder="Status"
            options={CompanyStatusOptions}
            value={status}
            isClearable={false}
            onChange={onChangeStatus}
          />
          <DateSelect onDateChange={onDateChange} />
          <div className="search">
            <Search
              isClearable={Boolean(search)}
              className="search__input"
              defaultValue={search ? search : ''}
              onClear={onClear}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <EditablePaginatedTable
        fetchData={fetchAccounts}
        getColumns={ACCOUNTS_COLUMNS}
        selectData={selectAccountsList}
        selectStatus={selectAccountsStatus}
        filters={{
          search,
          status,
          companyType: type,
          startDate,
          endDate,
        }}
        rowKey={rowKey}
        variant="accounts"
        validate={validate}
        transformAndDispatchData={handleTransformAndDispatchData}
        hasChanges={hasChanges}
        onRowClick={onRowClick}
        isPopup={false}
      />
    </div>
  );
};
