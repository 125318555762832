import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { SortItem, SortOrder } from './SortItem/SortItem';
import { usePaginator } from './Pagination/PaginationHook';
import { PaginationHookProps } from './Pagination/models/pagination-hook-props.interface';
import { BaseButtonSubmit } from '../Buttons/BaseButtonSubmit/BaseButtonSubmit';

import './TableStyle.scss';
import './EditableTableStyle.scss';
import { Modal } from '../Modal/Modal';
import {
  ErrorBlocks,
  FormActionHookProps,
  useFormActions,
} from '../Form/FormHook';
import { useAppDispatch } from '../../../store/hooks';
import {
  setSelectedRow,
  setVisiblePopUp,
} from '../../../store/reducers/admin/adminReducer';
import { CompanyType } from '@prisma/client';

export interface Column<T> {
  title?: string | ReactNode;
  sort?: boolean;
  sorter?: (a: T, b: T) => number;
  render: (item: T, index: number) => React.ReactElement | string | any;
  keyItem: string;
  meta?: {
    style: Object;
  };
}

export interface EditableColumn<T> extends Column<T> {
  renderEdit?: (item: T, index: number) => React.ReactElement;
}

interface Props<T>
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {
  columns: Column<T>[];
  showHeader?: boolean;
  data: T[];
  rowClass?: string | ((item: T) => string);
  rowKey?: (item: T) => any;
  paginator?: React.ReactElement;
  onSort?: (key: string) => void;
  sort?: SortOrder;
  loading?: boolean;
  onRowClick?: (item: T) => void;
  tableClassName?: string;
  isPopup?: boolean;
  isEdit?: boolean;
  isEditDisabled?: boolean;
  isDefaultTableEdit?: boolean;
  editedRow?: string;
  onEditClick?: (id: any) => void;
  onEditSave?: () => void;
  variant?:
    | 'users'
    | 'logs'
    | 'accounts'
    | 'bcuList'
    | 'statements'
    | 'submissionList'
    | 'referenceValue';
}

interface PaginatedTableProps<TData = any>
  extends Omit<Props<TData>, 'paginator' | 'onSort' | 'sort' | 'data'>,
    Omit<PaginationHookProps<TData>, 'defaultSortBy'> {}

interface EditablePaginatedTableProps<TData = any>
  extends Omit<PaginatedTableProps<TData>, 'columns' | 'onSubmit'>,
    FormActionHookProps {
  getColumns: (
    showErrorBlocks: ErrorBlocks,
    onChange?: (value: { name: string; value: string }, id: string) => void,
    type?: CompanyType,
  ) => EditableColumn<TData>[];
  hasChanges: (formData: FormData, item: TData) => boolean;
  onEditChange?: (value: { name: string; value: string }, id: string) => void;
  onEditSave?: () => void;
  isEditDisabled?: boolean;
  type?: CompanyType;
}

export const Table = <T extends { id: string | number }>({
  className,
  showHeader,
  columns,
  data,
  rowKey = item => item.id,
  paginator,
  onSort,
  sort,
  loading = false,
  onRowClick,
  tableClassName,
  variant,
  isPopup = true,
  isDefaultTableEdit = true,
  isEdit,
  isEditDisabled,
  editedRow,
  onEditSave,
  onEditClick,
  ...props
}: React.PropsWithChildren<Props<T>>) => {
  const dispatch = useAppDispatch();
  const handleShowPopup = (item: T) => {
    if (
      variant &&
      (variant === 'accounts' || variant === 'bcuList' || variant === 'users')
    ) {
      dispatch(setSelectedRow(item));
      dispatch(setVisiblePopUp(true));
    }
  };

  const onItemClick = (item: T) => {
    if (isPopup) handleShowPopup(item);
  };

  const formattedColumns = isDefaultTableEdit
    ? columns.map(({ renderEdit, render, ...column }: EditableColumn<any>) => ({
        ...column,
        render: (item: any, index: number) => {
          return Boolean(item.id === editedRow && renderEdit)
            ? renderEdit!(item, index)
            : render(item, index);
        },
      }))
    : columns;

  return (
    <div className="table">
      <div className={classNames('table-wrapper', className)}>
        <table className={classNames('base_table', tableClassName)} {...props}>
          {showHeader && (
            <thead>
              <tr>
                {formattedColumns.map((column: Column<T>) => (
                  <th key={`column_${column.keyItem}`}>
                    {column.sort ? (
                      <SortItem
                        sort={sort!}
                        keyItem={column.keyItem}
                        title={column.title!}
                        onChangeSort={onSort!}
                      />
                    ) : (
                      column.title
                    )}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          <tbody>
            {!loading &&
              data?.map((item: T, index: number) => (
                <tr
                  className={classNames({
                    even: index % 2 === 0,
                    clickable: !!onRowClick,
                  })}
                  key={`row_${rowKey(item)}_${index}`}
                  {...(onRowClick ? { onClick: () => onRowClick(item) } : {})}>
                  {formattedColumns.map((column: Column<T>, idy: number) => (
                    <td
                      onClick={() => onItemClick(item)}
                      key={`column_${rowKey(item)}_${
                        column.keyItem
                      }_${index}_${idy}`}
                      style={
                        column.meta?.style || {
                          textAlign: 'left',
                        }
                      }>
                      <span className="text">{column.render(item, index)}</span>
                    </td>
                  ))}
                  {isEdit && onEditClick && (
                    <td className="table__editButton">
                      <div
                        className={classNames('table__editButton_container', {
                          even: index % 2 === 0,
                        })}>
                        {editedRow === item.id ? (
                          <span
                            className={classNames('material-icons-outlined', {
                              isDone: true,
                              disabled: isEditDisabled,
                            })}
                            onClick={onEditSave}>
                            done
                          </span>
                        ) : (
                          <span
                            className="material-icons-outlined"
                            onClick={() => onEditClick(item.id)}>
                            edit
                          </span>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        {(loading || !data?.length) && (
          <div className="table-service-information">
            {loading && (
              <div className="table-service-information__loading">
                <span className="material-icons-outlined loading-icon">
                  loop
                </span>
                Loading...
              </div>
            )}
            {!loading && !data?.length && (
              <div className="table-service-information__no-result-found">
                <div className="table-service-information__no-result-found__text">
                  No result found
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {paginator && <div className="table-pagination">{paginator}</div>}
    </div>
  );
};

export const PaginatedTable = <TData extends { id: string | number }>({
  columns,
  fetchData,
  filters,
  selectData,
  selectStatus,
  ...props
}: React.PropsWithChildren<PaginatedTableProps<TData>>) => {
  const firstSortColumn = columns.find((column: Column<TData>) => column.sort);
  const { data, sort, loading, onChangeSort, paginationNavigation, showBy } =
    usePaginator({
      fetchData,
      filters,
      columns,
      defaultSortBy: firstSortColumn?.keyItem ?? '',
      selectData,
      selectStatus,
    });

  return (
    <Table
      onSort={onChangeSort}
      paginator={
        <>
          {showBy}
          {paginationNavigation}
        </>
      }
      data={data.results}
      sort={sort}
      columns={columns}
      loading={loading}
      showHeader
      {...props}
    />
  );
};

// TODO change this shit
export const EditablePaginatedTable = <TData extends { id: string }>({
  getColumns,
  className,
  type,
  onEditChange,
  hasChanges,
  validate,
  transformAndDispatchData,
  ...props
}: PropsWithChildren<EditablePaginatedTableProps<TData>>) => {
  const [editableItem, setEditableItem] = useState<TData | null>(null);
  const [nextItem, setNextItem] = useState<TData | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { onSubmit, showErrorsBlock, loading, hasErrors } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const triggerSubmit = () => {
    ref.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true }),
    );
  };

  useEffect(() => {
    if (!loading && !hasErrors) {
      setEditableItem(nextItem);
      setNextItem(null);
    }
  }, [loading]);

  const ref: any = useRef();

  const editableColumns = getColumns(showErrorsBlock, onEditChange, type).map(
    ({ renderEdit, render, ...column }: EditableColumn<TData>) => ({
      ...column,
      render: (item: TData, index: number) => {
        return Boolean(item.id === props.editedRow && renderEdit)
          ? renderEdit!(item, index)
          : render(item, index);
      },
    }),
  );

  return (
    <form onSubmit={onSubmit} ref={ref}>
      <PaginatedTable
        className={classNames('editable-table', className)}
        columns={editableColumns}
        {...props}
      />
      <Modal
        header={<h2>Do you want to save your changes?</h2>}
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        centered>
        <div className="action-buttons centered">
          <BaseButtonSubmit
            onClick={() => {
              setModalVisible(false);
              setEditableItem(nextItem);
              setNextItem(null);
            }}>
            Don't save
          </BaseButtonSubmit>
          <BaseButtonSubmit
            active
            itemType="submit"
            onClick={() => {
              setModalVisible(false);
              triggerSubmit();
            }}>
            Save
          </BaseButtonSubmit>
        </div>
      </Modal>
    </form>
  );
};
