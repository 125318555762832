import { FC } from "react";
import "./susStyles.scss";
import SummaryField from "../../SummaryField";
import { useAppSelector } from "../../../../../../store/hooks";



interface Props {
    title: string;
  }

  const SusSum: FC<Props> = ({ title }) => {
    const formValues = useAppSelector((state) => state.form.fields);
    return (
      <div className="form-sum1">
        <h2 className="form-title">{title}</h2>
        <SummaryField
          nameProp="Production date of neat SAF"
          selected={formValues.dateSustainabilitySAF}
          border
        />
        <SummaryField
          nameProp={<span>Default ILUC value (gCO<sub>2</sub>eg/MJ)</span>}
          selected={formValues.fieldSustainabilityCO2}
          border
        />
        <SummaryField
          nameProp="If applicable, disaggregated value for Direct Land Use Change"
          selected={formValues.fieldSustainabilityLand}
          border
        />
        <SummaryField
          nameProp="If applicable, disaggregated value for Landfill Emission Credit"
          selected={formValues.fieldSustainabilityLandfill}
          border
        />
        <SummaryField
          nameProp="If applicable, disaggregated value Recycling Emission Credit (REC)"
          selected={formValues.fieldSustainabilityRecycling}
        />
      </div>
    );
  };

export default SusSum;