import React from 'react';
import { PageWithTabsLayout } from '../../../layouts/PageWithTabsLayout';
import { TABS } from './models/tabs.constant';
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { ViewElement } from '../../../components/ViewElement/ViewElement';
import './UsersDetailStyle.scss';
import { LeftOutlined } from '@ant-design/icons';

export const UsersDetail: React.FC = () => {
    const navigate = useNavigate();
    const data: any = useLoaderData();

    return (
        <React.Suspense fallback={<p>...</p>}>
            <Await resolve={data?.user}>
                {(user) => (
                    <div className="users-detail">
                        <div className="users-detail__back" onClick={() => navigate(-1)}>
                            <LeftOutlined /> Users
                        </div>
                        <div className="users-detail__header">
                            <div className="users-detail__header__info">
                                <ViewElement
                                    label="User name"
                                    description={user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : 'Unknown'}></ViewElement>
                            </div>
                        </div>
                        <PageWithTabsLayout tabs={TABS} />
                    </div>
                )}
            </Await>
        </React.Suspense>
    );
};
