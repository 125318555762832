import React from 'react';
import { Input } from '../components/Form/Input';
import { Col, Row } from 'react-bootstrap';
import { Phone, PhoneValue } from '../components/Form/Phone';
import './userformStyles.scss';

type Props = {
  required?: boolean;
  errorTexts: { [key: string]: string };
  onDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPhoneChange: (value: PhoneValue) => void;
};

export const UserForm: React.FC<Props> = React.memo(
  ({ required, errorTexts, onDataChange, onPhoneChange }) => {
    return (
      <div className="user-from">
        <Row>
          <Col md={6}>
            <Input
              id="userName"
              label="Name"
              placeholder="Name"
              name="name"
              type="text"
              required={required}
              onChange={onDataChange}
              error={errorTexts['name']}
            />
          </Col>
          <Col md={6}>
            <Input
              id="userSurname"
              label="Surname"
              placeholder="Surname"
              name="surname"
              type="text"
              required={required}
              onChange={onDataChange}
              error={errorTexts['surname']}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Input
              id="userEmail"
              label="Email"
              placeholder="Email"
              name="email"
              type="email"
              required={required}
              onChange={onDataChange}
              error={errorTexts['email']}
            />
          </Col>
          <Col md={6}>
            <Phone
              id="userPhone"
              name="userPhone"
              required={required}
              onChange={onPhoneChange}
              error={
                errorTexts['userPhone_number'] || errorTexts['userPhone_code']
              }
            />
          </Col>
        </Row>
        <Row>
          <Input
            id="userPosition"
            label="Position"
            placeholder="Position"
            name="position"
            type="text"
            required={required}
            onChange={onDataChange}
            error={errorTexts['position']}
          />
        </Row>
      </div>
    );
  },
);
