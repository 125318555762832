import { actions } from '../fetchProvider/fetch';
import {
    URL_NOTE_GET_POST_DELETE,
} from '../urls/urls';
import { api, transformResponse } from '../index';

export enum RelationTypes {
    CREDIT = 'Credit',
    ACCOUNT = 'Company',
}

export const notes = api.injectEndpoints({
    endpoints: (builder) => ({
        getNoteByEntity: builder.query<any, { id: string, type: RelationTypes }>({
            keepUnusedDataFor: 120,
            providesTags: (r, e, { id, type }) => [{ type: 'Notes', id: `${type}_${id}` }],
            query: ({ id, type}) => ({
                action: () => actions.get(URL_NOTE_GET_POST_DELETE.replace(':type', type).replace(':id', id)),
            }),
            transformResponse,
        }),
        saveNoteByEntity: builder.mutation<any, { id: string, type: RelationTypes, note: string }>({
            invalidatesTags: (r, e, { id, type }) => [{ type: 'Notes', id: `${type}_${id}` }],
            query: ({ id, type, note }) => ({
                action: () => actions.post(URL_NOTE_GET_POST_DELETE.replace(':type', type).replace(':id', id), {payloads: { note }})
            }),
            transformResponse,
        }),
        deleteNoteByEntity: builder.mutation<any, { id: string, type: RelationTypes }>({
            invalidatesTags: (r, e, { id, type }) => [{ type: 'Notes', id: `${type}_${id}` }],
            query: ({ id, type}) => ({
                action: () => actions.delete(URL_NOTE_GET_POST_DELETE.replace(':type', type).replace(':id', id)),
            }),
            transformResponse,
        }),
    })
});

export const {
    useGetNoteByEntityQuery,
    useLazyGetNoteByEntityQuery,
    useSaveNoteByEntityMutation,
    useDeleteNoteByEntityMutation,
} = notes;
