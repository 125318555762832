import axios from 'axios';
import { useEffect, useState } from 'react';
import { mockData } from './information.mock';
import './Information.scss';

const getContent = async () => {
  return axios
    .get('https://rsb.org/?rest_route=/wp/v2/pages/21791')
    .catch(e => {
      console.warn(e);
      return { data: mockData };
    });
};

function InformationContent({ content }: { content: any }) {
  return (
    <div
      className="c-information-content">
        <div className="c-information-content__container" dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  );
}

export function Information() {
  const [content, setContent] = useState<any>('');

  useEffect(() => {
    setContent('');
    getContent().then(({ data }) => {
      const content = data?.content?.rendered || '';
      setContent(content);
    });
  }, []);

  return (
      <div className="c-information">
          <div className="c-information__header">Information & documentation</div>
          {content && <InformationContent content={content} />}
      </div>
  );
}
