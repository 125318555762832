export const ACCOUNTS_TITLE = {
  approveDate: 'Date of confirmation',
  balance: 'Balance',
  name: 'Company name',
  address: 'Company address',
  city: 'City',
  country: 'Country',
  zip: 'Zip',
  type: 'Type of organization',
  users: 'Primary contact',
  status: 'Account status',
};

export const ACCOUNT_USERS_TITLE = {
  role: 'Role',
  SUID: 'SUID',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  position: 'Position',
  status: 'Status',
};
