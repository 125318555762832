import { memo } from 'react';
import './styles.scss';

export const Badge = memo(({ count }: { count: number }) => {
    return (
        <div className="c-badge">
            <div className="c-badge__number">{count < 100 ? count : '99+'}</div>
        </div>
    );
});
