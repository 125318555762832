import { CompanyType } from '../../../structure/models/company/company';
import { Account, AccountFields, AccountGeneral, AccountSupplier } from "../../../structure/models/user/user";

export const accountDataProcessing = (form: AccountFields): Account | AccountSupplier | AccountGeneral => {
    const formattedData = {
        ...form,
        companyPhone: { code: form.companyPhone_code, number: form.companyPhone_number },
        userPhone: { code: form.userPhone_code, number: form.userPhone_number}
    } as any;

    if (!formattedData.website) {
        delete formattedData.website;
    }

    const deleteSupplierFields = () => {
        delete formattedData.rsb;
        delete formattedData.certificateEndStart;
        delete formattedData.certificateEndDate;
    }
    const deleteGeneralFields = () => {
        delete formattedData.token;
    }

    delete formattedData.companyPhone_code;
    delete formattedData.companyPhone_number;
    delete formattedData.userPhone_code;
    delete formattedData.userPhone_number;
    delete formattedData.confirmPassword;
    delete formattedData.agreement;

    switch(form.accountType) {
        case CompanyType.Supplier: {
            deleteGeneralFields();
            return formattedData;
        }
        case CompanyType.Corporate:
        case CompanyType.Intermediary: {
            deleteSupplierFields();
            return formattedData;
        }
        default: {
            deleteSupplierFields();
            deleteGeneralFields();
            return formattedData;
        }
    }
};
