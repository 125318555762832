import React, { FC, memo, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TRANSFER_ROUTES } from '../../../../structure/routes/routes';
import { DefaultSelect } from '../../../components/Form/DefaultSelect/DefaultSelect';
import NextButton from '../../../components/Buttons/NextButton/NextButton';
import { FormikErrors, useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectReportingOptions } from '../../../../store/reducers/user/optionsReducer';
import { ConfirmationPrompt } from '../../../components/ConfirmationPrompt';
import { useGetInformationRequestsQuery, useUpdateInformationRequestMutation } from '../../../../API';
import { fetchCompanyData } from '../../../../API/user';
import { useNotification } from '../../../../hooks/useNotification';
import { COUNTRIES } from '../../../../constants/countries.constant';
import { CreditInfoRequestStatus } from '@prisma/client';
import { Collapse } from 'antd';
import BCUData, { getBCUInfoKeys } from '../../../components/BCUData/BCUData';

interface FormValues {
  creditClaimCountry: string;
  reporting: string;
  radioButton: string;
}

enum ACTIONS {
  APPROVE = 'Approve',
  DENY = 'Deny',
}

const CONFIRMATION_TEXT: Record<ACTIONS, string> = {
  [ACTIONS.APPROVE]: 'Are you sure you want to approve this info request?',
  [ACTIONS.DENY]: 'Are you sure you want to deny this info request?',
};

export const InfoRequestPage: FC = memo(() => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const reportingOptions = useAppSelector(selectReportingOptions);
  const [updateRequest] = useUpdateInformationRequestMutation();
  const { data } = useGetInformationRequestsQuery();
  const [show, setShow] = useState(false);
  const [action, setAction] = useState(ACTIONS.APPROVE);

  const item = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return data?.results?.find((item: any) => item.id == id);
  }, [data?.results, id]);

  const form = useFormik<FormValues>({
    initialValues: {
      creditClaimCountry: '',
      reporting: '',
      radioButton: '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validate: values => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.creditClaimCountry) {
        errors.creditClaimCountry = 'Country field is required.';
      }

      if (!values.reporting) {
        errors.reporting = 'Reporting field is required.';
      }

      if (!values.radioButton) {
        errors.radioButton = 'Please, choose an option.';
      }

      return errors;
    },
    onSubmit: () => {},
  });

  const handleDeny = async () => {
    const response: any = await updateRequest({
      id,
      action,
    });

    if (response.data && response.data.success) {
      toast.success({ message: 'Success', description: 'Information request has been denied' });
      dispatch(fetchCompanyData());
      return navigate(TRANSFER_ROUTES.INFO_REQUESTS);
    }

    if (response.error) {
      toast.error({ message: 'Error', description: response.error?.message || 'Unknown error' });
    }
  };

  const handleSubmit = async () => {
    const response: any = await updateRequest({
      id,
      action,
      creditClaimCountry: form.values.creditClaimCountry,
      reporting: form.values.reporting,
    });

    if (response.data && response.data.success) {
      toast.success({ message: 'Success', description: 'Information request has been approved' });
      return navigate(TRANSFER_ROUTES.INFO_REQUESTS);
    }

    if (response.error) {
      toast.error({ message: 'Error', description: response.error?.message || 'Unknown error' });
    }
  };

  const handleAction = async (action: ACTIONS) => {
    if (action === ACTIONS.APPROVE) {
      const errors = await form.validateForm();

      if (errors.creditClaimCountry || errors.reporting) {
        return;
      }
    }

    setAction(action);
    setShow(true);
  };

  const handleClose = (state: boolean) => {
    setShow(false);

    if (!state) {
      return;
    }

    switch (action) {
      case ACTIONS.DENY:
        return handleDeny();
      case ACTIONS.APPROVE:
      default:
        return handleSubmit();
    }
  };

  return (
    <>
      <div className="c-transfer">
        <div className="c-transfer__container">
          <form className="c-transfer__form">
            <div className="c-transfer__row">
              <div className="c-transfer__col">
                <div className="c-transfer__form__header">
                  Scope 1 Info Request
                </div>

                <Collapse size="middle" bordered={false} items={[
                  {
                    key: 1,
                    label: `${item?.credit?.BCUID}`,
                    children: (
                      <div style={{ backgroundColor: '#fff', padding: '12px 0' }}>
                        <BCUData data={item?.credit} editable={false} keysFn={getBCUInfoKeys} />
                      </div>
                    ),
                  }
                ]} />

                <DefaultSelect
                  inputId="creditClaimCountry"
                  name="creditClaimCountry"
                  required
                  label="Country where BCU is claimed"
                  error={form.errors.creditClaimCountry}
                  options={COUNTRIES as any}
                  onChange={(option: any) =>
                    form.setFieldValue(
                      'creditClaimCountry',
                      option ? option.value : '',
                    )
                  }
                  value={form.values.creditClaimCountry}
                />

                <DefaultSelect
                  inputId="reporting"
                  name="reporting"
                  required
                  label="Reporting"
                  error={form.errors.reporting}
                  options={reportingOptions as any}
                  onChange={(option: any) =>
                    form.setFieldValue('reporting', option ? option.value : '')
                  }
                  value={form.values.reporting}
                />

                <div className="c-transfer__radio mt-4">
                  <p className="c-transfer__radio__text">
                    Is this account the legitimate Scope 1 transport service
                    provider for the selected BCU ID and amount to appear on the
                    retirement statement?<span style={{ color: 'red' }}>*</span>
                  </p>
                  <label className="c-input-radio">
                    <input
                      className="c-input-radio__input"
                      type="radio"
                      name="radioButton"
                      value="Yes"
                      checked={form.values.radioButton === 'Yes'}
                      onChange={() => form.setFieldValue('radioButton', 'Yes')}
                    />
                    <span className="c-input-radio__text">Yes</span>
                  </label>
                  <label className="c-input-radio">
                    <input
                      className="c-input-radio__input"
                      type="radio"
                      name="radioButton"
                      value="No"
                      checked={form.values.radioButton === 'No'}
                      onChange={() => form.setFieldValue('radioButton', 'No')}
                    />
                    <span className="c-input-radio__text">No</span>
                  </label>
                  {form.errors.radioButton && (
                    <div
                      className="is-error"
                      style={{ margin: '10px 0 0 5px', fontSize: '12px' }}>
                      {form.errors.radioButton}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="align-right mt-5 d-flex justify-content-end">
              <NextButton
                handleClick={() => navigate(TRANSFER_ROUTES.INFO_REQUESTS)}
                text="Back"
                style={{
                  margin: '0 15px 0 0',
                  width: '100px',
                  color: '#5C6D76',
                  backgroundColor: '#455A641A',
                }}
              />
              <NextButton
                handleClick={() => handleAction(ACTIONS.DENY)}
                text="Deny"
                disabled={item?.status !== CreditInfoRequestStatus.Pending}
                style={{
                  margin: '0 15px 0 0',
                  width: '100px',
                  color: '#FF3D00',
                  opacity: item?.status !== CreditInfoRequestStatus.Pending ? '0.5' : '1',
                  backgroundColor: '#FF3D001A',
                }}
              />
              <NextButton
                handleClick={() => handleAction(ACTIONS.APPROVE)}
                disabled={
                  item?.status !== CreditInfoRequestStatus.Pending || form.values.radioButton !== 'Yes' || !form.values.creditClaimCountry || !form.values.reporting
                }
                text="Approve"
                style={{ margin: '0', width: '100px' }}
              />
            </div>
          </form>
        </div>
      </div>

      <ConfirmationPrompt onClosed={handleClose} show={show}>
        <p>{CONFIRMATION_TEXT[action]}</p>
      </ConfirmationPrompt>
    </>
  );
});
