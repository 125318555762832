import './fileuploadStyles.scss'
import { ChangeEvent, MouseEvent, FC, useRef } from 'react';
import { setFileData, setFileDataTwoStep, setFileUploaded, setFileUploadedTwoStep} from '../../../../../store/reducers/user/bcuFormReducer';
import { useAppDispatch } from '../../../../../store/hooks';

interface FileUploadComponentProps {
  onFileUpload: (file: File) => void;
  label: string;
}

const FileUploadComponent: FC<FileUploadComponentProps> = ({ onFileUpload, label }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

 const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
          onFileUpload(selectedFile);
        if (label === 'PoS pdf upload of end product') {
          dispatch(setFileData(selectedFile.name));
          dispatch(setFileUploaded(true));
        } else if (label === 'PoS pdf upload') {
          dispatch(setFileDataTwoStep(selectedFile.name));
          dispatch(setFileUploadedTwoStep(true));
        }
    }
  };

  return (
    <div className="drop-area">
      <button className="btn-up" onClick={handleButtonClick}>
        <span className="btn-up-text">Upload pdf</span>
      </button>
      <input
        type="file"
        accept="application/pdf"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  )
};

export default FileUploadComponent;
