import React, { useEffect, useState } from 'react';
import { Button, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadChangeParam } from 'antd/es/upload/interface';
import { useNotification } from '../../../hooks/useNotification';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { UploadOutlined } from '@ant-design/icons';

export type UploadAttachment = UploadFile & { id: number };

export const Attachment: React.FC<{ files: UploadAttachment[]; isLoading?: boolean; readonly?: boolean, handleOpen: (file: UploadAttachment) => void; handleRemove: (file: UploadAttachment) => Promise<boolean>; handleUpload: (file: RcFile) => Promise<boolean>; onChange?: (info: UploadChangeParam<UploadAttachment>) => void;  } & UploadProps> = ({ handleUpload, handleRemove, handleOpen, files, isLoading, onChange, readonly = false, ...rest }) => {
    const notification = useNotification();
    const [handler, setHandler] = useState<any>(undefined);
    const [fileList, setFileList] = useState<UploadAttachment[]>([]);

    useEffect(() => {
        setFileList(files.map((file) => ({
            ...file,
            status: 'done',
            url: '#',
        })));
    }, [files]);

    const props: UploadProps = {
        ...rest,
        onRemove: (file) => {
            const h = () => {
                setHandler(undefined);

                handleRemove(file as UploadAttachment).finally(() => {
                    notification.success({ message: 'Success', description: 'File has been removed.' });
                });
            };

            setHandler(() => h);

            return false;
        },
        onPreview: (file) => {
            handleOpen(file as UploadAttachment);

            return false;
        },
        beforeUpload: (file) => {
            handleUpload(file).then((result: any) => {
                if (result.id) {
                    notification.success({ message: 'Success', description: 'File has been uploaded.' });
                } else {
                    notification.error({ message: 'Error', description: 'File has not been uploaded due to error.' });
                }
            });

            return false;
        },
        fileList,
    };

    return (
        <div style={{margin: '20px 0'}}>
            <ConfirmationPrompt onClosed={(state) => state ? (handler ? handler() : null) : setHandler(undefined)} show={!!handler}>
                <p>
                    Are you sure you want to delete this file?
                </p>
            </ConfirmationPrompt>

            <Upload
                {...props}
                disabled={readonly || isLoading}
            >
                {!readonly && (!props.maxCount || (props.fileList || []).length < props.maxCount) && <Button type="primary" loading={isLoading} icon={<UploadOutlined />}>Upload</Button>}
            </Upload>
        </div>
    );
};