import {
  LoginCredit,
  LoginOtpResponse,
  LoginTokenResponse,
} from '../structure/request/login';
import { actions } from './fetchProvider/fetch';
import { AppThunk } from '../store/store';
import { changeToken, logout, reset } from '../store/reducers/user/userReducer';
import {
    URL_LOGIN,
    URL_LOGOUT,
    URL_FORGOT_PASSWORD,
    URL_REFRESH_TOKEN,
    URL_REGISTRATION,
    URL_SET_PASSWORD,
    URL_CHANGE_PASSWORD,
    URL_CONFIRM_EMAIL,
    URL_RESET_PASSWORD,
    URL_VALIDATE_INVITE_TOKEN,
} from './urls/urls';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
  ChangePasswordData,
  SetPasswordData,
} from '../structure/request/change-password';
import { ServerResponse } from './types';
import { TokenResponse } from './token';
import { api } from './index';

export const refreshToken = (): AppThunk<Promise<void>> => dispatch => {
  return actions
    .get<AxiosResponse<ServerResponse<TokenResponse>>>(URL_REFRESH_TOKEN)
    .then(token => {
      dispatch(changeToken(token.data.data.accessToken));
    }).catch((e) => {
      console.warn(e);
    });
};

export const fetchRegistration =
  (registrationData: any): AppThunk<Promise<void>> =>
  dispatch => {
      return actions.post<string>(URL_REGISTRATION, {
          payloads: registrationData,
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      }).then(token => {
          dispatch(changeToken(token));
      });
  };

export const fetchSetPassword =
  (payloads: SetPasswordData): AppThunk<Promise<void>> =>
  () => {
    return actions.post<void>(URL_SET_PASSWORD, {
      payloads,
    });
  };

export const fetchResetPassword =
  (payloads: SetPasswordData): AppThunk<Promise<void>> =>
  () => {
    return actions.post<void>(URL_RESET_PASSWORD, {
      payloads,
    });
  };

export const fetchChangePassword =
  (payloads: ChangePasswordData): AppThunk<Promise<void>> =>
  () => {
    return actions.post<void>(URL_CHANGE_PASSWORD, {
      payloads,
    });
  };

export const fetchForgotPassword =
  (email: string): AppThunk<Promise<void>> =>
  () => {
    return actions.post<void>(URL_FORGOT_PASSWORD, {
      payloads: {
        email,
      },
    });
  };

export const fetchLogin =
  (
    loginCredit: LoginCredit,
  ): AppThunk<AxiosPromise<LoginTokenResponse | LoginOtpResponse>> =>
  dispatch => {
    return actions
      .post<AxiosResponse<LoginTokenResponse | LoginOtpResponse>>(URL_LOGIN, {
        payloads: loginCredit,
      })
      .then((resp: AxiosResponse<LoginTokenResponse | LoginOtpResponse>) => {
        // after entered correct OTP
        if (typeof resp.data.data !== 'string') {
          // Reset state
          dispatch(reset());

          dispatch(changeToken(resp.data.data.accessToken));

          // Invalidate rtk-query cache
          dispatch(api.util?.resetApiState());
        }

        return resp;
      });
  };

export const fetchLogOut = (): AppThunk<Promise<unknown>> => dispatch => {
  return actions
    .post(URL_LOGOUT)
    .then(() => {
      // Invalidate rtk-query cache
      dispatch(api.util?.resetApiState());
    }).catch((e) => {
      console.warn(e);
    })
    .finally(() => {
      dispatch(logout());
    });
};

export const fetchConfirmEmail =
  (token: string): AppThunk<AxiosPromise<ServerResponse<string>>> =>
  () => {
    return actions.post<AxiosResponse<ServerResponse<string>>>(
      URL_CONFIRM_EMAIL,
      {
        payloads: { token },
      },
    );
  };

export const fetchValidateInviteToken = (token: string): AppThunk<AxiosPromise<ServerResponse<boolean>>> => () => {
    return actions.get<AxiosResponse<ServerResponse<boolean>>>(URL_VALIDATE_INVITE_TOKEN, {
        queries: { token },
    });
}