import { ActionLogType } from '@prisma/client';

export const SystemLogTypes = [
  ActionLogType.AccountSuspended,
  ActionLogType.EmailLinkSent,
  ActionLogType.InactivityLogout,
  ActionLogType.CreditStatusChanged,
  ActionLogType.CreditInfoExpired,
  ActionLogType.UserStatusChanged,
  ActionLogType.RetirementStatementCreated,
  ActionLogType.EmailSent,
];

export const ACTION_LOGS_OPTIONS: Array<{
  value: ActionLogType | null;
  type: 'User' | 'System' | null;
  label: string;
}> = [
  { value: null, type: null, label: 'All' },
  {
    label: 'Account registered',
    type: 'User',
    value: ActionLogType.AccountRegistered,
  },
  {
    label: 'Account approved',
    type: 'User',
    value: ActionLogType.AccountApproved,
  },
  {
    label: 'Account changed',
    type: 'User',
    value: ActionLogType.AccountChanged,
  },
  {
    label: 'Account suspended',
    type: 'System',
    value: ActionLogType.AccountSuspended,
  },
  {
    label: 'Account restored',
    type: 'User',
    value: ActionLogType.AccountRestored,
  },
  {
    label: 'Account linked',
    type: 'User',
    value: ActionLogType.AccountLinked,
  },
  //
  {
    label: 'User invited',
    type: 'User',
    value: ActionLogType.UserInvited,
  },
  {
    label: 'User invite cancelled',
    type: 'User',
    value: ActionLogType.UserInviteCancelled,
  },
  {
    label: 'User registered',
    type: 'User',
    value: ActionLogType.UserRegistered,
  },
  {
    label: 'User approved',
    type: 'User',
    value: ActionLogType.UserApproved,
  },
  {
    label: 'User removed',
    type: 'User',
    value: ActionLogType.UserRemoved,
  },
  {
    label: 'Account owner change requested',
    type: 'User',
    value: ActionLogType.AccountOwnerDataChangeRequested,
  },
  {
    label: 'Account owner change approved',
    type: 'User',
    value: ActionLogType.AccountOwnerDataChangeApproved,
  },
  {
    label: 'User changed',
    type: 'User',
    value: ActionLogType.UserChanged,
  },
  //
  {
    label: 'Email verification link sent',
    type: 'System',
    value: ActionLogType.EmailLinkSent,
  },
  {
    label: 'Email confirmed',
    type: 'User',
    value: ActionLogType.EmailVerified,
  },
  //
  {
    label: 'Token created',
    type: 'User',
    value: ActionLogType.TokenCreated,
  },
  {
    label: 'Token submitted',
    type: 'User',
    value: ActionLogType.TokenSubmitted,
  },
  //
  {
    label: 'Contact removed',
    type: 'User',
    value: ActionLogType.ContactRemoved,
  },
  //
  {
    label: 'Password created',
    type: 'User',
    value: ActionLogType.PasswordCreated,
  },
  {
    label: 'Password changed',
    type: 'User',
    value: ActionLogType.PasswordChanged,
  },
  {
    label: 'Password reset',
    type: 'User',
    value: ActionLogType.PasswordReset,
  },
  //
  {
    label: 'Login',
    type: 'User',
    value: ActionLogType.Login,
  },
  {
    label: 'Logout',
    type: 'User',
    value: ActionLogType.Logout,
  },
  {
    label: 'Logout (after reaching the inactivity time limit)',
    type: 'System',
    value: ActionLogType.InactivityLogout,
  },
  //
  {
    label: 'Contact form submitted',
    type: 'User',
    value: ActionLogType.UserContacted,
  },
  //
  {
    label: 'BCU info changed',
    type: 'User',
    value: ActionLogType.CreditChanged,
  },
  //
  {
    label: 'BCU Scope 1 info requested',
    type: 'User',
    value: ActionLogType.CreditInfoRequested,
  },
  {
    label: 'BCU Scope 1 info approved',
    type: 'User',
    value: ActionLogType.CreditInfoApproved,
  },
  {
    label: 'BCU Scope 1 info denied',
    type: 'User',
    value: ActionLogType.CreditInfoDenied,
  },
  {
    label: 'BCU Scope 1 info request expired',
    type: 'System',
    value: ActionLogType.CreditInfoExpired,
  },
  //
  {
    label: 'BCU registration requested',
    type: 'User',
    value: ActionLogType.CreditRegistrationRequested,
  },
  {
    label: 'BCU transfer requested',
    type: 'User',
    value: ActionLogType.CreditTransferRequested,
  },
  {
    label: 'BCU retirement requested',
    type: 'User',
    value: ActionLogType.CreditRetirementRequested,
  },
  //
  {
    label: 'BCU registration approved',
    type: 'User',
    value: ActionLogType.CreditRegistrationApproved,
  },
  {
    label: 'Credit transfer approved',
    type: 'User',
    value: ActionLogType.CreditTransferApproved,
  },
  {
    label: 'Credit transfer accepted (receiver)',
    type: 'User',
    value: ActionLogType.CreditTransferAccepted,
  },
  {
    label: 'Credit transfer rejected (receiver)',
    type: 'User',
    value: ActionLogType.CreditTransferRejected,
  },
  {
    label: 'BCU retirement approved',
    type: 'User',
    value: ActionLogType.CreditRetirementApproved,
  },
  //
  {
    label: 'BCU registration denied',
    type: 'User',
    value: ActionLogType.CreditRegistrationDenied,
  },
  {
    label: 'BCU transfer denied',
    type: 'User',
    value: ActionLogType.CreditTransferDenied,
  },
  {
    label: 'BCU retirement denied',
    type: 'User',
    value: ActionLogType.CreditRetirementDenied,
  },
  //
  {
    label: 'Retirement statement created',
    type: 'System',
    value: ActionLogType.RetirementStatementCreated,
  },
  //
  {
    label: 'Note added/updated',
    type: 'User',
    value: ActionLogType.NoteAdded,
  },
  {
    label: 'Note removed',
    type: 'User',
    value: ActionLogType.NoteRemoved,
  },
  //
  {
    label: 'Attachment file added',
    type: 'User',
    value: ActionLogType.AttachmentAdded,
  },
  {
    label: 'Attachment file removed',
    type: 'User',
    value: ActionLogType.AttachmentRemoved,
  },
  //
  {
    label: 'BCU status changed',
    type: 'System',
    value: ActionLogType.CreditStatusChanged,
  },
  //
  {
    label: 'User status changed',
    type: 'System',
    value: ActionLogType.UserStatusChanged,
  },
  {
    label: 'Email notification sent',
    type: 'System',
    value: ActionLogType.EmailSent,
  },
];
