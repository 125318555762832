import classNames from 'classnames';
import React from 'react';
import {
  DATE_WITH_FULL_MONTH,
  formatDate,
} from '../../../../../core/models/contstants/format-date';
import { TransactionType } from '../../../../../../API/admin/submissions';
import { SubmissionsType } from '../../../../../../structure/request/admin';

export const transactionTypes = {
  Register: SubmissionsType.BCURegistration,
  Transfer: SubmissionsType.BCUTransfer,
  Retirement: SubmissionsType.BCURetirement,
};

export const COLUMNS = (id: string) => [
  {
    title: 'Date and time',
    sort: true,
    keyItem: 'approveDate',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__header">
          {item?.approveDate
            ? formatDate(item?.approveDate, DATE_WITH_FULL_MONTH)
            : ''}
        </div>
      </div>
    ),
  },
  {
    title: 'From/by',
    keyItem: 'fromBy',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__details">
          {item?.company?.name}
        </div>
      </div>
    ),
  },
  {
    title: 'To',
    keyItem: 'to',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__details">
          {item?.receiver?.name}
        </div>
      </div>
    ),
  },
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__details">
          {item?.newCredit?.BCUID || item?.credit?.BCUID}
        </div>
      </div>
    ),
  },
  {
    title: 'User',
    keyItem: 'user',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__details">
          {item?.sender?.firstname} {item?.sender?.lastname}
        </div>
      </div>
    ),
  },
  {
    title: 'Transaction type',
    keyItem: 'type',
    render: (item: any) => (
      <div className="admin-transactions__info">
        {transactionTypes[item.type as keyof typeof transactionTypes]}
      </div>
    ),
  },
  {
    title: 'Volume',
    keyItem: 'amount',
    render: (item: any) => {
      const plus =
        item.type === TransactionType.Register || id === item.receiver?.id;

      return (
        <div className="admin-transactions__info">
          <div>
            {plus ? '+' : '-'}
            {parseFloat(item?.amount || '0').toLocaleString('en-US', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}{' '}
            BCU
          </div>
        </div>
      );
    },
  },
  {
    title: 'Status',
    keyItem: 'BCUID',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__details">
          <span
            className={classNames('admin-transactions__status', {
              approved: item?.isAdminApproved,
              rejected: !item?.isAdminApproved,
            })}>
            {item?.isAdminApproved ? 'Approved' : 'Rejected'}
          </span>
        </div>
      </div>
    ),
  },
];
