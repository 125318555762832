import { Credit, User, Company, TransactionType, TransactionStatus } from '@prisma/client';

export { TransactionType } from '@prisma/client';

export const TransactionTypeOptions = [
  { value: TransactionType.Register, label: 'BCU registration' },
  { value: TransactionType.Transfer, label: 'BCU transfer' },
  { value: TransactionType.Retirement, label: 'BCU retirement' },
  // Additional type for front end use only
  { value: 'Scope1', label: 'Scope 1 Information request' },
];

export type Transaction = {
  id: string;
  credit: Pick<Credit, 'id' | 'BCUID'>;
  newCredit?: Pick<Credit, 'id' | 'BCUID'>;
  status: TransactionStatus;
  company: Pick<Company, 'id' | 'name'>;
  sender: Pick<User, 'firstName' | 'lastName' | 'type' | 'id'>;
  receiver: Pick<Company, 'id' | 'name'> | null;
  createdAt: string;
  user: User;
  amount: number;
}
