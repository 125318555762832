import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useGroups } from '../../../../components/UserBalance/Tabs/ScopeInfo';
import { useParams } from 'react-router-dom';
import { selectIncomingCredits } from '../../../../../store/reducers/user/creditsReducer';
import { fetchIncomingCredits } from '../../../../../API/credits';
import DataTable from '../../../../components/DataTable/DataTable';

export const Scope3: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const data = useAppSelector(selectIncomingCredits);

  // eslint-disable-next-line eqeqeq
  const currentBCU = useMemo(() => {
    if (Array.isArray(data) && id) {
      const transaction = data.find((i) => i.id.toString() === id);
      
      if (transaction) {
        return transaction.newCredit || transaction.credit;
      }
      
      return null;
    }
    return null;
  }, [data, id]);

  useEffect(() => {
    dispatch(fetchIncomingCredits());
  }, []);

  const groups = useGroups(currentBCU, 'scope3');

  if (!currentBCU) return null;

  return (
    <div style={{ margin: '20px 0 30px' }}>
      <div className="dashboard-bcu-info__table">
        <DataTable data={currentBCU} edit={false} columns={groups} />
      </div>
    </div>
  );
};
