import { KeyboardEvent } from 'react';

export const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
  const keyValue = event.key;

  const regex = /^[0-9]+$/;
  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
  if (event.currentTarget.value.length >= 3) {
    event.preventDefault();
  }
  if (parseInt(event.currentTarget.value + keyValue) > 100) {
    event.preventDefault();
  }
};

export const handleEnterLatinLetters = (
  event: KeyboardEvent<HTMLInputElement>,
) => {
  const keyValue = event.key;
  const regex = /^[a-zA-Z\s]+$/;

  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
};

export const handleEnterNumbers = (event: KeyboardEvent<HTMLInputElement>) => {
  const keyValue = event.key;

  if (['Backspace', 'ArrowLeft', 'ArrowRight'].indexOf(keyValue) > -1) {
    // Allowed keys
    return;
  }

  const regex = /^[0-9\.\-_/.,]+$/;

  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
};
export const handleEnterLatinAndNumbers = (
  event: KeyboardEvent<HTMLInputElement>,
) => {
  const keyValue = event.key;
  const regex = /^[a-zA-Z0-9.,\-_\s]+$/;

  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
};

export const handleEnterNumbersAndSlash = (
    event: KeyboardEvent<HTMLInputElement>,
) => {
  const keyValue = event.key;

  if (['Backspace', 'ArrowLeft', 'ArrowRight'].indexOf(keyValue) > -1) {
    // Allowed keys
    return;
  }

  const regex = /^[0-9\-\s\/]+$/;

  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
};

export const handleEnterLatinAndNumbersAndSlash = (
    event: KeyboardEvent<HTMLInputElement>,
) => {
  const keyValue = event.key;

  if (['Backspace', 'ArrowLeft', 'ArrowRight'].indexOf(keyValue) > -1) {
    // Allowed keys
    return;
  }

  const regex = /^[a-zA-Z0-9.,\-_\s\/]+$/;

  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
};
