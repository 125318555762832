import { LoginCredit } from '../../../structure/request/login';
import {
  AllEntries,
  validationFactory,
  Validators,
} from '../../core/helpers/validationFactory';

export const getFormLoginData = (formData: FormData): LoginCredit => {
  const email = formData.get('email')?.toString() ?? '';
  const password = formData.get('password')?.toString() ?? '';
  const otp = formData.get('otp')?.toString();

  return {
    email,
    password,
    ...(otp ? { otp } : {}),
  };
};

const { createValidator, createValidate } = validationFactory();

const validators: Validators = {
  email: createValidator(
    (value: string) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      ),
    'Email must have correct format.',
  ),
  password: createValidator(
    (value: string) =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value),
    'Password must contain 8 characters, a special sign, number, lower case letter, and capital letter.',
  ),
  otp: createValidator(
    (value: string, allEntries: AllEntries) =>
      allEntries.length === 2 || value.length === 5,
    'OTP must be a number from email.',
  ),
};

export const validate = createValidate(validators);
