import React, { FC, useState } from 'react';
import './EditButtons.scss';

export const EditButtons: FC<{
    isEdit: boolean;
    onEdit: () => void;
    onSave: () => Promise<boolean>;
    onCancel: () => void;
}> = ({ isEdit, onEdit, onSave, onCancel }) => {
    const [_isEdit, setIsEdit] = useState(isEdit);

    const handleEdit = () => {
        setIsEdit(true);
        onEdit();
    };

    const handleSave = () => {
        onSave().then((isSuccessful) => {
            if (isSuccessful) {
                setIsEdit(false);
            }
        });
    };

    const handleCancel = () => {
        setIsEdit(false);
        onCancel();
    };

    return (
        <div className="c-edit-buttons">
            {_isEdit && (
                <>
                    <button className="c-edit-buttons__button c-edit-buttons__button--cancel" type="button"
                            onClick={handleCancel}><span className="c-edit-buttons__button-text">Cancel</span></button>
                    <button className="c-edit-buttons__button c-edit-buttons__button--save" type="button"
                            onClick={handleSave}><span className="c-edit-buttons__button-text">Save changes</span>
                    </button>
                </>
            )}
            {!_isEdit && (
                <button className="c-edit-buttons__button c-edit-buttons__button--edit" type="button"
                        onClick={handleEdit}><span className="c-edit-buttons__button-text">Edit</span></button>
            )}
        </div>
    );
};