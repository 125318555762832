import { actions } from './fetchProvider/fetch';
import { AppThunkDispatch } from '../store/store';
import { setUserAccountData } from '../store/reducers/user/userReducer';
import { URL_COMPANY_INFO, URL_COMPANY_INFO_ID } from './urls/urls';
import { setSelectedRowContact } from '../store/reducers/user/rowReducer';

export const fetchCompanyInfo = () => (dispatch: AppThunkDispatch) => {
  return actions
    .get(URL_COMPANY_INFO)
    .then((resp: any) => dispatch(setUserAccountData(resp.data.data)));
};

export const fetchCompanyInfoId =
  (id: string) => (dispatch: AppThunkDispatch) => {
    const urlWithId = URL_COMPANY_INFO_ID.replace(':id', id);
    return actions
      .get(urlWithId)
      .then((resp: any) => dispatch(setSelectedRowContact(resp.data.data)));
  };
