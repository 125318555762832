import { CreditEvent } from '@prisma/client';

export const ALLOWED_BCU_TITLE = [
  'productType',
  'supplierName',
  'LCAGHGValue',
  'neatAmountInMJ',
  'volume',
  'certificationScheme',
  'GHGEmissionReduction',
  'directLandUseChangeDisaggregatedValue',
  'feedstockConversionProcess',
  'fieldBatchCountry',
  'fieldDec',
  'neatSAFProductionDate',
  'creditClaimCountry',
  'reporting',
  'tonnesOfCO2eq',
  'rawMaterial',
  'countryOfPhysicalEndProductDelivery',
  'airportOfPhysicalEndProductDelivery',
];

export const BCU_EVENTS: Record<CreditEvent, string> = {
  [CreditEvent.RegistrationSubmitted]: 'Registration Submitted',
  [CreditEvent.RegistrationApproved]: 'Registration Approved',
  [CreditEvent.RegistrationRejected]: 'Registration Rejected',
  [CreditEvent.TransferSubmitted]: 'Transfer Submitted',
  [CreditEvent.TransferApproved]: 'Transfer Approved',
  [CreditEvent.TransferRejected]: 'Transfer Rejected',
  [CreditEvent.DepositApproved]: 'Deposit Approved',
  [CreditEvent.DepositRejected]: 'Deposit Rejected',
  [CreditEvent.RetirementSubmitted]: 'Retirement Submitted',
  [CreditEvent.RetirementApproved]: 'Retirement Approved',
  [CreditEvent.RetirementRejected]: 'Retirement Rejected',
  [CreditEvent.Scope1InfoRequestSubmitted]: 'Scope 1 Request Submitted',
  [CreditEvent.Scope1InfoRequestApproved]: 'Scope 1 Request Approved',
  [CreditEvent.Scope1InfoRequestRejected]: 'Scope 1 Request Rejected',
  [CreditEvent.Split]: 'Split',
  [CreditEvent.Merge]: 'Merge',
  [CreditEvent.Copy]: 'Copy',
};
