import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsCompany } from '../../../../../store/reducers/admin/submissionsReducer';
import { actions } from '../../../../../API/fetchProvider/fetch';
import { URL_COMPANY_DOWNLOAD_SIGNED_LETTER } from '../../../../../API/urls/urls';
import fileDownload from 'js-file-download';
import { useNotification } from '../../../../../hooks/useNotification';
import CompanyInfoData from '../../../../components/CompanyInfoData/CompanyInfoData';
import { fetchEditCompany } from '../../../../../API/admin/accounts';
import { useParams } from 'react-router-dom';

export const AdminCompanyInfo: React.FC<{ fetchData?: (id: string) => any; }> = ({ fetchData }) => {
  const { id } = useParams();
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectSubmissionsCompany);

  if (!company) return null;

  const handleTransformAndDispatchData = async (data: any) => {
        try {
            await dispatch(fetchEditCompany(data));

            toast.success({ message: 'Success', description: 'Account has been updated' });

            if (id && fetchData) {
                await dispatch(fetchData(id));
            }

            return true;
        } catch (error: any) {
            const message = error?.response?.data?.message || [];

            toast.error({ message: 'Error', description: (<>
                    Account update failed{message.length > 0 ? ':' : ''}
                    {message.length > 0 && (<ul style={{marginTop:10, marginBottom: 0, padding: 0}}>
                        {message.map((m: string, idx: number) => <li key={idx}>{m}</li>)}
                    </ul>)}
                </>) });

            return false;
        }
    };

  const onDownloadLetter = async () => {
    const result: { data: Blob } | undefined = (await actions
      .get(URL_COMPANY_DOWNLOAD_SIGNED_LETTER.replace(':id', company.id.toString()), {
        responseType: 'blob',
      } as any)
      .catch(({ response }) => {
        toast.error({ message: 'Error', description: response.statusText });
      })) as any;

    if (result?.data) {
      fileDownload(result?.data, `signed_letter_${company.id}.pdf`);
      toast.success({ message: 'Success', description: 'The file has been downloaded successfully' });
    }
  };

  return (
      <div style={{ marginTop: 24 }}>
          <CompanyInfoData
              editable
              data={company}
              onSave={(data) => handleTransformAndDispatchData(data)}
              onDownloadSignedLetter={onDownloadLetter}
          />
      </div>
  );
};
