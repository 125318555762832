import { EditableColumn } from '../../../../components/Table/Table';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { ErrorBlocks } from '../../../../components/Form/FormHook';
import StatusComponent from '../../../../components/StatusComponent';
import { limitOfNumbers } from '../../../../../helpers/limitOfNumbers';
import { Prisma } from '@prisma/client';

export const BCU_LIST_COLUMNS_LIST = (
  showErrorsBlock: ErrorBlocks,
): EditableColumn<
  Prisma.CreditGetPayload<{
    include: {
      company: {
        select: {
          name: true;
        };
      };
      scope1Company: {
        select: {
          name: true;
        };
      };
      posFiles: {
        include: {
          attachment: true;
        };
      };
      supplierFiles: {
        include: {
          attachment: true;
        };
      };
    };
  }>
>[] => {
  return [
    {
      title: 'Date & time',
      keyItem: 'createdAt',
      sort: true,
      render: item => {
        return item.createdAt
          ? formatDate(item.createdAt, DATE_WITH_TIME_FORMAT)
          : 'Pending for approval';
      },
    },
    {
      title: 'BCU ID',
      keyItem: 'BCUID',
      sort: true,
      render: item => {
        return item.BCUID;
      },
    },
    {
      title: 'BCU Holder',
      keyItem: 'bcuHolder',
      render: item => item.bcuHolder,
    },
    {
      title: 'Product type',
      keyItem: 'productType',
      sort: true,
      render: item => item.productType,
    },
    {
      title: 'Amount of product (t)',
      sort: true,
      keyItem: 'volume',
      render: item => limitOfNumbers(item.volume),
      meta: {
        style: {
          textAlign: 'right',
          paddingRight: '75px',
        },
      },
    },
    {
      title: 'Amount of product (MJ)',
      sort: true,
      keyItem: 'neatAmountInMJ',
      render: item => limitOfNumbers(item.neatAmountInMJ, 0),
      meta: {
        style: {
          textAlign: 'right',
          paddingRight: '75px',
        },
      },
    },
    {
      title: 'Status',
      sort: true,
      keyItem: 'status',
      render: item => (
        <StatusComponent statusOfComponent={item.status as any} />
      ),
    },
  ];
};
