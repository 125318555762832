import React, { ComponentType, FC } from 'react';
import {
    Navigate,
    RouteObject,
    useRouteError,
} from 'react-router-dom';
import {
    ACCOUNTS_FEES,
    ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES,
    ADMIN_ACCOUNTS_AND_BALANCE_ROUTES,
    ADMIN_BCU_DETAILS_ROUTES,
    ADMIN_BCUS,
    ADMIN_LOGS,
    ADMIN_REFERENCE_VALUES,
    ADMIN_REFERENCE_VALUES_ROUTES,
    ADMIN_SUBMISSIONS_LIST,
    ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
    ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
    ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
    ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
    ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES,
    ADMIN_USERS,
    ADMIN_USERS_DETAILS,
    ADMIN_USERS_DETAILS_ROUTES,
    ANY,
    BCU_DETAILS_ROUTES,
    CHANGE_PASSWORD,
    COMPANY_PROFILE,
    CONTACT_LIST,
    CONTACT_US,
    DASHBOARD,
    DASHBOARD_ROUTES,
    INFORMATION,
    FORGOT_PASSWORD,
    LOGIN,
    PROFILE_INFO,
    PROFILE_ROUTES,
    REGISTER_CREDIT,
    REGISTRATION,
    RESET_PASSWORD,
    RETIREMENT,
    RETIREMENT_ROUTES,
    SET_PASSWORD,
    STATEMENTS,
    TRANSFER,
    TRANSFER_ROUTES,
    USER_INFO,
    USER_PROFILE,
    VERIFY_EMAIL,
} from './structure/routes/routes';
import { Dashboards } from './app/dashboards/Dashboards';
import { Transactions } from './app/transactions/Transactions';
import { Logs } from './app/dashboards/AdminDashboard/Logs/Logs';
import { Transfer } from './app/transfer/Transfer';
import { Retirement } from './app/retirement/Retirement';
import { Statements } from './app/home/Statements';
import { Registration } from './app/registration/Registration';
import { ForgotPassword } from './app/login/ForgotPassword';
import { Login } from './app/login/Login';
import { guard } from './app/core/HOC/guard';
import { pageAccesses } from './app/core/models/contstants/page-accesses';
import { MainLayout } from './app/layouts/MainLayout';
import { Profiles } from './app/profiles/Profiles';
import { BCURegistration } from './app/dashboards/UserDashboard/BCURegistration/BCURegistration';
import { SubmissionList } from './app/dashboards/AdminDashboard/SubmissionList/SubmissionList';
import { EmailVerification } from './app/email-verification/EmailVerification';
import { AdminCompanyInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminCompanyInfo';
import { AdminUsersInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminUsersInfo';
import { UserInfo } from './app/profiles/UserInfo/UserInfo';
import { CompanyInfo } from './app/profiles/CompanyInfo/CompanyInfo';
import { SetPassword } from './app/login/SetPassword';
import { ResetPassword } from './app/login/ResetPassword';
import { ChangePassword } from './app/login/ChangePassword';
import { Note } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/Note';
import { AdminRegisterLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminRegisterLayout';
import { AdminTransactionsLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminTransactionsLayout';
import { AdminBCUInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminBCUInfo';
import { AdminTransfersLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminTransfersLayout';
import { AdminTransferInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminTransferInfo';
import { ContactUs } from './app/contact-us/ContactUs';
import { AdminRetirementsLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminRetirementsLayout';
import { AdminRetirementInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminRetirementInfo';
import { Users } from './app/dashboards/AdminDashboard/Users/Users';
import { Accounts } from './app/dashboards/AdminDashboard/Accounts/Accounts';
import { BCUList } from './app/dashboards/AdminDashboard/BCUList/BCUList';
import { BCUInfo } from './app/dashboards/AdminDashboard/BCUList/BCUInfo';
import { InfoBCU } from './app/dashboards/AdminDashboard/BCUList/Tabs/InfoBCU';
import { BCUInfo as IncomingTransactionBCUInfo } from './app/transfer/Pages/IncomingTransactionPage/tabs/BCUInfo';
import { Scope1 as IncomingTransactionScope1 } from './app/transfer/Pages/IncomingTransactionPage/tabs/Scope1';
import { Scope3 as IncomingTransactionScope3 } from './app/transfer/Pages/IncomingTransactionPage/tabs/Scope3';
import { ReferenceValues } from './app/dashboards/AdminDashboard/ReferenceValues/ReferenceValues';
import {
    CreateCertificationSchemes,
} from './app/dashboards/AdminDashboard/ReferenceValues/Tabs/CreateCertificationSchemes';
import { useAppSelector } from './store/hooks';
import { selectUser } from './store/reducers/user/userReducer';
import { PageWithTabsLayout } from './app/layouts/PageWithTabsLayout';
import { UserBalance } from './app/components/UserBalance/UserBalance';
import ContactList from './app/components/ContactList';
import { InfoRequestsPage } from './app/transfer/Pages/InfoRequestsPage';
import { InfoRequestPage } from './app/transfer/Pages/InfoRequestPage';
import { Badge } from './app/components/Badge';
import {
    useCountIncomingCreditQuery,
    useCountInformationRequestsQuery,
} from './API';
import { NoteBCU } from './app/dashboards/AdminDashboard/BCUList/Tabs/NoteBCU';
import { AccountCompanyInfo } from './app/dashboards/AdminDashboard/Accounts/Tabs/AccountCompanyInfo';
import { AccountDetail } from './app/dashboards/AdminDashboard/Accounts/AccountDetail';
import { AccountUserInfo } from './app/dashboards/AdminDashboard/Accounts/Tabs/AccountUserInfo';
import { AccountHistory } from './app/dashboards/AdminDashboard/Accounts/Tabs/AccountHistory';
import { AccountBalance } from './app/dashboards/AdminDashboard/Accounts/Tabs/AccountBalance';
import { AccountNote } from './app/dashboards/AdminDashboard/Accounts/Tabs/AccountNote';
import { UserDetailsInfo } from './app/dashboards/AdminDashboard/Users/Tabs/UserDetailsInfo';
import { UsersDetail } from './app/dashboards/AdminDashboard/Users/UsersDetail';
import { ProfileInfo } from './app/profiles/ProfileInfo/ProfileInfo';
import { CreditInfo } from './app/components/UserBalance/CreditInfo';
import { CreditBCUInfo } from './app/components/UserBalance/Tabs/CreditBCUInfo';
import { ScopeInfo } from './app/components/UserBalance/Tabs/ScopeInfo';
import { AccountFees } from './app/profiles/AccountFees/AccountFees';
import { AccountFeeInformation } from './app/dashboards/AdminDashboard/Accounts/Tabs/AccountFeeInformation';
import { Information } from './app/home/Information';
import { CertificationSchemes } from './app/dashboards/AdminDashboard/ReferenceValues/Tabs/CertificationSchemes';
import { AdminProfileUpdateLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminProfileUpdateLayout';
import { AdminProfileInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminProfileInfo';
import { IncomingTransactionsPage } from './app/transfer/Pages/IncomingTransactionsPage';
import {
    CreditInfoRequestStatus,
    IncomingCreditI,
} from './structure/models/credits/credits';
import App from './App';
import { getUserByIdLoader } from './API/admin/users';
import { fetchSubmissionsCompanyInfo, fetchSubmissionsTransactionInfo } from './API/admin/submissions';
import { AdminScope1Info } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminScope1Info';
import { AdminScope3Info } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminScope3Info';
import { AdminUserProfile } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminUserProfile';
import { CreditBCUValues } from './app/components/UserBalance/Tabs/CreditBCUValues';
import { BcuLogs } from './app/dashboards/AdminDashboard/BCUList/Tabs/BcuLogs';
import { AdminBCUValues } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminBCUValues';
import { ValuesBCU } from './app/dashboards/AdminDashboard/BCUList/Tabs/ValuesBCU';
import { Scope1 } from './app/dashboards/AdminDashboard/BCUList/Tabs/Scope1';
import { Scope3 } from './app/dashboards/AdminDashboard/BCUList/Tabs/Scope3';
import { IncomingTransactionPage } from './app/transfer/Pages/IncomingTransactionPage';

const GuardedLayout = guard(PageWithTabsLayout);
const GuardedDashboards = guard(Dashboards);
const GuardedTransactions = guard(Transactions);
const GuardedBCURegistration = guard(BCURegistration);
const GuardedSetPassword = guard(SetPassword);
const GuardedResetPassword = guard(ResetPassword);
const GuardedChangePassword = guard(ChangePassword);
const GuardedRegistration = guard(Registration);
const GuardedForgotPassword = guard(ForgotPassword);
const GuardedLogin = guard(Login);
const GuardedContactUs = guard(ContactUs);
const GuardedProfiles = guard(Profiles);
const GuardedCompanyInfo = guard(CompanyInfo);
const GuardedProfileInfo = guard(ProfileInfo);
const GuardedUserInfo = guard(UserInfo);
const GuardedAccountFees = guard(AccountFees);
const GuardedContactList = guard(ContactList);
const GuardedInfoRequestsPage = guard(InfoRequestsPage);
const GuardedIncomingTransactionsPage = guard(IncomingTransactionsPage);
const GuardedIncomingTransactionPage = guard(IncomingTransactionPage);
const GuardedInfoRequestPage = guard(InfoRequestPage);
const GuardedCreditInfo = guard(CreditInfo);

const GuardedAdminBCUs = guard(BCUList);
const GuardedAdminBCUInfo = guard(BCUInfo);
const GuardedAdminLogs = guard(Logs);
const GuardedAdminAccountsAndBalance = guard(Accounts);
const GuardedAdminAccountsAndBalanceDetail = guard(AccountDetail);
const GuardedAdminUsers = guard(Users);
const GuardedAdminSubmissionsList = guard(SubmissionList);
const GuardedAdminReferenceValues = guard(ReferenceValues);
const GuardedAdminCompanyInfoLayout = guard(AdminRegisterLayout);
const GuardedAdminProfileUpdateInfoLayout = guard(AdminProfileUpdateLayout);
const GuardedAdminBCUInfoLayout = guard(AdminTransactionsLayout);
const GuardedAdminBCUTransferLayout = guard(AdminTransfersLayout);
const GuardedAdminBCURetirementLayout = guard(AdminRetirementsLayout);
const GuardedAdminCompanyInfo = guard(AdminCompanyInfo);
const GuardedAdminUsersInfo = guard(AdminUsersInfo);
const GuarderAdminUserProfileInfo = guard(AdminUserProfile);
const GuardedAdminUsersDetail = guard(UsersDetail);
const GuardedAdminProfileInfo = guard(AdminProfileInfo);

const {
    [DASHBOARD]: dashboardAccess,
    [DASHBOARD_ROUTES.TRANSFER]: transferAccess,
    [DASHBOARD_ROUTES.RETIREMENT]: retirementAccess,
    [DASHBOARD_ROUTES.REGISTER]: registerCreditAccess,
    [DASHBOARD_ROUTES.CONTACT_LIST]: contactListAccess,
    [TRANSFER_ROUTES.INFO_REQUEST]: transferInfoRequestAccess,
    [TRANSFER_ROUTES.INFO_REQUESTS]: transferInfoRequestsAccess,
    [SET_PASSWORD]: setPasswordAccess,
    [CHANGE_PASSWORD]: changePasswordAccess,
    [RESET_PASSWORD]: resetPasswordAccess,
    [REGISTRATION]: registrationAccess,
    [FORGOT_PASSWORD]: forgotPasswordAccess,
    [LOGIN]: loginAccess,
    [CONTACT_US]: contactUsAccess,
    [PROFILE_ROUTES.USER_PROFILE]: userInfoAccess,
    [PROFILE_ROUTES.COMPANY_PROFILE]: companyInfoAccess,
    [PROFILE_ROUTES.PROFILE_INFO]: companyProfileAccess,
    [PROFILE_ROUTES.USER_INFO]: companyUserAccess,
    [PROFILE_ROUTES.ACCOUNTS_FEES]: accountFeesAccess,
    [ADMIN_BCUS]: adminBCUsAccess,
    [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS]: adminBCUDetails,
    [ADMIN_LOGS]: adminLogsAccess,
    [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN]: adminAccountsAndBalanceAccess,
    [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS]: adminTransactionsAccess,
    [ADMIN_USERS]: adminUsersAccess,
    [ADMIN_USERS_DETAILS]: adminUsersDetailAccess,
    [ADMIN_SUBMISSIONS_LIST]: adminSubmissionListAccess,
    [ADMIN_REFERENCE_VALUES]: adminReferenceValuesAccess,
    [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO]:
        adminSubmissionsCompanyInfoAccess,
    [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO]:
        adminSubmissionsUsersInfoAccess,
    [ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.PROFILE_INFO]:
        adminSubmissionsProfileUpdateInfoAccess,
    [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO]:
        adminSubmissionsBCUInfoAccess,
    [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO]:
        adminSubmissionsBCUTransferAccess,
    [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO]:
        adminSubmissionsBCURetirementAccess,
} = pageAccesses;

const UserBalanceWithProps: FC = React.memo(() => {
    const user = useAppSelector(selectUser);

    return user ? (
        <UserBalance company={user!.company} style={{ marginBottom: '32px' }} />
    ) : (
        <div></div>
    );
});

const transferTabs = [
    {
        title: 'BCU Transfer',
        path: TRANSFER_ROUTES.TRANSFER,
    },
    {
        title: 'Contact List',
        path: TRANSFER_ROUTES.CONTACT_LIST,
    },
    {
        title: 'Incoming Transactions',
        path: TRANSFER_ROUTES.INCOMING_TRANSACTIONS,
        Badge: () => {
            const response = useCountIncomingCreditQuery();

            if (response?.data && response?.data?.length > 0) {
                const credits: IncomingCreditI[] = response.data;
                const filteredData = credits.filter(
                    ({ status }) => status === CreditInfoRequestStatus.Pending,
                );

                if (filteredData.length) return <Badge count={filteredData.length} />;
            }

            return null;
        },
    },
    {
        title: 'Scope 1 Info Requests List',
        path: TRANSFER_ROUTES.INFO_REQUESTS,
        childRoutes: [TRANSFER_ROUTES.INFO_REQUEST],
        Badge: () => {
            const response = useCountInformationRequestsQuery();

            if (response?.data && response?.data > 0) {
                return <Badge count={response?.data} />;
            }

            return null;
        },
    },
];

const retirementTabs = [
    {
        title: 'BCU Retirement',
        path: RETIREMENT_ROUTES.RETIREMENT,
    },
    {
        title: 'Contact List',
        path: RETIREMENT_ROUTES.CONTACT_LIST,
    },
];

const BubbleError: ComponentType = () => {
    const error = useRouteError();
    throw error;
};

export const routes: RouteObject[] = [
    {
        element: <App />,
        errorElement: <BubbleError />,
        children: [
            {
                element: <MainLayout />,
                children: [
                    {
                        path: DASHBOARD,
                        element: <GuardedDashboards {...dashboardAccess} />,
                        children: [
                            {
                                index: true,
                                element: <GuardedTransactions {...dashboardAccess} />,
                            },
                            {
                                path: REGISTER_CREDIT,
                                element: <GuardedBCURegistration {...registerCreditAccess} />,
                            },
                            {
                                path: CONTACT_LIST,
                                element: <GuardedContactList {...contactListAccess} />,
                            },
                            {
                                path: TRANSFER,
                                element: (
                                    <GuardedLayout
                                        aboveWrapper={<UserBalanceWithProps />}
                                        tabs={transferTabs}
                                        {...transferAccess}
                                    />
                                ),
                                children: [
                                    {
                                        index: true,
                                        element: <Transfer />,
                                    },
                                    {
                                        path: TRANSFER_ROUTES.CONTACT_LIST,
                                        element: <ContactList />,
                                    },
                                    {
                                        path: TRANSFER_ROUTES.INFO_REQUESTS,
                                        element: <GuardedInfoRequestsPage {...transferInfoRequestsAccess} />,
                                    },
                                    {
                                        path: TRANSFER_ROUTES.INCOMING_TRANSACTIONS,
                                        element: <GuardedIncomingTransactionsPage {...transferAccess} />,
                                    },
                                    {
                                        path: TRANSFER_ROUTES.INCOMING_TRANSACTION,
                                        element: <GuardedIncomingTransactionPage {...transferAccess} />,
                                        children: [
                                            {
                                                index: true,
                                                element: <IncomingTransactionBCUInfo />,
                                            },
                                            {
                                                path: TRANSFER_ROUTES.INCOMING_TRANSACTION_SCOPE_1,
                                                element: <IncomingTransactionScope1 />,
                                            },
                                            {
                                                path: TRANSFER_ROUTES.INCOMING_TRANSACTION_SCOPE_3,
                                                element: <IncomingTransactionScope3 />,
                                            },
                                        ]
                                    },
                                    {
                                        path: TRANSFER_ROUTES.INFO_REQUEST,
                                        element: <GuardedInfoRequestPage {...transferInfoRequestAccess} />,
                                    },
                                ],
                            },
                            {
                                path: RETIREMENT,
                                element: (
                                    <GuardedLayout
                                        aboveWrapper={<UserBalanceWithProps />}
                                        tabs={retirementTabs}
                                        {...retirementAccess}
                                    />
                                ),
                                children: [
                                    {
                                        index: true,
                                        element: <Retirement />,
                                    },
                                    {
                                        path: RETIREMENT_ROUTES.CONTACT_LIST,
                                        element: <ContactList />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: BCU_DETAILS_ROUTES.BCU_DETAILS,
                        element: <GuardedCreditInfo {...dashboardAccess} />,
                        children: [
                            {
                                path: BCU_DETAILS_ROUTES.BCU_VALUES,
                                element: <CreditBCUValues />,
                            },
                            {
                                index: true,
                                path: BCU_DETAILS_ROUTES.BCU_INFO,
                                element: <CreditBCUInfo />,
                            },
                            {
                                path: BCU_DETAILS_ROUTES.SCOPE1,
                                element: <ScopeInfo />,
                            },
                            {
                                path: BCU_DETAILS_ROUTES.SCOPE3,
                                element: <ScopeInfo scope="scope3" />,
                            },
                        ],
                    },
                    {
                        path: USER_PROFILE,
                        element: <GuardedProfiles {...userInfoAccess} />,
                        children: [
                            {
                                index: true,
                                element: <CompanyInfo />,
                            },
                            {
                                path: COMPANY_PROFILE,
                                element: <GuardedCompanyInfo {...companyInfoAccess} />,
                            },
                            {
                                path: PROFILE_INFO,
                                element: <GuardedProfileInfo {...companyProfileAccess} />,
                            },
                            {
                                path: USER_INFO,
                                element: <GuardedUserInfo {...companyUserAccess} />,
                            },
                            {
                                path: ACCOUNTS_FEES,
                                element: <GuardedAccountFees {...accountFeesAccess} />,
                            },
                        ],
                    },
                    {
                        path: SET_PASSWORD,
                        element: <GuardedSetPassword {...setPasswordAccess} />,
                    },
                    {
                        path: CHANGE_PASSWORD,
                        element: <GuardedChangePassword {...changePasswordAccess} />,
                    },
                    {
                        path: CONTACT_LIST,
                        element: <GuardedContactList {...contactListAccess} />,
                    },
                    {
                        path: RESET_PASSWORD,
                        element: <GuardedResetPassword {...resetPasswordAccess} />,
                    },
                    {
                        path: CONTACT_US,
                        element: <GuardedContactUs {...contactUsAccess} />,
                    },
                    {
                        path: REGISTRATION,
                        element: <GuardedRegistration {...registrationAccess} />,
                    },
                    {
                        path: FORGOT_PASSWORD,
                        element: <GuardedForgotPassword {...forgotPasswordAccess} />,
                    },
                    {
                        path: LOGIN,
                        element: <GuardedLogin {...loginAccess} />,
                    },
                    {
                        path: ADMIN_BCUS,
                        element: <GuardedAdminBCUs {...adminBCUsAccess} />,
                    },
                    {
                        path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS,
                        element: <GuardedAdminBCUInfo {...adminBCUDetails} />,
                        children: [
                            {
                                index: true,
                                element: <ValuesBCU />,
                            },
                            {
                                path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_INFO,
                                element: <InfoBCU />,
                            },
                            {
                                path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_SCOPE1,
                                element: <Scope1 />,
                            },
                            {
                                path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_SCOPE3,
                                element: <Scope3 />,
                            },
                            {
                                path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY,
                                element: <BcuLogs />,
                            },
                            {
                                path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_NOTE,
                                element: <NoteBCU />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_LOGS,
                        element: <GuardedAdminLogs {...adminLogsAccess} />,
                    },
                    {
                        path: ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN,
                        element: (
                            <GuardedAdminAccountsAndBalance
                                {...adminAccountsAndBalanceAccess}
                            />
                        ),
                    },
                    {
                        path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ADMIN_ACCOUNTS_AND_BALANCE_DETAILS,
                        element: (
                            <GuardedAdminAccountsAndBalanceDetail
                                {...adminTransactionsAccess}
                            />
                        ),
                        children: [
                            {
                                index: true,
                                element: <AccountCompanyInfo />,
                            },
                            {
                                path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_USER_INFO,
                                element: <AccountUserInfo />,
                            },
                            {
                                path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_HISTORY,
                                element: <AccountHistory />,
                            },
                            {
                                path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_BALANCE,
                                element: <AccountBalance />,
                            },
                            {
                                path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_FEE_INFORMATION,
                                element: <AccountFeeInformation />,
                            },
                            {
                                path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_NOTES,
                                element: <AccountNote />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_USERS,
                        element: <GuardedAdminUsers {...adminUsersAccess} />,
                    },
                    {
                        path: ADMIN_USERS_DETAILS_ROUTES.ADMIN_USERS_DETAILS,
                        element: (
                            <GuardedAdminUsersDetail {...adminUsersAccess} />
                        ),
                        loader: getUserByIdLoader,
                        children: [
                            {
                                index: true,
                                element: <UserDetailsInfo />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_SUBMISSIONS_LIST,
                        element: <GuardedAdminSubmissionsList {...adminSubmissionListAccess} />,
                    },
                    {
                        path: ADMIN_REFERENCE_VALUES,
                        element: <GuardedAdminReferenceValues {...adminReferenceValuesAccess} />,
                        children: [
                            {
                                index: true,
                                element: <CertificationSchemes />,
                            },
                            {
                                path: ADMIN_REFERENCE_VALUES_ROUTES.CREATE_CERTIFICATION_SCHEMES,
                                element: <CreateCertificationSchemes />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO,
                        element: (
                            <GuardedAdminCompanyInfoLayout
                                {...adminSubmissionsCompanyInfoAccess}
                            />
                        ),
                        children: [
                            {
                                index: true,
                                element: <GuardedAdminCompanyInfo {...adminSubmissionsCompanyInfoAccess} fetchData={fetchSubmissionsCompanyInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO,
                                element: <GuardedAdminUsersInfo {...adminSubmissionsUsersInfoAccess} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USER_PROFILE,
                                element: <GuarderAdminUserProfileInfo {...adminSubmissionsUsersInfoAccess} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.NOTE,
                                element: <Note />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.PROFILE_INFO,
                        element: (
                            <GuardedAdminProfileUpdateInfoLayout
                                {...adminSubmissionsProfileUpdateInfoAccess}
                            />
                        ),
                        children: [
                            {
                                index: true,
                                element: (
                                    <GuardedAdminProfileInfo
                                        {...adminSubmissionsProfileUpdateInfoAccess}
                                    />
                                ),
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.NOTE,
                                element: <Note />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO,
                        element: <GuardedAdminBCUInfoLayout {...adminSubmissionsBCUInfoAccess} />,
                        children: [
                            {
                                index: true,
                                element: <GuardedAdminCompanyInfo {...adminSubmissionsCompanyInfoAccess} fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO,
                                element: <GuardedAdminUsersInfo {...adminSubmissionsUsersInfoAccess} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE,
                                element: <Note />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_VALUES,
                                element: <AdminBCUValues fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO,
                                element: <AdminBCUInfo fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.SCOPE1_INFO,
                                element: <AdminScope1Info />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.SCOPE3_INFO,
                                element: <AdminScope3Info />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO,
                        element: (
                            <GuardedAdminBCUTransferLayout
                                {...adminSubmissionsBCUTransferAccess}
                            />
                        ),
                        children: [
                            {
                                index: true,
                                element: <AdminCompanyInfo fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.USERS_INFO,
                                element: <AdminUsersInfo />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_VALUES,
                                element: <AdminBCUValues fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO,
                                element: <AdminBCUInfo fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.TRANSFER_INFO,
                                element: <AdminTransferInfo />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.SCOPE1_INFO,
                                element: <AdminScope1Info />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.SCOPE3_INFO,
                                element: <AdminScope3Info />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.NOTE,
                                element: <Note />,
                            },
                        ],
                    },
                    {
                        path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO,
                        element: (
                            <GuardedAdminBCURetirementLayout
                                {...adminSubmissionsBCURetirementAccess}
                            />
                        ),
                        children: [
                            {
                                index: true,
                                element: <AdminCompanyInfo fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.USERS_INFO,
                                element: <AdminUsersInfo />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_VALUES,
                                element: <AdminBCUValues fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_INFO,
                                element: <AdminBCUInfo fetchData={fetchSubmissionsTransactionInfo} />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.RETIREMENT_INFO,
                                element: <AdminRetirementInfo />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.SCOPE1_INFO,
                                element: <AdminScope1Info />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.SCOPE3_INFO,
                                element: <AdminScope3Info />,
                            },
                            {
                                path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.NOTE,
                                element: <Note />,
                            },
                        ],
                    },
                    {
                        path: VERIFY_EMAIL,
                        element: <EmailVerification />,
                    },
                    {
                        path: STATEMENTS,
                        element: <Statements />,
                    },
                    {
                        path: INFORMATION,
                        element: <Information />,
                    },
                    {
                        path: ANY,
                        element: <Navigate to={STATEMENTS} />,
                    },
                ],
            },
        ],
    },
];
