import React, { useEffect, useState } from 'react';
import DefaultTable from '../../../components/DefaultTable';
import { useGetInformationRequestsQuery } from '../../../../API';
import { useNavigate } from 'react-router-dom';
import { TRANSFER_ROUTES } from '../../../../structure/routes/routes';
import Status, { IStatusProps } from '../../../components/StatusComponent';
import { OnFilterChange } from '../../../components/DefaultTable/TableFilters';
import {
  Column,
  ColumnWithFilter,
} from '../../../components/DefaultTable/types';
import { CreditInfoRequestStatus, Prisma } from '@prisma/client';

const mapItemStatusToComponent = (
  status: string,
): IStatusProps['statusOfComponent'] => {
  switch (status) {
    case CreditInfoRequestStatus.Approved:
      return 'Approved';
    case CreditInfoRequestStatus.Denied:
      return 'Denied';
    case CreditInfoRequestStatus.Pending:
    default:
      return 'Pending';
  }
};

type Scope1InfoRequest = Prisma.CreditInfoRequestGetPayload<{
    select: {
      id: true,
      createdAt: true,
      status: true,
      company: {
          select: {
              id: true,
              name: true,
          },
      },
      receiver: {
          select: {
              id: true,
              name: true,
          },
      },
      credit: {
          select: {
              id: true,
              BCUID: true,
          },
      },
      sender: {
          select: {
              id: true,
              firstName: true,
              lastName: true,
          },
      },
  },
}>;

export const COLUMNS: Array<Column<Scope1InfoRequest> | ColumnWithFilter<Scope1InfoRequest>> = [
  {
    title: 'Date and time',
    render: item => new Date(item?.createdAt).toLocaleString(),
  },
  {
    title: 'BCU ID',
    render: item => item.credit.BCUID,
  },
  {
    title: 'From/by',
    render: item => item.company.name,
  },
  {
    filters: [
      { title: 'Approved', value: CreditInfoRequestStatus.Approved },
      { title: 'Pending', value: CreditInfoRequestStatus.Pending },
      { title: 'Denied', value: CreditInfoRequestStatus.Denied },
    ],
    filterTitle: 'All statuses',
    title: 'Status',
    render: item => (
      <Status statusOfComponent={mapItemStatusToComponent(item.status)} />
    ),
  },
];

export const InfoRequestsPage: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const { data } = useGetInformationRequestsQuery();
  const [items, setItems] = useState(data?.results || []);

  useEffect(() => {
    setItems(data?.results || []);
  }, [data]);

  const handleRowClicked = async (item: any) => {
    // if (item.status === CreditInfoRequestStatus.Pending) {
      return navigate(TRANSFER_ROUTES.INFO_REQUEST.replace(':id', item.id));
    // }
  };

  const handleSearch = (query: string) => {
    if (query === '') {
      return setItems(data?.results || []);
    }

    setItems(
      ((data as any[]) || []).filter((item, index) => {
        return `${item.credit.BCUID} ${item.sender.name}`
          .toLowerCase()
          .includes(query);
      }),
    );
  };

  // We have only one filter, so no complex logic here
  const handleFilter = ({ values }: OnFilterChange) => {
    if (values.length === 0) {
      return setItems(data?.results || []);
    }

    setItems(
      ((data?.results as any[]) || []).filter(item => values.indexOf(item.status) > -1),
    );
  };

  return (
    <div className="contact-list-wrapper">
      <DefaultTable<any>
        items={items || []}
        showBottomFilters={false}
        headers={COLUMNS}
        onSearch={handleSearch}
        onFilter={handleFilter}
        onRowClicked={handleRowClicked}
      />
    </div>
  );
});
