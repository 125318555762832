import React from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export const ArrowSortIcon: React.FC<{ sortOrder: SortOrder }> = ({ sortOrder }) => {
    let icon = null

    switch (sortOrder) {
        case 'descend':
            icon = <ArrowDownOutlined style={{ fontSize: 15, color: '#07ACA6', position: 'relative', top: -1 }} />;
            break;
        case 'ascend':
            icon = <ArrowUpOutlined style={{ fontSize: 15, color: '#07ACA6', position: 'relative', top: -1 }} />;
            break;
        default:
            icon = <ArrowUpOutlined style={{ fontSize: 15, color: '#5C6D76', position: 'relative', top: -1 }} />;
            break;
    }

    return icon;
};