import { validationFactory } from '../../core/helpers/validationFactory';

const { createValidator, createValidate } = validationFactory();

export const getFormForgotPasswordData = (formData: FormData): string =>
  formData.get('email')?.toString() || '';

const validators = {
  email: createValidator(
    (value: string) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      ),
    'Email must have correct format.',
  ),
};

export const validate = createValidate(validators);
