import React, { useRef } from 'react';
import classNames from 'classnames';

import './InputStyle.scss';

export interface InputProps
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    React.AriaAttributes {
  isDateField?: boolean;
  lock?: boolean;
  label?: string;
  someBefore?: JSX.Element;
  someAfter?: JSX.Element;
  error?: string;
  description?: string;
  isErrorText?: boolean;
  isClearable?: boolean;
  onClear?: () => void;
}

export const Input: React.FC<InputProps> = React.memo(
  ({
    className,
    onChange,
    children,
    label,
    placeholder,
    required,
    lock,
    someBefore,
    someAfter,
    description,
    error,
    isDateField = false,
    isErrorText = true,
    isClearable = false,
    onClear,
    ...props
  }) => {
    const inputRef = useRef<any>(null);

    const customOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!lock && onChange) {
        onChange(e);
      }
    };

    const onInputClear = () => {
      if (onClear) {
        if (inputRef && inputRef.current) inputRef.current.value = '';
        onClear();
      }
    };

    const onDateFieldClick = () => {
      if (inputRef && inputRef.current && props.type === 'date') {
        inputRef?.current?.showPicker();
      }
    };

    const input = (
      <div className="custom-input">
        <div
          className={classNames('custom-input__wrapper', {
            error: error,
            dateError: error && isDateField,
          })}>
          {someBefore}
          <input
            ref={inputRef}
            disabled={lock}
            onClick={onDateFieldClick}
            className={classNames(
              {
                clear_input_lock: lock,
                iconBefore: Boolean(someBefore),
                date: props.type === 'date',
              },
              className,
            )}
            placeholder={placeholder}
            {...props}
            onChange={customOnChange}
          />
          {error && (
            <span
              className={classNames(
                'material-icons-outlined',
                'custom-input__error-icon',
                { isClearable: isClearable },
              )}>
              error
            </span>
          )}
          {isClearable && (
            <span
              className={classNames(
                'material-icons-outlined',
                'custom-input__close-icon',
              )}
              onClick={onInputClear}>
              close
            </span>
          )}
          {someAfter}
        </div>
        {description && (
          <div className="custom-input__description">{description}</div>
        )}
        {error && isErrorText && (
          <div className="custom-input__error">{error}</div>
        )}
      </div>
    );

    if (label) {
      return (
        <label className="label-input">
          <div className="label-input__text">
            <span>{label}</span>
            {required && <span className="label-input__text-required">*</span>}
          </div>
          {input}
        </label>
      );
    }

    return input;
  },
);
