import { isValid, format } from 'date-fns';

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_WITH_TIME_FORMAT = `${DATE_FORMAT} 'at' HH:mm a`;
export const DATE_WITH_FULL_MONTH = `dd MMMM yyyy 'at' HH:mm a`;
export const DATE_WITH_TIME_FORMAT_SHORT = `${DATE_FORMAT} HH:mm`;

export const dropTimeZone = (
    date: string | Date,
) => {
  if (!date || !isValid(new Date(date))) {
    return;
  }

  const d = new Date(date);
  const userTimezoneOffset = d.getTimezoneOffset() * 60000;

  return new Date(d.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset));
}

export const formatDate = (
  date: string | Date,
  formatString: string = DATE_FORMAT,
  dropTz = false,
  emptyValue = '-',
) => {
  if (!date || !isValid(new Date(date))) {
    return emptyValue;
  }

  if (dropTz) {
    return format(dropTimeZone(date) as Date, formatString);
  }

  return format(new Date(date), formatString);
};


