import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchCompanyInfo } from '../../../API/company';
import './CompanyInfoStyle.scss';
import CompanyInfoData from '../../components/CompanyInfoData/CompanyInfoData';
import { DATE_WITH_TIME_FORMAT, formatDate } from '../../core/models/contstants/format-date';

export const CompanyInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.user.account);

  const formattedData = useMemo(() => {
    if (!account) return null;

    return  {
      ...account,
      approveDate: formatDate(account?.approveDate, DATE_WITH_TIME_FORMAT),
    };
  }, [account]);

  useEffect(() => {
    dispatch(fetchCompanyInfo());
  }, []);

  return (
    <>
      {formattedData && (<div className="company-info">
          <CompanyInfoData hideId data={formattedData} />
      </div>)}
    </>
  );
};
