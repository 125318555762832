import { ChangeEvent, FC } from 'react';
import classNames from 'classnames';

interface TimeFieldProps {
  time: string;
  error?: boolean;
  onTimeChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

export const TimeField: FC<TimeFieldProps> = ({
  time,
  error = false,
  onTimeChange,
}) => {
  return (
    <div className={classNames('date-select__input', { timeError: error })}>
      <span
        className={classNames('material-icons-outlined', 'date-select__icon')}>
        schedule
      </span>
      <input
        type="time"
        value={time}
        placeholder="hh:mm"
        onChange={onTimeChange}
      />
      {error && (
        <span
          className={classNames(
            'material-icons-outlined',
            'date-select__error',
          )}>
          error
        </span>
      )}
    </div>
  );
};
