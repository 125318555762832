import React, { ReactNode, useEffect, useState } from 'react';
import './styles.scss';
import { SearchTable } from '../SearchTable';
import { PaginationBoardInTable } from '../../transfer/PaginationBoardInTable';
import { OnFilterChange, Filters } from './TableFilters';
import { Column } from './types';

type TableProps<T = any> = {
  items: T[];
  headers: Column<any>[];
  isLoading?: boolean;
  showPagination?: boolean;
  showBottomFilters?: boolean;
  showSearch?: boolean;
  showFilters?: boolean;
  onRowClicked?: (item: T, rowIndex?: number, columnIndex?: number) => void;
  onFilter?: (filter: OnFilterChange) => void;
  onSearch?: (query: string) => void;
};

export type ColumnWithFilter<T = any> = Column<T> & {
  filters: { title: string; value: string }[];
  filterTitle?: string;
};

const DefaultTable = <T,>({
  items: propsItems,
  headers,
  onRowClicked,
  onSearch,
  onFilter,
  isLoading = false,
  showPagination = true,
  showBottomFilters = true,
  showSearch = true,
  showFilters = true,
}: TableProps<T>) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [items, setItems] = useState(propsItems);
  const [rows, setRows] = useState<ReactNode[][]>([]);

  const totalPages = Math.max(Math.ceil((items?.length || 0) / rowsPerPage), 1);

  useEffect(() => {
    setItems(propsItems);
  }, [propsItems]);

  // Prepare table presentation data
  useEffect(() => {
    setRows(
      items.map((item, index) => {
        return headers.map(
          column => column.render && column.render(item, index),
        );
      }),
    );
  }, [items, headers]);

  const handleRowsPerPageChange = (selectedRowsPerPage: number) => {
    setRowsPerPage(selectedRowsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div
      className={`transfer-contact-list-list${isLoading ? ' is-loading' : ''}`}>
      {(showSearch || showFilters || showPagination) && (
        <div className="transfer-contact-list-list_header">
          <div className="d-flex">
            {showSearch && (
              <SearchTable
                onChange={event =>
                  onSearch ? onSearch(event.target.value.trim()) : undefined
                }
              />
            )}
            {showFilters && (
              <Filters
                columns={headers}
                items={items}
                onFilterChange={onFilter ? onFilter : undefined}
              />
            )}
          </div>
        </div>
      )}
      <table className="contact-list-table">
        <thead>
          <tr className="contact-list-table__tr">
            {headers.map(column => (
              <th className="contact-list-table__td" key={column.title}>
                <span className="contact-list-table__text-head">
                  {column.title}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => {
            if (
              index >= (currentPage - 1) * rowsPerPage &&
              index < currentPage * rowsPerPage
            ) {
              return (
                <tr
                  style={onRowClicked ? { cursor: 'pointer' } : undefined}
                  className={`contact-list-table__tr ${
                    index % 2 === 0 ? 'even' : ''
                  }`}
                  key={index}
                  onClick={() =>
                    onRowClicked ? onRowClicked(items[index], index) : undefined
                  }>
                  {row.map((column, columnIndex) => (
                    <td
                      className="contact-list-table__td"
                      key={columnIndex}
                      onClick={() =>
                        onRowClicked
                          ? onRowClicked(items[index], index, columnIndex)
                          : undefined
                      }>
                      <span className="contact-list-table__text">{column}</span>
                    </td>
                  ))}
                </tr>
              );
            }
            return null;
          })}
          {rows.length === 0 && (
            <tr>
              <td
                className="contact-list-table__empty"
                colSpan={headers.length}>
                <span>No items found</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {(showPagination || showBottomFilters) && (
        <div className="transfer-contact-list-list_end">
          <PaginationBoardInTable
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            rowsPerPage={rowsPerPage}
            totalItems={items?.length || 0}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default DefaultTable;
