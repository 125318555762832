import React, { useEffect, useState } from 'react';
import { PageWithTabsLayout } from '../../../layouts/PageWithTabsLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { BaseButtonSubmit } from '../../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { RejectModal } from './RejectModal/RejectModal';

import './AdminSubmissionsDetailsStyle.scss';
import {
  selectSubmissionsDetailsStatus,
  selectSubmissionsMessage,
  selectTransactionType,
} from '../../../../store/reducers/admin/submissionsReducer';
import { SubmissionsStatusBE, SubmissionsType } from '../../../../structure/request/admin';
import { ADMIN_SUBMISSIONS_LIST } from '../../../../structure/routes/routes';
import { selectUser } from '../../../../store/reducers/user/userReducer';
import { useNotification } from '../../../../hooks/useNotification';
import { ViewElement } from '../../../components/ViewElement/ViewElement';
import { isInTypes } from '../../../../helpers/types';
import { UserRole } from '@prisma/client';

interface Props {
  fetchData: (id: string) => any;
  fetchReject: (params: any) => any;
  fetchApprove: (params: any) => any;
  type?: SubmissionsType;
  tabs: any;
}

export const AdminSubmissionsDetailsLayout: React.FC<Props> = ({
  fetchData,
  fetchReject,
  fetchApprove,
  type,
  tabs,
}) => {
  const toast = useNotification();
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const message = useAppSelector(selectSubmissionsMessage);
  const status = useAppSelector(selectSubmissionsDetailsStatus);
  const user = useAppSelector(selectUser);
  const transactionType = useAppSelector(selectTransactionType);

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const goBack = () => navigate(ADMIN_SUBMISSIONS_LIST);

  const onReject = () => setShowRejectModal(true);
  const onApprove = () => {
    setLoading(true);
    dispatch(fetchApprove({ id, message }))
      .then(() => {
        toast.success({ message: 'Success', description: 'Registration approved' });
        goBack();
      })
      .catch((e: any) => {
        toast.error({ message: 'Error', description: e.response.data.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onHide = (status: 'reject' | 'cancel') => {
    if (status === 'reject') {
      setRejectLoading(true);
      dispatch(fetchReject({ id, message }))
        .then(() => {
          toast.success({ message: 'Success', description: 'Registration rejected' });
          goBack();
        })
        .catch((e: any) => {
          toast.error({ message: 'Error', description: e.response.data.message });
        })
        .finally(() => {
          setRejectLoading(false);
        });
      return;
    }
    setShowRejectModal(false);
  };

  useEffect(() => {
    dispatch(fetchData(id!));
  }, [id, dispatch, pathname]);

  const actionButtons = (
    <div className="submissions-details__action-buttons">
      <BaseButtonSubmit onClick={onReject} lock={rejectLoading || loading}>
        Reject
      </BaseButtonSubmit>
      <BaseButtonSubmit
        active
        loading={loading}
        onClick={onApprove}
        lock={loading || rejectLoading}>
        Approve
      </BaseButtonSubmit>
    </div>
  );

  const isEdited = !isInTypes(user!.role, [UserRole.Auditor]);

  return (
    <div className="submissions-details">
      <div onClick={goBack} className="submissions-details__back">
        <span className="material-icons-outlined">arrow_back_ios</span>
        Submissions List
      </div>
      <div className="submissions-details__header">
        <div className="submissions-details__header__info">
            <ViewElement label="Submission type" description={type || transactionType} />
        </div>
      </div>
      <PageWithTabsLayout
        underWrapper={
          <>
            {status === SubmissionsStatusBE.Pending &&
              isEdited &&
              actionButtons}
          </>
        }
        tabs={tabs.map((tab: any) => ({
          ...tab,
          path: tab.path.replace(':id', id as string),
        }))}
      />
      <RejectModal
        centered
        show={showRejectModal}
        onHide={onHide}
        loading={rejectLoading}
      />
    </div>
  );
};
