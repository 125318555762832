import React, { useEffect, useState } from 'react';
import { Note } from '../../../../components/Note';
import { useParams } from 'react-router-dom';
import {
  RelationTypes,
  useGetNoteByEntityQuery,
  useSaveNoteByEntityMutation
} from '../../../../../API/admin/notes';
import { useNotification } from '../../../../../hooks/useNotification';

export const NoteBCU: React.FC = () => {
  const toast = useNotification();
  const { creditId } = useParams();
  const { data: note } = useGetNoteByEntityQuery({ id: creditId || '', type: RelationTypes.CREDIT });
  const [saveNoteByEntity] = useSaveNoteByEntityMutation();
  const [value, setValue] = useState(note?.note || '');

  useEffect(() => {
    setValue(note?.note || '');
  }, [note]);

  const onChange = (value: string) => {
    setValue(value);
  };

  const onSend = async (v: string) => {
    const result: any = await saveNoteByEntity({ id: creditId || '', type: RelationTypes.CREDIT, note: v || value || '' });

    if (result && result.data) {
      toast.success({ message: 'Success', description: 'Your note has been saved' });
    } else {
      toast.error({ message: 'Error', description: (result.error?.message || []).join('\n') || 'Unknown error' });
    }
  };

  return (
    <div className="admin-bcu-info__note">
      <Note value={value} onChange={onChange} onSend={onSend} />
    </div>
  );
};
