import React, { useEffect, useState } from 'react';

import { Select, SelectProps } from 'antd';
import { useLazyGetAirportsQuery } from '../../../API/airports';

export const SearchAirportInput: React.FC<{ value: SelectProps['value'], onChange: SelectProps['onChange'], country: string, placeholder?: string; className: SelectProps['className'], style?: React.CSSProperties }> = ({ value, onChange, country, placeholder, className, style }) => {
    const [getAirports] = useLazyGetAirportsQuery();
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const [prevCountry, setPrevCountry] = useState<string | undefined>();

    useEffect(() => {
        if (prevCountry && prevCountry !== country) {
            // clear
            if (onChange) {
                onChange(undefined, undefined as any);
            }
        }

        setPrevCountry(country);
        setOptions([]);

        if (!country) {
            return;
        }

        getAirports({ country }, true).then(
            ({ data }) => {
                const transformedOptions = (data || []).map(
                    ({ code, name }: { code: string; name: string }) => ({
                        value: code,
                        label: `${name} [${code}]`,
                    }),
                );

                setOptions(transformedOptions);
            },
        );
    }, [country]);

    return (
        <Select
            showSearch
            allowClear
            className={className}
            value={value}
            placeholder={placeholder}
            style={style}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            onChange={onChange}
            notFoundContent={null}
            options={options}
        />
    );
};
