import React from 'react';
import { Col, Row } from 'antd';

import './ViewElementStyle.scss';
import classNames from 'classnames';

interface Props {
  label?: React.ReactNode;
  description?: React.ReactNode;
  dots?: boolean;
  odd?: boolean;
}

export const ViewElement: React.FC<Props> = ({
  label,
  description,
  dots = true,
  odd = false,
}) => {
  return (
    <Row className={classNames('view-element', { odd: odd })}>
      <Col className="view-element__label">
        {label}
        {dots ? ':' : ''}
      </Col>
      <Col className="view-element__description">{description}</Col>
    </Row>
  );
};
