import React, { ComponentType } from 'react';
import './DefaultSelectStyle.scss';
import classNames from 'classnames';
import RSelect, {
  GroupBase,
  StylesConfig,
  ThemeConfig,
  components,
} from 'react-select';
import { Option, SelectProps } from '../Select/Select';
import { OptionsOrGroups } from 'react-select/dist/declarations/src/types';

export type SelectOption<T> =
  | OptionsOrGroups<Option<T>, GroupBase<Option<T>>>
  | any;

export const DropdownIndicator: ComponentType<any> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="m5,8.45l4.98,4.98l4.98,-4.98l-9.95,0z" fill="#455A64" />
      </svg>
    </components.DropdownIndicator>
  );
};

export const IndicatorSeparator: ComponentType<any> = props => {
  return (
    <>
      {props.selectProps.isClearable ? (
        <components.IndicatorSeparator {...props} />
      ) : null}
    </>
  );
};

export const defaultStyles: StylesConfig = {
  control: (
    styles,
    { selectProps: { 'aria-errormessage': errorMessage } },
  ) => ({
    ...styles,
    borderRadius: '8px',
    border: '1px solid #909090',
    boxShadow: 'none',
    borderColor: errorMessage ? '#d64751' : styles.borderColor,
    ':hover': {
      borderColor: errorMessage ? '#d64751' : styles[':hover']?.borderColor,
    },
  }) as any,
  singleValue: styles => ({
    ...styles,
    color: '#455A64',
  }) as any,
  menu: styles => ({
    ...styles,
    zIndex: 900,
  }) as any,
  placeholder: styles => ({
    ...styles,
    color: '#9F9F9F',
    fontSize: '14px',
  }) as any,
};

export const defaultSelectTheme: ThemeConfig = baseTheme => {
  return {
    ...baseTheme,
    // borderRadius: 8,
    colors: {
      primary: '#07ACA6',
      primary25: 'rgba(7, 172, 166, .25)',
      primary50: 'rgba(7, 172, 166, .5)',
      primary75: 'rgba(7, 172, 166, .75)',
      danger: '#DE350B',
      dangerLight: '#FFBDAD',
      neutral0: 'hsl(0, 0%, 100%)',
      neutral5: 'hsl(0, 0%, 95%)',
      neutral10: 'hsl(0, 0%, 90%)',
      neutral20: 'hsl(0, 0%, 70%)',
      neutral30: 'hsl(0, 0%, 70%)',
      neutral40: 'hsl(0, 0%, 60%)',
      neutral50: 'hsl(0, 0%, 50%)',
      neutral60: 'hsl(0, 0%, 40%)',
      neutral70: 'hsl(0, 0%, 30%)',
      neutral80: 'hsl(0, 0%, 20%)',
      neutral90: 'hsl(0, 0%, 10%)',
    },
    spacing: {
      ...baseTheme.spacing,
      baseUnit: 6,
    },
  };
};

const isObject = (v: any): v is object => {
  return typeof v === 'object';
};

const isDefinedPrimitive = (
  v: any,
): v is boolean | number | string | null | symbol | bigint => {
  return (
    typeof v !== 'undefined' && typeof v !== 'object' && typeof v !== 'function'
  );
};

const isValueOption = (v: any): v is { label: string; value: any } => {
  return (
    v &&
    isObject(v) &&
    Object.hasOwn(v, 'label') &&
    Object.hasOwn(v, 'value') &&
    typeof (v as any).label === 'string'
  );
};

export function DefaultSelect<
  Option extends any,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  error,
  isMulti,
  description,
  required = false,
  value,
  onChange,
  label = '',
  isErrorText = true,
  ...props
}: SelectProps<Option, IsMulti, Group> & {
  label?: string;
  isErrorText?: boolean;
}) {
  const { className } = props;

  const options = props.options || [];
  let realValue: any = null;

  /* TODO:
       This approach is bad.
       We need to move this to the upper level, but for now better than nothing,
       bc I don't want to change every occurrence of it in the project
       before the release.
     */
  if (isMulti && Array.isArray(value)) {
    realValue = value;
  } else if (isValueOption(value)) {
    realValue = value;
  } else if (isDefinedPrimitive(value)) {
    realValue = options.find((o: any) => o.value === value) || null;
  }
  // ENDTODO

  const onChangeValue = (value: any) => {
    if (onChange) {
      if (value === null) {
        realValue = '';
        return onChange({ label: '', value: '' } as any, {} as any);
      }
      return onChange(value as any, {} as any);
    }
  };

  return (
    <div className="custom-select__wrapper">
      {label ? (
        <label
          htmlFor={props.inputId || props.id}
          className={`custom-select__label${
            required ? ' custom-select__label--required' : ''
          }`}>
          {label}
        </label>
      ) : null}
      <RSelect
        // @ts-ignore
        styles={defaultStyles}
        isClearable
        isMulti={isMulti}
        onChange={onChangeValue}
        components={{ DropdownIndicator, IndicatorSeparator }}
        theme={defaultSelectTheme}
        aria-errormessage={error && isErrorText}
        className={classNames('custom-select', className, {
          custom_select_lock: props.isDisabled,
          isError: error,
        })}
        getOptionValue={(o: any) => o.value || o}
        getOptionLabel={(o: any) => o.label || o}
        value={realValue}
        {...props}
      />
      {error && isErrorText && (
        <div className="custom-select__error">{error}</div>
      )}
      {!error && description && (
        <div className="custom-select__description">{description}</div>
      )}
    </div>
  );
}
