import React, { useEffect } from 'react';
import { ChangePasswordForm } from './ChangePasswordForm';
import { fetchSetPassword } from '../../API/login';
import { DASHBOARD } from '../../structure/routes/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotification } from '../../hooks/useNotification';

export const SetPassword: React.FC = () => {
  const toast = useNotification();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      toast.error({ message: 'Error', description: 'Token is required.' });
      navigate(DASHBOARD);
    }
  }, []);

  return <ChangePasswordForm fetchData={fetchSetPassword} token={token!} isSetPassword />;
};
