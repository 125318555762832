import React, { useEffect, useState } from 'react';

import './PaginationBoardInTableStyles.scss';

interface PaginationBoardInTableProps {
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onChangePage: (page: number) => void;
  rowsPerPage: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
}

export const PaginationBoardInTable: React.FC<PaginationBoardInTableProps> = ({
  onChangeRowsPerPage,
  onChangePage,
  rowsPerPage,
  totalItems,
  currentPage,
  totalPages,
}) => {
  const rowsPerPageOptions = [25, 50, 75, 100];

  const [editablePage, setEditablePage] = useState<number | string>(
    currentPage,
  );

  useEffect(() => {
    setEditablePage(currentPage);
  }, [currentPage]);

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedRowsPerPage = parseInt(event.target.value, 10);
    onChangeRowsPerPage(selectedRowsPerPage);
  };

  const handlePageChange = (p: number) => {
    switch (true) {
      case p <= 1:
        return onChangePage(1);
      case p >= totalPages:
        return onChangePage(totalPages);
      default:
        return onChangePage(p);
    }
  };

  const normalizePageNumber = (p: string) => {
    switch (p) {
      case '':
        return 1;
      default:
        const n = parseInt(p, 10);

        if (isNaN(n)) {
          return 1;
        }

        if (n >= totalPages) {
          return totalPages;
        }

        if (n <= 1) {
          return 1;
        }

        return n;
    }
  };

  const handleEditablePageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    switch (event.target.value) {
      case '':
        return setEditablePage('');
      default:
        return setEditablePage(normalizePageNumber(event.target.value));
    }
  };

  return (
    <div className="pagination-board-table">
      <div className="pagination-board-table__rows">
        <span>Rows per page</span>
        <div className="pagination-board-table__number">
          <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            {rowsPerPageOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="pagination-board-table__content">
        <div className="pagination-board-table__pages">
          <span>{totalItems} result(s)</span>
          <div className="pagination-board-table__buttons">
            <button
              className="pagination-board-table__button material-icons-outlined"
              disabled={currentPage === 1}
              title="Go to the first page"
              onClick={() => handlePageChange(1)}>
              keyboard_double_arrow_left
            </button>
            <button
              className="pagination-board-table__button material-icons-outlined"
              disabled={currentPage === 1}
              title="Go to the previous page"
              onClick={() => handlePageChange(currentPage - 1)}>
              keyboard_arrow_left
            </button>
            <button
              className="pagination-board-table__button material-icons-outlined"
              disabled={currentPage === totalPages}
              title="Go to the next page"
              onClick={() => handlePageChange(currentPage + 1)}>
              keyboard_arrow_right
            </button>
            <button
              className="pagination-board-table__button material-icons-outlined"
              disabled={currentPage === totalPages}
              title="Go to the last page"
              onClick={() => handlePageChange(totalPages)}>
              keyboard_double_arrow_right
            </button>
          </div>
        </div>
        <span>Jump to page</span>
        <div className="pagination-board-table__number">
          {/* TODO: add Enter keypress handler? */}
          <input
            type="text"
            value={editablePage}
            disabled={totalPages < 2}
            onChange={handleEditablePageChange}
            onBlur={event =>
              handlePageChange(normalizePageNumber(event.target.value))
            }
          />
        </div>
      </div>
    </div>
  );
};
