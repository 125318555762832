import { FC } from 'react';
import './Footer.scss';
import { config } from '../../../config';

export const Footer: FC = () => {
  return (
    <footer className="footer">
      <span>
        ©2024 | RSB - Roundtable on Sustainable Biomaterials Association | All
        Rights reserved | Version {config.APP_VERSION || '0.0.0'}
      </span>
    </footer>
  );
};
