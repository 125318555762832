export const ANY = '*';
export const STATEMENTS = '/statements';

export const INFORMATION = '/';

const subRoute = (mainRoute: string, subRoute: string) =>
  `${mainRoute}/${subRoute}`;

export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const REGISTRATION = '/registration';
export const CHANGE_PASSWORD = '/change-password';
export const SET_PASSWORD = '/set-password';
export const RESET_PASSWORD = '/reset-password';
export const VERIFY_EMAIL = '/verify-email';
export const CONTACT_US = '/contact-us';

export const DASHBOARD = '/dashboard';

export const TRANSFER = 'transfer';
export const CONTACT_LIST = 'contact-list';
export const LOGS = 'logs';
export const RETIREMENT = 'retirement';
export const REGISTER_CREDIT = 'register-credit';

export const DASHBOARD_ROUTES = {
  TRANSFER: subRoute(DASHBOARD, TRANSFER),
  TRANSACTIONS: DASHBOARD,
  RETIREMENT: subRoute(DASHBOARD, RETIREMENT),
  REGISTER: subRoute(DASHBOARD, REGISTER_CREDIT),
  CONTACT_LIST: subRoute(DASHBOARD, CONTACT_LIST),
};

export const BCU_DETAILS = '/bcu/:creditId';
export const BCU_DETAILS_ROUTES = {
  BCU_DETAILS,
  BCU_VALUES: subRoute(BCU_DETAILS, 'bcu-values'),
  BCU_INFO: subRoute(BCU_DETAILS, 'bcu-info'),
  SCOPE1: subRoute(BCU_DETAILS, 'scope1'),
  SCOPE3: subRoute(BCU_DETAILS, 'scope3'),
};

const INCOMING_TRANSACTIONS = subRoute(DASHBOARD_ROUTES.TRANSFER, 'incoming');
const INCOMING_TRANSACTION = subRoute(INCOMING_TRANSACTIONS, ':id');

export const TRANSFER_ROUTES = {
  TRANSFER: DASHBOARD_ROUTES.TRANSFER,
  CONTACT_LIST: subRoute(DASHBOARD_ROUTES.TRANSFER, CONTACT_LIST),
  REQUESTS: subRoute(DASHBOARD_ROUTES.TRANSFER, 'requests'),
  INFO_REQUESTS: subRoute(DASHBOARD_ROUTES.TRANSFER, 'info-requests'),
  INCOMING: subRoute(DASHBOARD_ROUTES.TRANSFER, 'incoming'),
  INCOMING_TRANSACTIONS: INCOMING_TRANSACTIONS,
  INCOMING_TRANSACTION: INCOMING_TRANSACTION,
  INCOMING_TRANSACTION_SCOPE_1: subRoute(INCOMING_TRANSACTION, 'scope1'),
  INCOMING_TRANSACTION_SCOPE_3: subRoute(INCOMING_TRANSACTION, 'scope3'),
  INFO_REQUEST: subRoute(
    subRoute(DASHBOARD_ROUTES.TRANSFER, 'info-requests'),
    ':id',
  ),
};

export const RETIREMENT_ROUTES = {
  RETIREMENT: DASHBOARD_ROUTES.RETIREMENT,
  CONTACT_LIST: subRoute(DASHBOARD_ROUTES.RETIREMENT, CONTACT_LIST),
};

export const TRANSFER_REQUEST = subRoute(TRANSFER_ROUTES.REQUESTS, '/:id');

// profile
export const USER_PROFILE = '/profile';
export const COMPANY_PROFILE = 'company';
export const PROFILE_INFO = 'profile-info';
export const USER_INFO = 'user-info';
export const ACCOUNTS_FEES = 'account-fees';

export const PROFILE_ROUTES = {
  USER_PROFILE: USER_PROFILE,
  COMPANY_PROFILE: subRoute(USER_PROFILE, COMPANY_PROFILE),
  PROFILE_INFO: subRoute(USER_PROFILE, PROFILE_INFO),
  USER_INFO: subRoute(USER_PROFILE, USER_INFO),
  ACCOUNTS_FEES: subRoute(USER_PROFILE, ACCOUNTS_FEES),
};

// admin
const ADMIN = '/admin';
export const ADMIN_BCUS = subRoute(ADMIN, 'bcus');
export const ADMIN_BCU_DETAILS = subRoute(ADMIN_BCUS, ':creditId');
export const ADMIN_BCU_DETAILS_ROUTES = {
  ADMIN_BCU_DETAILS,
  ADMIN_BCU_INFO: subRoute(ADMIN_BCU_DETAILS, 'bcu-info'),
  ADMIN_BCU_SCOPE1: subRoute(ADMIN_BCU_DETAILS, 'scope1'),
  ADMIN_BCU_SCOPE3: subRoute(ADMIN_BCU_DETAILS, 'scope3'),
  ADMIN_BCU_HISTORY: subRoute(ADMIN_BCU_DETAILS, 'history'),
  ADMIN_BCU_NOTE: subRoute(ADMIN_BCU_DETAILS, 'note'),
};
export const ADMIN_LOGS = subRoute(ADMIN, 'logs');
export const ADMIN_ACCOUNTS_AND_BALANCE = subRoute(
  ADMIN,
  'accounts-and-balance',
);
// export const ADMIN_ACCOUNTS_AND_BALANCE_DETAILS = subRoute(ADMIN_ACCOUNTS_AND_BALANCE, ':creditId');
export const ADMIN_ACCOUNTS_AND_BALANCE_ROUTES = {
  MAIN: ADMIN_ACCOUNTS_AND_BALANCE,
  DETAILS: subRoute(ADMIN_ACCOUNTS_AND_BALANCE, ':id'),
};
export const ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES = {
  ADMIN_ACCOUNTS_AND_BALANCE_DETAILS: ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS,
  ACCOUNT_USER_INFO: subRoute(
    ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS,
    'user-info',
  ),
  ACCOUNT_HISTORY: subRoute(
    ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS,
    'account-history',
  ),
  ACCOUNT_FEE_INFORMATION: subRoute(
    ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS,
    'fee-information',
  ),
  ACCOUNT_BALANCE: subRoute(
    ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS,
    'balance',
  ),
  ACCOUNT_NOTES: subRoute(ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS, 'notes'),
};

export const ADMIN_USERS = subRoute(ADMIN, 'users');
export const ADMIN_USERS_DETAILS = subRoute(ADMIN_USERS, ':userId');
export const ADMIN_USERS_DETAILS_ROUTES = {
  ADMIN_USERS_DETAILS,
};

export const ADMIN_SUBMISSIONS_LIST = subRoute(ADMIN, 'submission-lists');

export const ADMIN_SUBMISSIONS_LIST_COMPANY_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'company-info/:id',
);

export const ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_COMPANY_INFO, 'users'),
  USER_PROFILE: subRoute(ADMIN_SUBMISSIONS_LIST_COMPANY_INFO, 'user-profile'),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_COMPANY_INFO, 'note'),
};

export const ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'profile-update-info/:id',
);

export const ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES = {
  PROFILE_INFO: ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO,
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO, 'note'),
};

export const ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'bcu-credit/:id',
);

export const ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'users'),
  BCU_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'bcu-info'),
  BCU_VALUES: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'bcu-values'),
  SCOPE1_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'scope1'),
  SCOPE3_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'scope3'),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'note'),
};

export const ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'bcu-transfer/:id',
);

export const ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'users'),
  BCU_VALUES: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'bcu-values'),
  BCU_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'bcu-info'),
  TRANSFER_INFO: subRoute(
    ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO,
    'transfer-info',
  ),
  SCOPE1_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'scope1'),
  SCOPE3_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'scope3'),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'note'),
};

export const ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'bcu-retirement/:id',
);

export const ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'users'),
  BCU_VALUES: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'bcu-values'),
  BCU_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'bcu-info'),
  RETIREMENT_INFO: subRoute(
    ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO,
    'retirement-info',
  ),
  SCOPE1_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'scope1'),
  SCOPE3_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'scope3'),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'note'),
};

export const ADMIN_REFERENCE_VALUES = subRoute(ADMIN, 'reference-values');

export const ADMIN_REFERENCE_VALUES_ROUTES = {
  CERTIFICATION_SCHEMES: ADMIN_REFERENCE_VALUES,
  CREATE_CERTIFICATION_SCHEMES: subRoute(
    ADMIN_REFERENCE_VALUES,
    'create-reference-values',
  ),
  TAB2: subRoute(ADMIN_REFERENCE_VALUES, 'tab2'),
};
