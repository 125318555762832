import React, { useState } from 'react';

import './InformationRequestFormStyle.scss';

import NextButton from '../components/Buttons/NextButton/NextButton';
import { FormikErrors, useFormik } from 'formik';
import { DefaultSelect, SelectOption } from '../components/Form/DefaultSelect/DefaultSelect';
import { useGetContactListQuery, useSendInformationRequestMutation } from '../../API';
import { CompanyType } from '../../structure/models/company/company';
import { useNotification } from '../../hooks/useNotification';

type FormValues = {
    company: string;
};

export const InformationRequestForm: React.FC<{ id: string }> = ({id}) => {
    const toast = useNotification();
    const [sendInformationRequest] = useSendInformationRequestMutation();
    const {data: contactListData} = useGetContactListQuery();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const contactListOptions: SelectOption<string>[] = (contactListData || []).filter((entry: any) => entry?.to?.type === CompanyType.Airline).map((entry: any) => ({
        label: entry.to?.name,
        value: entry.toId,
    }));

    const form = useFormik<FormValues>({
        initialValues: {
            company: '',
        },
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values) => {
            const errors: FormikErrors<FormValues> = {};

            if (!values.company) {
                errors.company = 'Field is required.';
            }

            return errors;
        },
        onSubmit: async (values) => {
            const response: any = await sendInformationRequest({
                BCUID: id,
                company: values.company,
            });

            if (response.data && response.data.success) {
                toast.success({ message: 'Success', description: 'Your information request has been sent' });
                setIsSubmitted(true);
                return;
            }

            if (response.error) {
                toast.error({ message: 'Error', description: response.error?.message || 'Unknown error' });
                setIsSubmitted(false);
            }

            return;
        }
    });

    return (
        <div className="c-information-request-form">
            <DefaultSelect
                inputId="company"
                name="company"
                label="Scope 1 emitter that will provide information"
                placeholder="Company name"
                required
                error={form.errors.company}
                isClearable
                isSearchable
                options={contactListOptions}
                onChange={(option: any) => {
                    form.setFieldValue('company', option ? option.value : '');
                    setIsSubmitted(false);
                }}
                value={form.values.company}
            />
            <div className="c-information-request-form__submit">
                <NextButton
                    disabled={isSubmitted}
                    handleClick={form.submitForm}
                    text="Request information"
                    style={{margin: '0', width: '178px'}}
                />
            </div>
        </div>
    );
};