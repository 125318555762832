import {
  ChangeEvent,
  FocusEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useRef,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import './FieldStyles.scss';
import { useAppSelector } from '../../../../../store/hooks';

interface FieldProps {
  label: string | ReactNode;
  labelStyles?: React.CSSProperties;
  label2?: string | React.ReactNode;
  fieldAfter?: React.ReactNode;
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  error?: any;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  max?: number | string;
  min?: number | string;
  step?: number;
  showOptional?: boolean;
  errorVisible?: boolean;
  description?: string;
  formatOnBlur?: boolean;
  decimals?: number;
  handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const Field: FC<FieldProps> = ({
  label,
  label2,
  labelStyles,
  fieldAfter,
  id,
  value,
  onChange,
  onBlur,
  error,
  required,
  type,
  disabled,
  placeholder,
  max,
  min,
  step,
  showOptional,
  errorVisible,
  description,
  handleKeyPress,
  formatOnBlur,
  decimals = 0,
}) => {
  const refInput = useRef<any>(null);

  const neatProductSource = useAppSelector(
    state => state.form.fields.radioButton,
  );
  const inputClass = classNames({
    fieldCity: id === 'fieldCity',
    fieldPost: id === 'fieldPost',
    'field-input': id !== 'fieldGHG',
    fieldGHG: id === 'fieldGHG',
    fieldACQ: id === 'dateAcq',
    fieldEntry: id === 'dateEntry',
    fieldTonnes: id === 'fieldTonnes',
    fieldMJ: id === 'fieldMJ',
    'field-generate-token': id === 'field-generate-token',
    error: !!error && errorVisible,
    isEmpty: !value,
  });

  if (showOptional && neatProductSource === 'Supplier') {
    return null;
  }

  const onInputClick = (e: MouseEvent<HTMLInputElement>) => {
    if (type === 'date') refInput?.current?.showPicker();
  };

  const formatOnBlurHandler: React.FocusEventHandler<HTMLInputElement> = e => {
    if (onBlur) {
      onBlur(e);
    }

    if (formatOnBlur) {
      const value = e.target.value.replaceAll(',', '');

      const formattedValue = parseFloat(value).toLocaleString('en-US', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
      });

      const fallbackValue = parseFloat('0').toLocaleString('en-US', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
      });

      onChange({
        target: {
          value: formattedValue === 'NaN' ? fallbackValue : formattedValue,
        },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div className="field-container">
      <div className="registration--labelf">
        <div className="registration-dropdown--labelf">{label2}</div>
      </div>
      <label className="field-label" htmlFor={id} style={labelStyles}>
        {label}
        {required && <span className="field-error">*</span>}
      </label>
      <div style={{ display: 'inline-flex' }}>
        <input
          ref={refInput}
          disabled={disabled}
          className={inputClass}
          id={id}
          name={id}
          onChange={onChange}
          onBlur={formatOnBlurHandler}
          value={value}
          type={type}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          maxLength={255}
          style={{ cursor: type === 'date' ? 'pointer' : 'text' }}
          onClick={onInputClick}
        />
      </div>
      {fieldAfter || null}
      {error && errorVisible ? (
        <span className="field-error-text">{error}</span>
      ) : (
        description && <span className="field-description">{description}</span>
      )}
    </div>
  );
};

export default Field;
