import React, { useEffect, useState } from 'react';
import { Modal, ModalProps } from '../../../Modal/Modal';
import { Col, Row } from 'react-bootstrap';
import { Input } from '../../../Form/Input';
import { BaseButtonSubmit } from '../../../Buttons/BaseButtonSubmit/BaseButtonSubmit';
import classNames from 'classnames';
import { fetchGenerateToken } from '../../../../../API/token';
import { useAppDispatch } from '../../../../../store/hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './GenerateTokenModalStyle.scss';
import { intlFormatDistance, subMinutes } from 'date-fns';
import { useNotification } from '../../../../../hooks/useNotification';

export const GenerateTokenModal: React.FC<ModalProps> = ({
  show,
  className,
  ...props
}) => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<{ token: string; expiresAt: Date }>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchToken = () => {
      setLoading(true);
      dispatch(fetchGenerateToken())
        .then(generatedToken => {
          setToken(generatedToken);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    show ? fetchToken() : setToken(undefined);
  }, [show]);

  const onCopy = (_: string, success: boolean) => {
    if (success) {
      toast.success({ message: 'Success', description: 'Token has been copied to clipboard' });
    }
  };
  return (
    <Modal
      show={show}
      header={<h2 style={{ fontWeight: 'normal' }}>Generate Token</h2>}
      className={classNames('generate-token-modal', className)}
      centered
      {...props}
    >
      <Row>
        <Col md="9">
          <Input placeholder="Token" disabled value={token?.token} />
        </Col>
        <Col md="3">
          <CopyToClipboard text={token?.token || ''} onCopy={onCopy}>
            <BaseButtonSubmit active loading={loading}>
              Copy
            </BaseButtonSubmit>
          </CopyToClipboard>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <small>The token will expire {intlFormatDistance(token?.expiresAt || new Date(), subMinutes(new Date(), 1), { unit: 'hour', numeric: 'always' })}.</small>
        </Col>
      </Row>
    </Modal>
  );
};
