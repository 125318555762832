import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import BCUData, { getBCUInfoKeys } from '../../../../components/BCUData/BCUData';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { actions } from '../../../../../API/fetchProvider/fetch';
import { URL_POS_FILE, URL_SUPPLIER_FILE } from '../../../../../API/urls/urls';
import fileDownload from 'js-file-download';
import { useNotification } from '../../../../../hooks/useNotification';
import { fetchAdminEditBCU } from '../../../../../API/admin/bcu-list';
import { setAdminCredit } from '../../../../../store/reducers/admin/bcuListReducer';
import { useParams } from 'react-router-dom';
import { CompanyType } from '@prisma/client';

export const AdminBCUInfo: React.FC<{ fetchData?: (id: string) => any; }> = ({ fetchData }) => {
    const { id } = useParams();
    const toast = useNotification();
    const dispatch = useAppDispatch();
    const details = useAppSelector(selectSubmissionsDetails);
    const user = useAppSelector(selectUser);

    if (!details) return null;

    const isEditable = user?.company?.type === CompanyType.Admin;

    const bcu = details ? {
        ...(details.creditTransaction?.newCredit || details.creditTransaction?.credit),
        company: details.company,
    } : undefined;

    const handleTransformAndDispatchData = async (data: any) => {
        try {
            await dispatch(fetchAdminEditBCU({
                ...data,
                abatementBaselineScheme: data.abatementBaselineScheme?.id,
                additionalClaimAsAllowedUnderRsb: data.additionalClaimAsAllowedUnderRsb || undefined,
                company: undefined,
                supplierFilePath: undefined,
            })).then((resultingBCU) => {
                dispatch(setAdminCredit(resultingBCU));
            });
            toast.success({ message: 'Success', description: 'BCU has been updated' });

            if (id && fetchData) {
                await dispatch(fetchData(id));
            }

            return true;
        } catch (error: any) {
            const messages: string[] = (error?.response?.data?.message || []);

            toast.error({ message: 'Error', description: 'BCU has not been updated' });

            messages.forEach((message: string) => {
                toast.error({ message: 'Error', description: message });
            });

            return false;
        }
    };

    const handleDownloadPosFile = async () => {
        const result: { data: Blob } | undefined = (await actions
            .get(URL_POS_FILE.replace(':id', bcu.id), {
                responseType: 'blob',
            } as any)
            .catch(({ response }) => {
                toast.error({ message: 'Error', description: response.statusText });
            })) as any;

        if (result?.data) {
            fileDownload(result?.data, `pos_${bcu.id}.pdf`);
            toast.success({ message: 'Success', description: 'The file has been downloaded successfully.' });
        }
    };

    const handleDownloadSupplierFile = async () => {
        const result: { data: Blob } | undefined = (await actions
            .get(URL_SUPPLIER_FILE.replace(':id', bcu.id), {
                responseType: 'blob',
            } as any)
            .catch(({ response }) => {
                toast.error({ message: 'Error', description: response.statusText });
            })) as any;

        if (result?.data) {
            fileDownload(result?.data, `supplier_${bcu.id}.pdf`);
            toast.success({ message: 'Success', description: 'The file has been downloaded successfully.' });
        }
    };

    return (<div style={{ marginTop: '20px' }}>
        {bcu && <BCUData
            editable={isEditable}
            user={user}
            data={bcu}
            onSave={handleTransformAndDispatchData}
            onDownloadPosFile={handleDownloadPosFile}
            onDownloadSupplierFile={handleDownloadSupplierFile}
            keysFn={getBCUInfoKeys}
        />}
    </div>);
};
