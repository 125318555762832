import { AppThunk } from '../store/store';
import { actions } from './fetchProvider/fetch';
import { AxiosResponse } from 'axios';
import { ServerResponse } from './types';
import { URL_GENERATE_TOKEN } from './urls/urls';

export type TokenResponse = {
  accessToken: string;
};

interface GenerateTokenResponse {
  token: string;
  expiresAt: string;
}

export const fetchGenerateToken = (): AppThunk<Promise<{ token: string; expiresAt: Date }>> => () => {
  return actions
    .post<AxiosResponse<ServerResponse<GenerateTokenResponse>>>(
      URL_GENERATE_TOKEN,
    )
    .then((response: AxiosResponse<ServerResponse<GenerateTokenResponse>>) => {
      const token = response.data.data.token;
      const expiresAt = new Date(response.data.data.expiresAt);

      return {
        token,
        expiresAt,
      };
    });
};
