import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import { ViewElement } from '../../../../components/ViewElement/ViewElement';

export const AdminProfileInfo: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);

  if (!details) return null;

  const userUpdate = details?.userUpdate;

  return (
    <div className="admin-transfer-info view-elements-with-rows">
      <ViewElement label={<strong>Before Update</strong>} />
      <ViewElement
        label="First Name"
        description={userUpdate?.beforeUpdate?.firstName}
      />
      <ViewElement
        label="Last Name"
        description={userUpdate?.beforeUpdate?.lastName}
      />
      <ViewElement
        label="Email"
        description={userUpdate?.beforeUpdate?.email}
      />
      <ViewElement
        label="Position"
        description={userUpdate?.beforeUpdate?.position}
      />
      <ViewElement
        label="Phone"
        description={`${userUpdate?.beforeUpdate?.cellPhone?.code || ''}${
          userUpdate?.beforeUpdate?.cellPhone?.number || ''
        }`}
      />
      <ViewElement label={<strong>After Update</strong>} />
      <ViewElement
        label="First Name"
        description={
          userUpdate?.afterUpdate?.firstName ||
          userUpdate?.beforeUpdate?.firstName
        }
      />
      <ViewElement
        label="Last Name"
        description={
          userUpdate?.afterUpdate?.lastName ||
          userUpdate?.beforeUpdate?.lastName
        }
      />
      <ViewElement
        label="Email"
        description={
          userUpdate?.afterUpdate?.email || userUpdate?.beforeUpdate?.email
        }
      />
      <ViewElement
        label="Position"
        description={
          userUpdate?.afterUpdate?.position ||
          userUpdate?.beforeUpdate?.position
        }
      />
      <ViewElement
        label="Phone"
        description={`${
          userUpdate?.afterUpdate?.cellPhone?.code ||
          userUpdate?.beforeUpdate?.cellPhone?.code
        }${
          userUpdate?.afterUpdate?.cellPhone?.number ||
          userUpdate?.beforeUpdate?.cellPhone?.number
        }`}
      />
    </div>
  );
};
