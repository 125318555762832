import React from 'react';
import { Flex, Spin } from 'antd';

import './BigLoader.scss';

export const BigLoader: React.FC = React.memo(() => {
  return (
    <Flex className="c-loader" style={{ height: '100vh', width: '100vw', overflow: 'hidden'}} justify='center' align='center'>
      <Spin size="large" />
    </Flex>
  );
});
