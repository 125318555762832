import React from 'react';
import { TableHeadProps } from './types';
import './head.scss';

export const TableHead = <T = any,>({ headers, current, onSortChange }: TableHeadProps<T>) => {
    return (
        <thead className="c-table-head">
            <tr className="c-table-head__row">
                {(headers || []).map((column) => {
                    const classNames = [
                        'c-table-head__btn',
                    ];

                    if (column.sort) {
                        classNames.push('c-table-head__btn--sortable');
                    }

                    if (current && current.key === column.keyItem) {
                        classNames.push('c-table-head__btn--active');
                        if (current.direction === 'desc') {
                            classNames.push('c-table-head__btn--desc');
                        }
                    }

                    return (
                        <th className="c-table-head__col" key={column.title}>
                            {column.sort && (
                                <button
                                    type="button"
                                    className={classNames.join(' ')}
                                    onClick={() => onSortChange ? onSortChange({ column }) : undefined}
                                >{column.title}</button>
                            )}
                            {!column.sort && (
                                <span>{column.title}</span>
                            )}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};