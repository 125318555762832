import { PaginatedData } from '../../../structure/request/pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AccountFee, Company } from '../../../structure/models/company/company';

interface AccountsState {
  status: 'idle' | 'loading' | 'failed';
  list: {
    count: number;
    results: any[];
  };
  company: Company;
  fee: {
    count: number;
    results: AccountFee[];
  };
}

const initialState: AccountsState = {
  status: 'idle',
  list: {
    count: 0,
    results: [],
  },
  company: {} as Company,
  fee: {
    count: 0,
    results: [],
  },
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountsList: (state, action: PayloadAction<PaginatedData<any>>) => {
      state.list = action.payload;
    },
    setAccountsStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setAccountFee: (state, action) => {
      state.fee = action.payload;
    },
    updateCompany: (state, action) => {
      const company = action.payload;
      const results = state.list.results.map(value =>
        value.id === company.id ? { ...value, ...company } : value,
      );

      state.list = {
        ...state.list,
        results,
      };
    },
  },
});

export const {
  setAccountsStatus,
  setAccountsList,
  setCompany,
  updateCompany,
  setAccountFee,
} = accountsSlice.actions;
export const selectAccountsList = (state: RootState) => state.accounts.list;
export const selectAccountsStatus = (state: RootState) => state.accounts.status;
export const selectCompany = (state: RootState) => state.accounts.company;
export const selectAccountFee = (state: RootState) => state.accounts.fee;

export default accountsSlice.reducer;
