import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Option } from '../../../app/components/Form/Select/Select';

interface OptionsState {
    reporting: Option<string>[];
}

export const REPORTING_VOLUNTARY = 'Voluntary';

const initialState: OptionsState = {
    reporting: [
        {
            label: 'Voluntary',
            value: REPORTING_VOLUNTARY,
        }
    ],
};

const optionsReducer = createSlice({
    name: 'options',
    initialState,
    reducers: {
        setReportingOptions: (state, action) => {
            state.reporting = action.payload;
        },
    },
});

export const { setReportingOptions } = optionsReducer.actions;

export const selectReportingOptions = (state: RootState) => state.options.reporting;

export default optionsReducer.reducer;
