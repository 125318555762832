import { Big } from './big';

export const limitOfNumbers = (number: number | string, limit = 3) => {
  if (!number) return '';

  return parseFloat(new Big(number).toString()).toLocaleString('en-US', {
    minimumFractionDigits: limit,
    maximumFractionDigits: limit,
  });
};
