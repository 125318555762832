import { FC } from 'react';
import './stepsStyle.scss';
import Step from './Step/Step';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBaseLines } from '../../../../../store/reducers/user/abatementBaselinesReducer';

interface IBCUFormStepsProps {
  step: number;
  handleCurrentStep: (step: number) => void;
  neatProductSource: string;
  batchNumber: number;
  isVisibleStep: number[];
}

const BCUFormSteps: FC<IBCUFormStepsProps> = ({
  step,
  handleCurrentStep,
  neatProductSource,
  batchNumber,
  isVisibleStep,
}) => {
  const fields = useAppSelector(state => state.form.fields);
  const baselines = useAppSelector(selectBaseLines);
  const selectedBaseline = (baselines || []).find(b => b.id === fields.sustainabilityCertification);
  const hasCORSIAStep = selectedBaseline && selectedBaseline.name.length > 0 && String(selectedBaseline.name).includes('CORSIA');

  const getStep2Description = (): string => {
    switch (neatProductSource) {
      case '':
        return 'Neat supplier / self production information';
      case 'Supplier':
        return 'Neat supplier information';
      case 'Self-production':
        return 'Self production information';
      default:
        return '';
    }
  };

  const step2Description = getStep2Description();
  const step4Description = `Batch Information`;

  return (
    <div className="steps-reg">
      <Step
        number={1}
        title="Step 1"
        description="General information"
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 1}
        isVisibleStep={isVisibleStep}
      />
      <Step
        number={2}
        title="Step 2"
        description={step2Description}
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 2}
        blockStep={neatProductSource === ''}
        isVisibleStep={isVisibleStep}
      />
      <Step
        number={3}
        title="Step 3"
        description="Blending account information"
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 3}
        blockStep={neatProductSource === ''}
        isVisibleStep={isVisibleStep}
      />
      <Step
        number={4}
        title="Step 4"
        description={step4Description}
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 4}
        blockStep={neatProductSource === ''}
        isVisibleStep={isVisibleStep}
      />
      {hasCORSIAStep && (
        <Step
          number={5}
          title="Step 5"
          description="ICAO CORSIA information"
          currentStep={step}
          handleCurrentStep={handleCurrentStep}
          isFinishedStep={step > 5}
          blockStep={neatProductSource === ''}
          isVisibleStep={isVisibleStep}
        />
      )}
    </div>
  );
};

export default BCUFormSteps;
