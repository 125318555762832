import { useEffect } from 'react';
import { TableStructure } from '../helpers/updateTableData';
import { Subject } from 'rxjs';
import { config } from '../config';

export interface SSEEventData {
  id: TableStructure;
  data: any;
}

const url = config.SSE_URL;
let subject: Subject<SSEEventData> | undefined  = undefined;
let source: EventSource | undefined = undefined;

export const useSSE = () => {
  useEffect(() => {
    source = new EventSource(url);
    subject = new Subject<SSEEventData>();

    source?.addEventListener('message', (event) => {
      let eventData: { id?: TableStructure; data?: any; event?: string } | undefined = undefined;

      try {
        eventData = JSON.parse(event.data);
      } catch (e) {
        console.warn(`Cannot parse SSE data`, e);
      }

      if (eventData?.event === 'data-change') {
        subject?.next({
          id: eventData?.id || TableStructure.Logs,
          data: eventData?.data,
        });
      }
    });

    return () => {
      subject?.complete();
      source?.close();

      subject = undefined;
      source = undefined;
    }
  }, []);

  return subject;
};
