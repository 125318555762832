import { FC } from 'react';
import classNames from 'classnames';
import './ListStyle.scss';

interface ListProps {
  titles: { [key: string]: string };
  data: any[];
}

export const List: FC<ListProps> = ({ titles, data }) => {
  const listHeader = Object.entries(titles);
  const dataLength = listHeader.length;

  return (
    <div className="list">
      <div className="list__header">
        {listHeader.map(([key, title]) => (
          <div key={key} style={{ width: `calc(100% / ${dataLength})` }}>
            {title}
          </div>
        ))}
      </div>
      {Boolean(data.length) ? (
        data.map((user: any, index: number) => (
          <div
            key={user.SUID}
            className={classNames('list__item', {
              odd: index % 2 === 1,
            })}>
            {listHeader.map(([key], index) => (
              <div key={key} style={{ width: `calc(100% / ${dataLength})` }}>
                {user[key]}
              </div>
            ))}
          </div>
        ))
      ) : (
        <div className="list__noResult">No result found</div>
      )}
    </div>
  );
};
